import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Container, Col, Card, CardBody } from "reactstrap";
import { menuSelectionChange } from "../../store/actions";
import { Box, Typography } from "@mui/material";
import { PieChart } from "../Insights/PieChart";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import ComparisonFilters from "./ComparisonFilters";
import { ComparisonFunctionalArea } from "./ComparisonFunctionalArea";
import ComparisonDetails from "./ComparisonDetails";
import BusinessProcessesData from "./BusinessProcessesData.json";
import { StyledDataGrid } from "../generalFunctions";
import {
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";

const renderCellData = (params, keyName) => {
  const rowData = params?.row || {};

  return (
    <div>
      <span>{rowData[keyName]}</span>
    </div>
  );
};
const renderCellClassName = (params, keyName) => {
  const rowData = params?.row || {};
  const colorApplied =
    rowData?.colorApplied === "All"
      ? "MissMatch"
      : rowData?.colorApplied === "AllNew"
      ? "New"
      : rowData?.colorApplied && rowData?.colorApplied?.length
      ? rowData?.colorApplied
      : [];

  return colorApplied === "MissMatch"
    ? "mismatchColorClass"
    : colorApplied === "New"
    ? "newColorClass"
    : colorApplied.includes(keyName)
    ? "mismatchColorClass"
    : {};
};
export const Comparison = () => {
  document.title = "Comparison";
  const dispatch = useDispatch();

  const comparisonTabsData = [
    { id: 1, fName: "Sandbox", sName: "Sandbox Preview" },
    { id: 2, fName: "Sandbox", sName: "Production" },
    { id: 3, fName: "Sandbox", sName: "Impl 1" },
    { id: 4, fName: "Sandbox", sName: "Impl 2" },
  ];
  const [selectedComparisonType, setSelectedComparisonType] = useState(null);
  const [selectedBP, setSelectedBP] = useState(0);
  const [filterFormSelection, setFilterFormSelection] = useState({
    selectedTenant: 1,
    startDate: null,
    endDate: null,
  });
  const [rows, setRows] = useState([]);
  const [isViewClicked, setIsViewClicked] = useState(false);
  const [selectedSuiteSelection, setSelectedSuiteSelection] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const comparisonType = [
    {
      id: 1,
      score: 97,
      name: "Business Process",
      series: [40, 20, 40],
      labels: ["Mismatch", "Newly Added", "No-Change"],
    },
    {
      id: 2,
      score: 83,
      name: "Security",
      series: [26, 34, 40],
      labels: ["Mismatch", "Newly Added", "No-Change"],
    },
    {
      id: 3,
      score: 89,
      name: "Integration",
      series: [60, 10, 30],
      labels: ["Mismatch", "Newly Added", "No-Change"],
    },
    {
      id: 4,
      score: 76,
      name: "Tenant",
      series: [10, 20, 70],
      labels: ["Mismatch", "Newly Added", "No-Change"],
    },
    {
      id: 5,
      score: 90,
      name: "Reports",
      series: [30, 10, 60],
      labels: ["Mismatch", "Newly Added", "No-Change"],
    },
  ];
  const headerHeight = 65;
  const rowHeight = 60;
  const columns = [
    {
      field: "step",
      headerName: "Step",
      // align: "center",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "step"),
      cellClassName: (params) => renderCellClassName(params, "steps"),
    },
    {
      field: "order",
      headerName: "Order",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "order"),
      cellClassName: (params) => renderCellClassName(params, "order"),
    },
    {
      field: "if",
      headerName: "If",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "if"),
      cellClassName: (params) => renderCellClassName(params, "if"),
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "type"),
      cellClassName: (params) => renderCellClassName(params, "type"),
    },
    {
      field: "specify",
      headerName: "Specify",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "specify"),
      cellClassName: (params) => renderCellClassName(params, "specify"),
    },
    {
      field: "optional",
      headerName: "Optional",
      width: 60,
      maxWidth: 60,
      renderCell: (params) => renderCellData(params, "optional"),
      cellClassName: (params) => renderCellClassName(params, "optional"),
    },
    {
      field: "group",
      headerName: "Group",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "group"),
      cellClassName: (params) => renderCellClassName(params, "group"),
    },
    {
      field: "additionalInformation",
      headerName: "Additional Information",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "additionalInformation"),
      cellClassName: (params) =>
        renderCellClassName(params, "additionalInformation"),
    },
    {
      field: "all",
      headerName: "All",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "all"),
      cellClassName: (params) => renderCellClassName(params, "all"),
    },
    {
      field: "runAsUser",
      headerName: "Run As User",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "runAsUser"),
      cellClassName: (params) => renderCellClassName(params, "runAsUser"),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "dueDate"),
      cellClassName: (params) => renderCellClassName(params, "dueDate"),
    },
    {
      field: "effectiveDate",
      headerName: "Due Date Is Based On Effective Date",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "effectiveDate"),
      cellClassName: (params) => renderCellClassName(params, "effectiveDate"),
    },
    {
      field: "complete",
      headerName: "Complete",
      width: 200,
      maxWidth: 200,
      renderCell: (params) => renderCellData(params, "complete"),
      cellClassName: (params) => renderCellClassName(params, "complete"),
    },
  ];

  useEffect(() => {
    handleMenuClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = () => {
    dispatch(menuSelectionChange(5));
  };

  const handleComparisonTypeClick = (compType, indexVal) => {
    setSelectedComparisonType(compType);
  };
  const getProcessNameName = () => {
    const rec = selectedComparisonType
      ? selectedComparisonType
      : comparisonType.slice(0, 1)[0];
    return rec?.name;
  };
  const handleBusinessProcessChange = (event) => {
    setSelectedBP(event.target.value);
  };
  const getData = () => {
    return comparisonTabsData?.find(
      (rec) => rec?.id === parseInt(filterFormSelection?.selectedTenant)
    );
  };
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    setSelectedSuiteSelection(suiteSelection);
    // setSuiteSelection(suiteSelection); // Call setSuiteSelection here when needed
  };
  const getFilteredRows = ({ apiRef }) => {
    gridExpandedSortedRowIdsSelector(apiRef);
  };
  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    // Function to handle export button click to export data as CSV

    const handleExport = (options) => {
      // Set a custom file name for the exported CSV file
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      options.fileName = `suite_instance_listing_${timestamp}`;

      apiRef.current.exportDataAsCsv(options);
    };
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
      >
        <div>
          <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>
            Comparison View
          </h6>
        </div>

        <div>
          <i
            onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
            className="mdi mdi-microsoft-excel"
            style={{ cursor: "pointer", fontSize: "16px", color: "#4aa3ff" }}
            onMouseEnter={(e) => (e.target.style.color = "#0875e1")}
            onMouseLeave={(e) => (e.target.style.color = "#4aa3ff")}
          ></i>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="page-content" id="component">
        <Container fluid>
          <Box>
            <Breadcrumbs title="Home" breadcrumbItem="Comparison" />
            <ComparisonFilters
              comparisonTabsData={comparisonTabsData}
              setFilterFormSelection={setFilterFormSelection}
            />
            <Row>
              {/* ComparisonFunctionalArea Component */}
              <ComparisonFunctionalArea
                RenderComparisonTypeDetails={
                  <RenderComparisonTypeDetails
                    comparisonTypeData={comparisonType}
                    handleComparisonTypeClick={handleComparisonTypeClick}
                    width={250}
                  />
                }
              />
            </Row>
            <Row>
              <ComparisonDetails
                RenderComparisonTypeDetails={
                  <RenderComparisonTypeDetails
                    comparisonTypeData={
                      selectedComparisonType
                        ? [selectedComparisonType]
                        : comparisonType.slice(0, 1)
                    }
                    handleComparisonTypeClick={() => {}}
                    width={450}
                  />
                }
                chartName={getProcessNameName()}
                selectedBP={selectedBP}
                BusinessProcessesData={BusinessProcessesData}
                handleBusinessProcessChange={handleBusinessProcessChange}
                comparisonTabsData={getData()}
                setRows={setRows}
                setIsViewClicked={setIsViewClicked}
              />
            </Row>
            <Row>
              {isViewClicked && rows?.length ? (
                <Row
                  style={{
                    background: "#f3f3f4",
                    paddingTop: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <Col xl={12} style={{ paddingRight: "0px" }}>
                    <Card className="mb-1">
                      <CardBody className="card-body-dash">
                        <Box className="d-flex align-items-center">
                          <Box className="flex-grow-1">
                            <Typography className="card-title">
                              {"Comparison Data"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box style={{ padding: 20, height: "462px" }}>
                          <div
                            style={{
                              background: "#ffffff",
                              overflow: "auto",
                              height: "500px",
                              width: "100%",
                              flexGrow: 1,
                              marginLeft: 10,
                            }}
                          >
                            {/* Render the StyledDataGrid component with the specified rows, columns, and props */}
                            <StyledDataGrid
                              initialState={
                                {
                                  // columns: {
                                  //   columnVisibilityModel: {
                                  //     // Hide columns status and traderName, the other columns will remain visible
                                  //     warning: false,
                                  //     passed: false,
                                  //     failed: false,
                                  //     undefined: false,
                                  //   },
                                  // },
                                }
                              }
                              rows={rows}
                              columns={columns}
                              rowHeight={rowHeight}
                              columnHeaderHeight={headerHeight}
                              onCellClick={handleCellClick}
                              hideFooter
                              hideFooterPagination
                              hideFooterSelectedRowCount
                              slots={{ toolbar: CustomToolbar }}
                              onRowSelectionModelChange={(
                                newRowSelectionModel
                              ) => {
                                setRowSelectionModel(newRowSelectionModel);
                              }}
                              rowSelectionModel={rowSelectionModel}
                              componentsProps={{
                                // Set custom styles for the datafg grid components

                                basePopper: {
                                  // sx: {
                                  //   backgroundColor: "red",
                                  //   "& .MuiPaper-root": { backgroundColor: "green",fontSize:"2px" },
                                  //   "&.MuiDataGrid-menu .MuiPaper-root": { backgroundColor: "blue",fontSize:"10px" }
                                  // }
                                  sx: {
                                    "& .MuiTypography-root": {
                                      fontSize: "12px",
                                    },
                                    // '& .MuiDataGrid-filterForm': {backgroundColor: 'lightblue'},
                                  },
                                },
                              }}
                            />
                          </div>
                        </Box>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Row>
          </Box>
        </Container>
      </div>
    </>
  );
};
const RenderComparisonTypeDetails = ({
  comparisonTypeData,
  handleComparisonTypeClick,
  width = 250,
}) => {
  const part1 = comparisonTypeData;

  return (
    <>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        {part1 && part1?.length ? (
          part1.map((rec, indexVal) => {
            return (
              <>
                <Box
                  sx={{
                    maxWidth: { xs: width, sm: width },
                    maxHeight: { xs: width - 50, sm: width - 50 },
                    bgcolor: "background.paper",
                    marginLeft: indexVal === 0 ? 0 : 2,
                    cursor: "pointer",
                  }}
                  style={{
                    textAlign: "center",
                  }}
                  key={`comparisonType_index_${rec?.id}_${indexVal}`}
                  onClick={() => handleComparisonTypeClick(rec, indexVal)}
                >
                  <PieChart
                    name={rec?.name || ""}
                    value={rec?.score || 0}
                    data={rec}
                    series={rec?.series || []}
                    labels={rec?.labels || []}
                    width={width}
                  />
                </Box>
                {indexVal === 2 ? <br /> : null}
              </>
            );
          })
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};
