import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Import the storage engine (e.g., localStorage)

const persistConfig = {
  key: "root", // Change this to a unique key for your app
  storage, // Use the storage engine
  // Add any additional configuration options here if needed
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
// const composeEnhancers =
//   (typeof window !== 'undefined' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       trace: true, // Enable trace logs
//     })) || compose;

export function configureStore(initialState) {
  const persistedReducer = persistReducer(persistConfig, rootReducer); // Wrap your root reducer with persistReducer

  const store = createStore(
    persistedReducer, // Use the persisted reducer
    initialState,
    applyMiddleware(...middlewares)
    // composeEnhancers(applyMiddleware(...middlewares))
  );

  const persistor = persistStore(store); // Create the persistor

  sagaMiddleware.run(rootSaga);
  return { store, persistor }; // Return both store and persistor
}
