// Import necessary dependencies
import {
  takeEvery,
  call,
  put,
  all,
  fork,
  select,
  takeLatest,
} from "redux-saga/effects";
import {
  faFetch,
  scenarioFetch,
  searchScenarioID,
  searchSuiteFetch,
  searchSuiteID,
  stepFetch,
  suiteFetch,
} from "./orchestractor";
import {
  currentScenarioNameSelection,
  currentSuiteNameSelection,
  fetchSuiteInstanceListing,
  getFAAreas,
  getScenarioList,
  getStepList,
  getSuiteList,
  setFAAreaSelection,
  setLoadingState,
  setScenarioSelection,
  setSearchFlag,
  setSuiteSelection,
  showError,
} from "./actions";

function* fetchFaList(action) {
  try {
    const searchflag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchflag) {
      yield put(setLoadingState(true));
      const { faAreas, faAreaSelection } = yield call(
        faFetch,
        action.payload.tenantSelection,
        action.payload.startDate,
        action.payload.endDate
      );
      yield put(getFAAreas(faAreas));
      yield put(setFAAreaSelection(faAreaSelection));
    }
  } catch (error) {
    yield put(getFAAreas(null)); // Set FA areas to null in case of error
    yield put(setFAAreaSelection(null)); // Set FA area selection to null in case of error
  } finally {
    yield put(setLoadingState(false));
  }
}

function* watchTenantChange() {
  yield takeEvery("SET_TENANT_SELECTION", fetchFaList);
}

function* fetchSuiteList(action) {
  try {
    const searchflag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchflag) {
      yield put(setLoadingState(true));
      const faAreaSelection = action.payload;
      const tenantSelection = yield select(
        (state) => state.dashboardData.tenantSelection
      );
      const startDate = yield select((state) => state.dashboardData.startDate);
      const endDate = yield select((state) => state.dashboardData.endDate);

      const { suiteList, suiteSelection, currentSuiteName, SuiteInstanceList } =
        yield call(
          suiteFetch,
          faAreaSelection,
          tenantSelection,
          startDate,
          endDate
        );

      yield put(getSuiteList(suiteList));
      yield put(setSuiteSelection(suiteSelection));
      yield put(currentSuiteNameSelection(currentSuiteName));
      yield put(fetchSuiteInstanceListing(SuiteInstanceList));
    }
  } catch (error) {
    yield put(getSuiteList(null));
    yield put(setSuiteSelection(null));
    yield put(currentSuiteNameSelection(null));
    yield put(fetchSuiteInstanceListing(null));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* faChange() {
  yield takeLatest("SET_FA_AREA_SELECTION", fetchSuiteList);
}

function* fetchScenarioList(action) {
  try {
    const searchflag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchflag) {
      yield put(setLoadingState(true));
      const suiteid = action.payload;
      const tenantSelection = yield select(
        (state) => state.dashboardData.tenantSelection
      );
      const startDate = yield select((state) => state.dashboardData.startDate);
      const endDate = yield select((state) => state.dashboardData.endDate);

      const { scenaioList, scenarioSelection, scenarioNameSelection } =
        yield call(scenarioFetch, suiteid, tenantSelection, startDate, endDate);

      yield put(getScenarioList(scenaioList));
      yield put(currentScenarioNameSelection(scenarioNameSelection));
      yield put(setScenarioSelection(scenarioSelection));
    }
  } catch (error) {
    yield put(getScenarioList(null));
    yield put(currentScenarioNameSelection(null));
    yield put(setScenarioSelection(null));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* suiteIDChange() {
  yield takeLatest("SET_SUITE_SELECTION", fetchScenarioList);
}

function* getSteps(action) {
  try {
    const searchflag = yield select((state) => state.dashboardData.searchFlag);
    if (!searchflag) {
      yield put(setLoadingState(true));
      const senarioid = action.payload;
      const tenantSelection = yield select(
        (state) => state.dashboardData.tenantSelection
      );
      const startDate = yield select((state) => state.dashboardData.startDate);
      const endDate = yield select((state) => state.dashboardData.endDate);

      const stepList = yield call(
        stepFetch,
        senarioid,
        tenantSelection,
        startDate,
        endDate
      );

      yield put(getStepList(stepList));
    }
  } catch (error) {
    yield put(getStepList(null));
  } finally {
    yield put(setLoadingState(false));
  }
}

function* scenarioSelectionChange() {
  yield takeEvery("SET_SCENARIO_SELECTION", getSteps);
}

function* search_fun(action) {
  try {
    yield put(setLoadingState(true));
    const searchText = action.payload.searchText;
    const searchType = action.payload.searchType;

    const tenantSelection = yield select(
      (state) => state.dashboardData.tenantSelection
    );
    const startDate = yield select((state) => state.dashboardData.startDate);
    const endDate = yield select((state) => state.dashboardData.endDate);

    if (searchType === "SuiteRunIDRadios") {
      const { success, isWithinRange, faselection, suiteNameID, suiteID } =
        yield call(searchSuiteID, searchText, startDate, endDate);

      if (success && isWithinRange) {
        const { suiteList, SuiteInstanceList } = yield call(
          searchSuiteFetch,
          suiteNameID,
          faselection,
          tenantSelection,
          startDate,
          endDate
        );
        yield put(setFAAreaSelection(faselection));
        yield put(getSuiteList(suiteList));
        yield put(currentSuiteNameSelection(suiteNameID));
        yield put(fetchSuiteInstanceListing(SuiteInstanceList));
        yield put(setSuiteSelection(searchText));

        const { scenaioList, scenarioSelection, scenarioNameSelection } =
          yield call(
            scenarioFetch,
            searchText,
            tenantSelection,
            startDate,
            endDate
          );

        yield put(getScenarioList(scenaioList));
        yield put(currentScenarioNameSelection(scenarioNameSelection));
        yield put(setScenarioSelection(scenarioSelection));

        const stepList = yield call(
          stepFetch,
          scenarioSelection,
          tenantSelection,
          startDate,
          endDate
        );

        yield put(getStepList(stepList));
      } else {
        if (!isWithinRange) {
          const errormsg = `The Suite Run ID ${searchText} does not exist.`;
          yield put(showError(true, errormsg, "error"));
        } else {
          const errormsg = `The Suite Run ID ${searchText} does not exist.`;
          yield put(showError(true, errormsg, "error"));
        }
      }
    }

    if (searchType === "scenRunIDRadios") {
      const { success, rangeScenario, suiteID_n, scenarioNameID } = yield call(
        searchScenarioID,
        searchText,
        startDate,
        endDate
      );

      if (success && rangeScenario) {
        const { success, faselection, suiteNameID, suiteID } = yield call(
          searchSuiteID,
          suiteID_n
        );

        const { suiteList, SuiteInstanceList } = yield call(
          searchSuiteFetch,
          suiteNameID,
          faselection,
          tenantSelection,
          startDate,
          endDate
        );

        yield put(setFAAreaSelection(faselection));
        yield put(getSuiteList(suiteList));
        yield put(currentSuiteNameSelection(suiteNameID));
        yield put(fetchSuiteInstanceListing(SuiteInstanceList));
        yield put(setSuiteSelection(suiteID));

        const { scenaioList, scenarioSelection } = yield call(
          scenarioFetch,
          suiteID,
          tenantSelection,
          startDate,
          endDate
        );

        yield put(getScenarioList(scenaioList));
        yield put(setScenarioSelection(searchText));
        yield put(currentScenarioNameSelection(scenarioNameID));
        const stepList = yield call(
          stepFetch,
          searchText,
          tenantSelection,
          startDate,
          endDate
        );

        yield put(getStepList(stepList));
      } else {
        if (!rangeScenario) {
          const errormsg = `The Scenario Run ID ${searchText} does not exist.`;

          yield put(showError(true, errormsg, "error"));
        } else {
          const errormsg = `The Scenario Run ID ${searchText} does not exist.`;
          yield put(showError(true, errormsg, "error"));
        }
      }
    }

    // Other code here...
  } catch (error) {
    console.error("msg", error);
  } finally {
    yield put(setSearchFlag(false));
    yield put(setLoadingState(false));
  }
}

function* search() {
  yield takeEvery("SET_SEARCH", search_fun);
}

function* DashboardSaga() {
  yield all([
    fork(watchTenantChange),
    fork(faChange),
    fork(suiteIDChange),
    fork(scenarioSelectionChange),
    fork(search),
  ]);
}

export default DashboardSaga;
