import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export const FileUpload = ({
  handleFileUpload,
  getSelectedFileDetails,
  handleRemoveUploadedFile,
  formDetails,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
      // Call your backend API endpoint to upload files
    },
  });
  useEffect(() => {
    if (uploadedFiles?.length) {
      handleFileUpload(uploadedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);

  return (
    <div
      {...getRootProps()}
      style={{
        height: "150px",
        border: "1px dashed lightgray",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <input
        {...getInputProps()}
        multiple={false}
        name="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <div>
        <p>Drag and drop files here or click to browse.</p>
        <div>
          {getSelectedFileDetails(
            formDetails?.suiteScript,
            formDetails?.item
          ) ? (
            <div
              style={{
                marginTop: 5,
                display: "flex",
              }}
            >
              {getSelectedFileDetails(
                formDetails?.suiteScript,
                formDetails?.item
              )}
              {/* <button
                className="btn btn-secondary btn-sm waves-effect waves-light"
                style={{ marginLeft: 5 }}
                title="Remove"
                onClick={(event) =>
                  handleRemoveUploadedFile(
                    event,
                    formDetails?.suiteScript,
                    formDetails?.item
                  )
                }
              >
                X
              </button> */}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
