import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import ScenarioTable from "./ScenarioTable";
import { Collapse, Card, CardHeader, CardBody } from "reactstrap";
import { CSVLink } from "react-csv";


const WbScenariolist = ({ scenaioList, scenarioNameSelection }) => {
  // State to track expanded scenarioIds in the accordionte
  const [expandedScenario, setExpandedScenario] = useState([]);

  // Function to handle accordion toggle when clicking on the card header
  const handleAccordionToggle = (scenarioId) => {
    setExpandedScenario((prevState) =>
      prevState.includes(scenarioId)
        ? prevState.filter((id) => id !== scenarioId) // Collapse the card if it was expanded
        : [...prevState, scenarioId] // Expand the card if it was collapsed
    );
  };


  

  // Update expandedScenario state whenever scenarioNameSelection changes
  useEffect(() => {
     if (scenarioNameSelection) {
      setExpandedScenario([scenarioNameSelection]);
    } else {
      setExpandedScenario([]);
    }
  }, [scenaioList,scenarioNameSelection]);


  // Styles for card header and body
  const cardHeaderStyle = {
    padding: "3px",
    marginBottom: "0",
    backgroundColor: "#f7f7f7",
    borderBottom: "1px solid #ddd",
    cursor: "pointer",
    fontSize: "10.5px",
    fontWeight: "500",
    display: "flex",
    justifyContent: "space-between", // Add spacing between text and arrow
    alignItems: "center", // Align the arrow verticallo
    borderRadius: "0",
  };

  const cardBodyStyle = {
    padding: "1px",
  };
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
 
  return (
    <div>
      {/* Header section with title and Excel icon */}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          padding: "0px 5px",
          background: "white",
          borderBottom: "1px solid rgb(240, 240, 240)",
        }}
      >
        <div>
          <h6
            style={{ marginBottom: "0px", fontSize: "11px", color: "#000000cc" }}
          >
            Scenario Run Instance Listing
          </h6>
        </div>
        <div>
        <CSVLink
  data={scenaioList} // Use the entire scenaioList as the data for CSV export
  headers={[
    { label: "Scenario Id", key: "scenarioDetails.scenarioId" },
    { label: "Scenario Name", key: "scenarioDetails.scenarioName" },
      { label: "RI Id", key: "scenarioRIDetails[0].scenarioRIId" },
      { label: "RID Description", key: "scenarioRIDetails[0].scenarioRIDesc" },
    { label: "Duration", key: "scenarioRIDetails[0].scenarioRIDuration" },
  
    { label: "Start DateTime", key: "scenarioRIDetails[0].scenarioRIStartDatetime" },
    { label: "Status", key: "scenarioRIDetails[0].scenarioRIStatus" },
  ]}
  filename={`Scenario_Instance_Listing${timestamp}.csv`}
>
  <i
    className="mdi mdi-microsoft-excel"
    style={{
      cursor: "pointer",
      fontSize: "16px",
      color: "rgb(74, 163, 255)",
    }}
  />
</CSVLink>

        </div>
      </div>

      {/* Main content section */}
      <div
        style={{ background: "#ffffff", overflow: "auto", height: "440px" }}
      >
        {/* Iterate through each scenario in scenaioList */}
        {scenaioList.map((scenario) => (
          <Card style={{ marginBottom: "0px" }} key={scenario.scenarioDetails.scenarioId}>
            <CardHeader
              onClick={() =>
                handleAccordionToggle(scenario.scenarioDetails.scenarioId)
              }
              style={cardHeaderStyle}
            >
              <span>{scenario.scenarioDetails.scenarioName}</span>
              {/* Add arrow symbol based on the collapse/expand state */}
              {expandedScenario.includes(scenario.scenarioDetails.scenarioId) ? (
                <span>&#9660;</span> // Down arrow for expanded state
              ) : (
                <span>&#9654;</span> // Right arrow for collapsed state
              )}
            </CardHeader>
            {/* Render the ScenarioTable component within a Collapse component */}
            <Collapse
    isOpen={expandedScenario.includes(scenario.scenarioDetails.scenarioId)}
  >
    <CardBody style={cardBodyStyle}>
      <ScenarioTable subScenario={scenario.scenarioRIDetails} />
    </CardBody>
  </Collapse>

 
          </Card>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  scenaioList: state.dashboardData.scenaioList || [],
  scenarioNameSelection: state.dashboardData.scenarioNameSelection || [],
});

export default connect(mapStateToProps)(WbScenariolist);
