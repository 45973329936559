import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <Row>
            <Col lg={12} style={{textAlign: "right"}}>
             © {new Date().getFullYear()} <b>Ilumn</b>. All rights reserved. 
              </Col>
            </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
