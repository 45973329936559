import React, { useState, useEffect } from "react"; // Import React and necessary hooks
import ProgressBar from "../Charts/ResultsBarChart"; // Import the ProgressBar component from a file

import {
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid"; // Import functions and hhooks from the @mui/x-data-grid library

import { setSuiteSelection } from "../../../store/actions"; // Import the setSuiteSelection action creator from the Redux store
import { connect } from "react-redux"; // Import the connect function from react-redux to connect the component to the Redux store
import { StyledDataGrid } from "../../generalFunctions"; // Import StyledDataGrid component from generalFunctions file

const getFilteredRows = ({ apiRef }) => // Define a function to get filtered rows based on the current grid API
  gridExpandedSortedRowIdsSelector(apiRef);
 
 
  // Define the columns for the data grid

const columns = [
  {
    field: "id",
    headerName: "Run ID",
    align: "center",
    width: 60,
    maxWidth: 60,
   },
   {
    field: "date1",
    headerName: "Date",
    width: 130,
   
    type: "date",
    align: "center",
    valueFormatter: (params) => {
      const date = new Date(params.value);
      const options = {
        day: "2-digit",
        month: "short",
        year: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      const formattedDateTime = date.toLocaleDateString("en-UK", options);
      return formattedDateTime;
    },
  },
  {
    field: "duration",
    headerName: "Duration",
      align: "center", 
      width: 70,
      // renderHeader: () => (
      //   <ColumnHeaderWrapper/>
      // )
  },
  {
    field: "results",
    renderCell: RenderCellProgressBar,
    headerName: "Results",
    align: "center",
    width: 90,
    maxWidth: 90,
    disableColumnMenu: true, 
    sortable: false,
 
  },
  {
    field: "passed",
    headerName: "Passed",
    align: "center",
    width: 70,
     
   
 
  },
  {
    field: "failed",
    headerName: "Failed",
    align: "center",
    width: 70,
   
    
  
  },
  {
    field: "warning",
    headerName: "Warning",
    align: "center",
    width: 70,
    
 
  },
  {
    field: "undefined",
    headerName: "Undefined",
    align: "center",
    width: 80,
  
    
   
  },
 
];
// CustomToolbar component for the data grid's toolbar section

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

   // Function to handle export button click to export data as CSV

   const handleExport = (options) => {
    // Set a custom file name for the exported CSV file
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    options.fileName = `suite_instance_listing_${timestamp}`;
 
    apiRef.current.exportDataAsCsv(options);
  };
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
    >
      <div>
        <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>Suite Run Instance Listing
</h6>
      </div>
     

      <div>
        <i
          onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
          className="mdi mdi-microsoft-excel"
          style={{ cursor: "pointer", fontSize: "16px", color: "#4aa3ff" }}
          onMouseEnter={(e) => (e.target.style.color = "#0875e1")}
          onMouseLeave={(e) => (e.target.style.color = "#4aa3ff")}
        ></i>
      </div>
    </div>
  );
};
// WBSuiteRunlist component

const WbSuiterunlist = ({suiteSelection, suiteInstanceListing, setSuiteSelection }) => {
   // Define headerHeight and rowHeight for the data grid

  const headerHeight = 28;
  const rowHeight = 26; 
    const [selectedSuiteSelection, setSelectedSuiteSelection] = useState(null);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const rows = suiteInstanceListing.map((item) => ({
        // Map the suiteInstanceListing data to rows for the data grid

    id: item.suiteRIId,
    date1: item.suiteRIStartDatetime,
    duration: item.suiteRIDuration,
    results :`Passed-${item.suiteRIStatus.passedCount}|Failed-${item.suiteRIStatus.failedCount}|Warning-${item.suiteRIStatus.warningCount}|Undefined-${item.suiteRIStatus.undefinedCount}`,

    passed: item.suiteRIStatus.passedCount,
    failed: item.suiteRIStatus.failedCount,
    warning: item.suiteRIStatus.warningCount,
    undefined: item.suiteRIStatus.undefinedCount,
  }));


  useEffect(() => {
    // Effect to handle changes in selected suite selection

    
    
     
  }, [selectedSuiteSelection]);

  useEffect(() => {
      // Effect to set the row selection model when suiteInstanceListing or suiteSelection changes

    setRowSelectionModel([suiteSelection]);
  }, [suiteInstanceListing,suiteSelection]);




  const handleCellClick = async (params) => {
        // Function to handle cell click and set the selected suite selection

    const suiteSelection = params.row.id;
    setSelectedSuiteSelection(suiteSelection);
    setSuiteSelection(suiteSelection); // Call setSuiteSelection here when needed
  };

  return (
    <div
      style={{
        background: "#ffffff",
        overflow: "auto",
        height: "445px",
        width: "100%", 
        flexGrow: 1
      }}
    >
 
      {/* Render the StyledDataGrid component with the specified rows, columns, and props */}

      <StyledDataGrid

initialState={{
  columns: {
    columnVisibilityModel: {
      // Hide columns status and traderName, the other columns will remain visible
      warning: false,
      passed: false,
      failed: false,
      undefined: false,
     },
  },
}}



        rows={rows}
        columns={columns}
        rowHeight={rowHeight}
        columnHeaderHeight={headerHeight}
        onCellClick={handleCellClick}
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
       
        slots={{ toolbar: CustomToolbar }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
          }}
        rowSelectionModel={rowSelectionModel}



        componentsProps={{
          
                 // Set custom styles for the datafg grid components

          basePopper: {
            // sx: {
            //   backgroundColor: "red",
            //   "& .MuiPaper-root": { backgroundColor: "green",fontSize:"2px" },
            //   "&.MuiDataGrid-menu .MuiPaper-root": { backgroundColor: "blue",fontSize:"10px" }
            // }
            sx: { '& .MuiTypography-root': {  fontSize: "12px",  },  
            // '& .MuiDataGrid-filterForm': {backgroundColor: 'lightblue'}, 
          } 
          }
        }}
       




      />
          

    </div>
  );
};

const mapStateToProps = (state) => ({
  suiteInstanceListing: state.dashboardData.suiteInstanceListing || [],
  suiteSelection: state.dashboardData.suiteSelection || [],
});

const mapDispatchToProps = {
  setSuiteSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(WbSuiterunlist);


function RenderCellProgressBar(params) {
  const passed = params.row.passed;
  const failed = params.row.failed;
  const warning = params.row.warning;
  const Undefined = params.row.undefined;

  return (
    <ProgressBar
      Passed={passed}
      Failed={failed}
      Warning={warning}
      Undefined={Undefined}
    />
  );
}
