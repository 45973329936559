import React, { useEffect, useState } from "react";
import { StyledDataGrid } from "../generalFunctions";
import {
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";

export const DataTableForChart = (props) => {
  const { businessProcessDetails, chartName, height } = props;

  const headerHeight = 50;
  const rowHeight = 45;
  const [columns, setColumns] = useState([]);

  const [selectedSuiteSelection, setSelectedSuiteSelection] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setColumns([
      {
        field: "processName",
        headerName: chartName || "Business Process Type",
        headerClassName: "headerStyle",
        align: "left",
        width: 250,
        flex: 1,
        // maxWidth: 250,
        //   renderCell: (params) => renderCellData(params, "step"),
        //   cellClassName: (params) => renderCellClassName(params, "steps"),
      },
      {
        field: "Count",
        headerName: "Count",
        headerClassName: "headerStyle",
        align: "right",
        headerAlign: "right",
        width: 170,
        // maxWidth: 170,
        //   renderCell: (params) => renderCellData(params, "order"),
        //   cellClassName: (params) => renderCellClassName(params, "order"),
      },
    ]);
  }, [chartName]);
  useEffect(() => {
    if (businessProcessDetails?.length) {
      //   console.log(businessProcessDetails);
      const updatedData = [...businessProcessDetails]?.map((item, index) => {
        return { ...item, id: index };
      });
      setRows(updatedData);
    }
  }, [businessProcessDetails]);

  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    setSelectedSuiteSelection(suiteSelection);
    // setSuiteSelection(suiteSelection); // Call setSuiteSelection here when needed
  };
  const getFilteredRows = ({ apiRef }) => {
    gridExpandedSortedRowIdsSelector(apiRef);
  };
  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    // Function to handle export button click to export data as CSV

    const handleExport = (options) => {
      // Set a custom file name for the exported CSV file
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      options.fileName = `suite_instance_listing_${timestamp}`;

      apiRef.current.exportDataAsCsv(options);
    };
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          padding: "0 5px 0 5px",
          borderBottom: " 1px solid #f0f0f0",
          maxHeight: "50px",
        }}
      >
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>
            {`${businessProcessDetails?.length} items`}
          </h6>
        </div>

        {/* <div>
          <i
            onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
            className="mdi mdi-microsoft-excel"
            style={{ cursor: "pointer", fontSize: "16px", color: "#4aa3ff" }}
            onMouseEnter={(e) => (e.target.style.color = "#0875e1")}
            onMouseLeave={(e) => (e.target.style.color = "#4aa3ff")}
          ></i>
        </div> */}
      </div>
    );
  };

  return (
    <div
      style={{
        background: "#ffffff",
        overflow: "auto",
        height: height,
        width: "100%",
        flexGrow: 1,
        marginLeft: 10,
      }}
    >
      <StyledDataGrid
        initialState={{}}
        rows={rows}
        columns={columns}
        rowHeight={rowHeight}
        columnHeaderHeight={headerHeight}
        onCellClick={handleCellClick}
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        slots={{ toolbar: CustomToolbar }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        componentsProps={{
          basePopper: {
            sx: {
              "& .MuiTypography-root": { fontSize: "12px" },
            },
          },
        }}
      />
    </div>
  );
};
