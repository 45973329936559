import React from "react";
import ReactApexChart from "react-apexcharts";
const colorArr = ["#11c46e", "#ff3d60", "#f1b44c", "#9da1a6"]; // Define the color array

const DashDonutChartSmall = ({
  passedCount,
  failedCount,
  warningCount,
  undefinedCount,
}) => {
  // Data and options for the donut chart
  const chartData = {
    series: [passedCount, failedCount, warningCount, undefinedCount],
    options: {
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let total = 0;
          for (let x of series) {
            total += x;
          }
          let selected = series[seriesIndex];

          return `
            <div style="color :white;font-size:10px;padding:5px; background-color: ${
              colorArr[seriesIndex]
            };">
              ${w.config.labels[seriesIndex]}: ${((selected / total) * 100)
            .toFixed(0)
            .toLocaleString()}%
            </div>
          `;
        },
      },
      labels: ["Passed", "Failed", "Warning", "Undefined"],
      colors: ["#11c46e", "#ff3d60", "#f1b44c", "#9da1a6"],
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "12px",
        offsetX: 0,
        offsetY: -10,
      },
      dataLabels: {
        enabled: false, // Hide data labels
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
            labels: {
              show: false, // Hide data labels within donut segments
            },
          },
        },
      },
      stroke: {
        show: false, // Remove lines between series
        width: 1,
      },
      responsive: [
        {
          breakpoint: 200,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      {/* Render the donut chart */}
      <ReactApexChart
        id="donutDash"
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height="95"
        className="apex-charts"
      />
    </div>
  );
};

export default DashDonutChartSmall;
