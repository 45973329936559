import React, { Fragment } from "react";
import { InputGroup } from "reactstrap";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

export const CreateSchedule = (props) => {
  const {
    open,
    handleClose = () => {},
    formDetails,
    handleChangeTestSuite = () => {},
    testSuitDetails,
    handleChangeTestSuiteFrequency = () => {},
    recurrenceConstant,
    handleChange = () => {},
    handleMonthlyChange = () => {},
    daysOfWeek,
    handleWeekChange = () => {},
    names,
    timeList,
    timezoneData,
    callingType,
    monthlyMonthDetails,
    handleMonthlyMonthChange = () => {},
    monthlyWeekDetails,
    handleMonthlyWeekChange = () => {},
    monthNames,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleChangeTimeZone = () => {},
    handleChangeTimeSelection = () => {},
    handleRecurrenceTypeValueChange = () => {},
    handleCreateSchedule = () => {},
    handleWeeklyRecurrenceValueChange = () => {},
  } = props;

  const getAllTestSuite = (item) => {
    let allTestSuite = [];
    item?.modules?.length &&
      item?.modules.forEach((rec) => {
        allTestSuite = rec?.suiteScripts?.length
          ? [...allTestSuite, ...rec.suiteScripts]
          : [...allTestSuite];
      });

    return allTestSuite;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
      <DialogTitle>
        <Typography className="font-size-18" variant="h5">
          <strong>{"Schedule Test Suite"}</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        {callingType === "TestManager" ? (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <FormLabel className="font-size-12" style={{ width: 130 }}>
              Test Suite
            </FormLabel>
            <FormControl
              sx={{ m: 1, minWidth: 350, minHeight: 20, margin: "3px" }}
            >
              <Select
                native
                defaultValue=""
                value={formDetails?.testSuite || null}
                name="testSuite"
                onChange={handleChangeTestSuite}
                style={{ height: 25 }}
                className="font-size-12"
              >
                {testSuitDetails &&
                  testSuitDetails?.suiteScriptListByFA
                    .filter((rec) => rec?.modules?.length)
                    .map((item) => {
                      return (
                        <optgroup
                          className="font-size-12"
                          label={item?.faShortName || ""}
                        >
                          {item?.modules?.length ? (
                            getAllTestSuite(item).map((suiteScript) => {
                              return (
                                <option
                                  className="font-size-12"
                                  key={suiteScript?.suiteId || 0}
                                  value={suiteScript?.suiteId || 0}
                                >
                                  {suiteScript?.suiteName || ""}
                                </option>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </optgroup>
                      );
                    })}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <></>
        )}
        <Box style={{ display: "flex", alignItems: "center" }}>
          <FormLabel className="font-size-12" style={{ width: 130 }}>
            Run Frequency
          </FormLabel>
          <FormControl
            sx={{ m: 1, minWidth: 350, minHeight: 20, margin: "3px" }}
          >
            <Select
              defaultValue=""
              value={formDetails?.testSuiteFrequency || null}
              onChange={handleChangeTestSuiteFrequency}
              name="testSuiteFrequency"
              style={{ height: 25 }}
              className="font-size-12"
            >
              <MenuItem className="font-size-12" value={1}>
                Daily Recurrence
              </MenuItem>
              <MenuItem className="font-size-12" value={2}>
                Weekly Recurrence
              </MenuItem>
              <MenuItem className="font-size-12" value={3}>
                Monthly Recurrence
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {formDetails?.testSuiteFrequency === 1 ||
        formDetails?.testSuiteFrequency === 2 ||
        formDetails?.testSuiteFrequency === 3 ? (
          <Box>
            {formDetails?.testSuiteFrequency === 1 ? (
              <>
                <Typography
                  className="font-size-14"
                  variant="h6"
                  style={{ marginTop: 10 }}
                >
                  <strong>{recurrenceConstant[0]}</strong>
                </Typography>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel className="font-size-12" style={{ width: 130 }}>
                    {"Recurrence Type"}
                  </FormLabel>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 350,
                      minHeight: 20,
                      margin: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={formDetails?.recurrenceType || null}
                      name="radio-buttons-group"
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio size="small" sx={{ padding: "3px" }} />}
                        label="Recurs Every Weekday"
                        className="radio-label-style"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" sx={{ padding: "3px" }} />}
                        label="Recurs Every x Days(s)"
                        className="radio-label-style"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formDetails?.recurrenceType === 2 ? (
                    <input
                      type="number"
                      min="0"
                      className="form-control font-size-14"
                      style={{ height: 25, width: 80 }}
                      value={formDetails?.recurrenceTypeValue || 0}
                      name={"recurrenceTypeValue"}
                      onChange={handleRecurrenceTypeValueChange}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            ) : null}
            {formDetails?.testSuiteFrequency === 2 ? (
              <>
                <Typography
                  className="font-size-14"
                  variant="h6"
                  style={{ marginTop: 10 }}
                >
                  <strong>{recurrenceConstant[1]}</strong>
                </Typography>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel className="font-size-12" style={{ width: 130 }}>
                    Recurs Every x Week(s)
                  </FormLabel>
                  <FormControl
                    sx={{ m: 1, minWidth: 350, minHeight: 20, margin: "3px" }}
                  >
                    <input
                      type="number"
                      min="0"
                      className="form-control font-size-12"
                      style={{ height: 25 }}
                      value={formDetails?.weeklyRecurrenceValue || 0}
                      name={"weeklyRecurrenceValue"}
                      onChange={(e) => handleWeeklyRecurrenceValueChange(e)}
                    />
                  </FormControl>
                </Box>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel className="font-size-12" style={{ width: 130 }}>
                    Day(s) of the Week
                  </FormLabel>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 350,
                      maxWidth: 350,
                      minHeight: 20,
                      margin: "3px",
                    }}
                  >
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      multiple
                      value={daysOfWeek}
                      onChange={handleWeekChange}
                      renderValue={(selected) => selected.join(", ")}
                      style={{ height: 25 }}
                      className="font-size-12"
                    >
                      {names.map((name) => (
                        <MenuItem
                          className="multiselect-style"
                          key={name}
                          value={name}
                        >
                          <Checkbox
                            className="multiselect-style"
                            checked={daysOfWeek.indexOf(name) > -1}
                          />
                          <ListItemText
                            className="radio-label-style multiselect-style"
                            primary={name}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </>
            ) : null}
            {formDetails?.testSuiteFrequency === 3 ? (
              <>
                <Typography
                  className="font-size-14"
                  variant="h6"
                  style={{ marginTop: 10 }}
                >
                  <strong>{recurrenceConstant[2]}</strong>
                </Typography>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel style={{ width: 130 }}></FormLabel>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 260,
                      minHeight: 20,
                      margin: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={formDetails?.recurrenceType || null}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio size="small" sx={{ padding: "3px" }} />}
                        label="Every Month"
                        className="radio-label-style"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" sx={{ padding: "3px" }} />}
                        label="Month(s)"
                        className="radio-label-style"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formDetails?.recurrenceType === 2 ? (
                    <Select
                      labelId="month-multiple-checkbox-label"
                      multiple
                      value={monthlyMonthDetails}
                      onChange={handleMonthlyMonthChange}
                      renderValue={(selected) => selected.join(", ")}
                      style={{ height: 25, width: 80 }}
                      className="font-size-12"
                    >
                      {monthNames.map((name) => (
                        <MenuItem
                          className="multiselect-style"
                          key={name}
                          value={name}
                        >
                          <Checkbox
                            className="multiselect-style"
                            checked={monthlyMonthDetails.indexOf(name) > -1}
                          />
                          <ListItemText
                            className="radio-label-style multiselect-style"
                            primary={name}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel className="font-size-12" style={{ width: 130 }}>
                    {"Recurrence Type"}
                  </FormLabel>
                  <FormControl
                    sx={{
                      m: 1,
                      minWidth: 260,
                      minHeight: 20,
                      margin: "3px",
                      marginLeft: "10px",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={formDetails?.monthlyRecurrenceType || null}
                      onChange={handleMonthlyChange}
                      className="radio-label-style"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio size="small" sx={{ padding: "3px" }} />}
                        label="Day(s) of the Month"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio size="small" sx={{ padding: "3px" }} />}
                        label="Day of the week"
                        className="radio-label-style"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formDetails?.monthlyRecurrenceType === 1 ? (
                    <input
                      type="number"
                      min="0"
                      className="form-control font-size-12"
                      style={{ height: 25, width: 80 }}
                      value={formDetails?.monthlyRecurrenceTypeValue || 0}
                      name={"monthlyRecurrenceTypeValue"}
                      onChange={(e) => handleRecurrenceTypeValueChange(e)}
                    />
                  ) : formDetails?.monthlyRecurrenceType === 2 ? (
                    <Select
                      labelId="monthly-week-multiple-checkbox-label"
                      multiple
                      value={monthlyWeekDetails}
                      onChange={handleMonthlyWeekChange}
                      renderValue={(selected) => selected.join(", ")}
                      style={{ height: 25, width: 80 }}
                      className="font-size-12"
                    >
                      {names.map((name) => (
                        <MenuItem
                          className="multiselect-style"
                          key={name}
                          value={name}
                        >
                          <Checkbox
                            className="multiselect-style"
                            checked={monthlyWeekDetails.indexOf(name) > -1}
                          />
                          <ListItemText
                            className="radio-label-style multiselect-style"
                            primary={name}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            ) : null}
            {/* Common for All */}
            {/* Time Selection Component */}
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="font-size-12" style={{ width: 130 }}>
                Start Time
              </FormLabel>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 350,
                  maxWidth: 350,
                  minHeight: 20,
                  margin: "3px",
                }}
              >
                <Select
                  defaultValue=""
                  value={formDetails?.testSuiteTimeSelection || ""}
                  onChange={handleChangeTimeSelection}
                  name="testSuiteTimeSelection"
                  style={{ height: 25 }}
                  className="font-size-12"
                >
                  {timeList?.length ? (
                    timeList.map((item, index) => (
                      <MenuItem className="font-size-12" value={item || ""}>
                        {item || ""}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="font-size-12" value={1}>
                      -Select Time-
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            {/* Timezone Component */}
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="font-size-12" style={{ width: 130 }}>
                Timezone
              </FormLabel>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 350,
                  maxWidth: 350,
                  minHeight: 20,
                  margin: "3px",
                }}
              >
                <Select
                  defaultValue=""
                  value={formDetails?.testSuiteTimezone || ""}
                  onChange={handleChangeTimeZone}
                  name="testSuiteTimezone"
                  style={{ height: 25 }}
                  className="font-size-12"
                >
                  {timezoneData?.length ? (
                    timezoneData.map((item) => (
                      <MenuItem
                        className="font-size-12"
                        key={item?.id || 0}
                        value={item?.id || 0}
                      >
                        {item?.text || ""}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="font-size-12" value={1}>
                      -Select Timezone-
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            {/* Start and End Date Fields */}
            <Typography
              className="font-size-14"
              variant="h6"
              style={{ marginTop: 10 }}
            >
              <strong>{"Range of Recurrence"}</strong>
            </Typography>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="font-size-12" style={{ width: 130 }}>
                Start Date
              </FormLabel>
              <FormControl
                sx={{ m: 1, minWidth: 350, minHeight: 20, margin: "3px" }}
              >
                <InputGroup>
                  <Flatpickr
                    id="endDatepicker"
                    className="form-control-cal d-block font-size-12"
                    placeholder="DD-MMM-YYYY"
                    type="datetime-local"
                    options={{
                      altInput: true,
                      altFormat: "d-M-Y",
                      dateFormat: "Y-m-d",
                      onChange: (selectedDates) =>
                        setStartDate(selectedDates[0]),
                    }}
                    value={startDate}
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FormLabel className="font-size-12" style={{ width: 130 }}>
                End Date
              </FormLabel>
              <FormControl
                sx={{ m: 1, minWidth: 350, minHeight: 20, margin: "3px" }}
              >
                <InputGroup>
                  <Flatpickr
                    id="endDatepicker"
                    className="form-control-cal d-block font-size-12"
                    placeholder="DD-MMM-YYYY"
                    type="datetime-local"
                    options={{
                      altInput: true,
                      altFormat: "d-M-Y",
                      dateFormat: "Y-m-d",
                      onChange: (selectedDates) => setEndDate(selectedDates[0]),
                    }}
                    value={endDate}
                  />
                </InputGroup>
              </FormControl>
            </Box>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleCreateSchedule}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};
