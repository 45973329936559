import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MyTextField } from "../../components/TextField";
import { useState } from "react";
import { Typography } from "@mui/material";

export const ChangePassword = (props) => {
  const {
    editUserDetails,
    passwordDetails,
    handleChangePasswordClick,
    oldPasswordErrorMessages,
    newPasswordErrorMessages,
    confirmPasswordErrorMessages,
    passwordCommonErrorMessages,
  } = props;

  const [profileDetails, setProfileDetails] = useState(editUserDetails || {});

  useEffect(() => {
    if (editUserDetails) {
      setProfileDetails(editUserDetails || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("ChangePassword ===> ", profileDetails, passwordDetails);

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className="card">
            <div className="card-body p-0">
              <div
                className="text-muted"
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <Row>
                  <div className="col-md-1 col-sm-12"></div>
                  <div className="col-md-12 col-sm-12">
                    {/* <Row className="mb-2 row">
                      <Col className="col-3">
                        <label className="small-select">
                          Old Password <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          value={passwordDetails?.oldPassword || ""}
                          handleChange={(event) =>
                            handleChangePasswordClick(event, "oldPassword")
                          }
                          type={"password"}
                          className={""}
                          placeholder={"Enter old password"}
                          pattern={""}
                          inputMode={"password"}
                        />
                        {oldPasswordErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {oldPasswordErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row> */}
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label className="small-select">
                          New Password <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          value={passwordDetails?.newPassword || ""}
                          handleChange={(event) =>
                            handleChangePasswordClick(event, "newPassword")
                          }
                          type={"password"}
                          className={""}
                          placeholder={"Enter new password"}
                          pattern={""}
                          inputMode={"password"}
                        />
                        {newPasswordErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {newPasswordErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label className="small-select">
                          Confirm Password <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          value={passwordDetails?.confirmPassword || ""}
                          handleChange={(event) =>
                            handleChangePasswordClick(event, "confirmPassword")
                          }
                          type={"password"}
                          className={""}
                          placeholder={"Confirm new password"}
                          pattern={""}
                          inputMode={"password"}
                        />
                        {confirmPasswordErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {confirmPasswordErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}

                        {passwordCommonErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {passwordCommonErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-2 row">
                      <Col className="col-3"></Col>
                      <Col className="col-9">
                        <ul style={{ paddingLeft: "14px" }}>
                          <li>
                            Password should contain alphanumeric character.
                          </li>
                          <li>
                            Password length can be minimum 8 and maximum 15
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                  <div className="col-md-1 col-sm-12"></div>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
