// Import required modules and components
import { connect } from "react-redux";
import DashRadialChart from "./Charts/DashRadial";
import { Card, CardBody, Col, Row } from "reactstrap";
import React from "react";

const AreaChartB = ({ CurrentSelectionFA, faAreas }) => {
  // Extract information of the selected FA area
  const selectedFA = faAreas.find((fa) => fa.faId === CurrentSelectionFA);
  const chartTitle = selectedFA ? selectedFA.faShortName : "";
  const passedCount = selectedFA ? selectedFA.faScenarioRISummary.passedCount : 0;
  const failedCount = selectedFA ? selectedFA.faScenarioRISummary.failedCount : 0;
  const warningCount = selectedFA ? selectedFA.faScenarioRISummary.warningCount : 0;
  const undefinedCount = selectedFA ? selectedFA.faScenarioRISummary.undefinedCount : 0;
  const totalCount = selectedFA ? selectedFA.faScenarioTotal : 0;

  // Calculate the percentage for each category
  const passedPercentage = totalCount !== 0 ? (passedCount / totalCount) * 100 : 0;
  const failedPercentage = totalCount !== 0 ? (failedCount / totalCount) * 100 : 0;
  const warningPercentage = totalCount !== 0 ? (warningCount / totalCount) * 100 : 0;
  const undefinedPercentage = totalCount !== 0 ? (undefinedCount / totalCount) * 100 : 0;
 
 

  if (faAreas.length === 0) {
    return "";
  }
  // Return the JSX content
  return (
    <React.Fragment>
      {/* Display the Card with the Radial Chart */}
      <Col xl={4} style={{ paddingRight: "0px" }}>
        <Card>
          <CardBody className="card-body-dash">

            {/* Title of the chart */}
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title marginBottomSummaryCard">
                  {chartTitle} Summary
                </h5>
              </div>
            </div>
        
            {/* Render the Radial Chart */}
            <DashRadialChart
              totalLegend={""}
              totalCountValue={totalCount}
              passedCount={passedPercentage}
              failedCount={failedPercentage}
              warningCount={warningPercentage}
              undefinedCount={undefinedPercentage}
            />
{/* Display the total count in the center */}
<div className="centerAbsoluteSummary">
 
          <p className="chartTotalTextSummary">
          {totalCount}
          </p>
        </div>
            {/* Legend for the chart */}
            <Row className="legendCenterTopSummaryCard">
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <i className="mdi mdi-checkbox-blank-circle me-1 text-success fontSizeCircleSummaryCard"></i>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <i className="mdi mdi-checkbox-blank-circle me-1 text-danger fontSizeCircleSummaryCard"></i>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <i className="mdi mdi-checkbox-blank-circle me-1 text-warning fontSizeCircleSummaryCard"></i>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <i className="mdi mdi-checkbox-blank-circle me-1 text-info fontSizeCircleSummaryCard"></i>
              </Col>
            </Row>

            {/* Labels for each category */}
            <Row className="rowAlignMidSummaryCard">
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">Passed</small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">Failed</small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">Warning</small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">Undefined</small>
              </Col>
            </Row>

            {/* Counts for each category */}
            <Row className="rowAlignMidSummaryCard">
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">{passedCount}</small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">{failedCount}</small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">{warningCount}</small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">{undefinedCount}</small>
              </Col>
            </Row>

            {/* Percentages for each category */}
            <Row className="rowAlignBottomSummaryCard">
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">
                  {passedPercentage.toFixed(0).toLocaleString()}%
                </small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">
                  {failedPercentage.toFixed(0).toLocaleString()}%
                </small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">
                  {warningPercentage.toFixed(0).toLocaleString()}%
                </small>
              </Col>
              <Col className="d-flex align-items-center justify-content-center text-center paddingzSummaryCard">
                <small className="font-size-8">
                  {undefinedPercentage.toFixed(0).toLocaleString()}%
                </small>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

// Map the Redux state to component props
const mapStateToProps = (state) => {
  return {
    CurrentSelectionFA: state.dashboardData.faAreaSelection || [],
    faAreas: state.dashboardData.faAreas || [],
  };
};

// Connect the component with Redux store and export it
export default connect(mapStateToProps)(AreaChartB);
