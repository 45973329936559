import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  // FormControl,
  // FormLabel,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import StackedBarChartRoundedIcon from "@mui/icons-material/StackedBarChartRounded";
import DonutLargeRoundedIcon from "@mui/icons-material/DonutLargeRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import StackedLineChartRoundedIcon from "@mui/icons-material/StackedLineChartRounded";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { grey, lightGreen } from "@mui/material/colors";
import { DonutChart } from "./DonutChart";
// import { DataTableForChart } from "./DataTableForChart";
import { LineChart } from "./LineChart";
import { BubbleChart } from "./BubbleChart";
import { StackedChart } from "./StackedChart";
// import InsightsDataArea from "./InsightsDataArea";
import { DataTableForChart } from "./DataTableForChart";
import Split from "@uiw/react-split";

export const InsightsChart = ({
  chartName,
  BusinessProcessDetails,
  defaultChart = 1,
  lineType = 1,
  isStacked = false,
  selectedMenu = 1,
  rightText = "",
  tableHeaderName = "",
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChartType, setSelectedChartType] = useState(1);
  const [dataToShow, setDataToShow] = useState(
    BusinessProcessDetails.slice(0, 5)
  );

  const chartHeight = 330;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (defaultChart) {
      setSelectedChartType(defaultChart || 1);
    }
  }, [defaultChart]);
  useEffect(() => {
    if (BusinessProcessDetails?.length) {
      setDataToShow(BusinessProcessDetails.slice(0, 5));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BusinessProcessDetails]);

  const handleOpenPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickChart = (e, type) => {
    handleClose();
    setSelectedChartType(type);
  };
  const RenderChartRightSection = () => {
    return (
      <>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Avatar
            sx={{
              width: 50,
              height: 50,
              marginTop: -3,
              bgcolor: lightGreen[200],
            }}
          >
            <AssignmentIcon />
          </Avatar>
        </Box>
        <Box padding={2}>
          <Typography
            component={"span"}
            dangerouslySetInnerHTML={{
              __html:
                rightText ||
                `Here are the Top-performing transactions within the specified
                timeframe. It's imperative to hone in on these pivotal Business
                Processes during the test execution.`,
            }}
          ></Typography>
          {/* <Box>
            <ol>
              <li>Change Organization Assignments for Worker</li>
              <li>Request one-Time Payment</li>
              <li>Start Performance Review</li>
              <li>Change Job</li>
              <li>Assign Paygroup</li>
            </ol>
          </Box> */}
        </Box>
      </>
    );
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update isMobile when window is resized
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const colors = [
    "#33b2df",
    "#546E7A",
    "#d4526e",
    "#13d8aa",
    "#A5978B", //
    "#2b908f",
    "#f9a3a4",
    "#90ee7e",
    "#f48024",
    "#69d2e7",
  ];
  return (
    <>
      <Row
        style={{
          background: "#f3f3f4",
          paddingTop: "0px",
          marginTop: "20px",
          // border: "1px solid red",
          marginLeft: "0px",
        }}
      >
        <Col xl={5} style={{ paddingRight: "0px", paddingLeft: "0px" }}>
          <Card className="mb-1">
            <CardBody className="card-body-dash">
              <Box className="d-flex align-items-center">
                <Box className="flex-grow-1">
                  <Typography className="card-title">
                    {chartName || "Chart"}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip title="Download" arrow>
                    <IconButton
                      aria-label="Download"
                      color="success"
                      onClick={(event) => handleOpenPopup(event)}
                    >
                      <TuneIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Box style={{ padding: 20, height: "360px" }}>
                {selectedChartType === 1 ? (
                  <>
                    <DonutChart
                      businessProcessDetails={dataToShow}
                      height={chartHeight}
                    />
                    {/* <Box style={{ marginTop: 20 }}>
                          <DataTableForChart
                            businessProcessDetails={dataToShow}
                          />
                        </Box> */}
                  </>
                ) : selectedChartType === 2 ? (
                  <>
                    <LineChart
                      businessProcessDetails={
                        lineType === 2 ? BusinessProcessDetails : dataToShow
                      }
                      lineType={lineType || 1}
                      colors={colors}
                      height={chartHeight}
                    />
                    {/* <Box style={{ marginTop: 20 }}>
                          <DataTableForChart
                            businessProcessDetails={dataToShow}
                          />
                        </Box> */}
                  </>
                ) : selectedChartType === 3 ? (
                  <>
                    <BubbleChart
                      businessProcessDetails={dataToShow}
                      height={chartHeight}
                    />
                    {/* <Box style={{ marginTop: 20 }}>
                          <DataTableForChart
                            businessProcessDetails={dataToShow}
                          />
                        </Box> */}
                  </>
                ) : selectedChartType === 4 || selectedChartType === 6 ? (
                  <>
                    <StackedChart
                      businessProcessDetails={dataToShow}
                      isBar={false}
                      colors={
                        selectedMenu === 2
                          ? ["#69caaf"]
                          : colors[Math.floor(Math.random() * 11)]
                      }
                      height={chartHeight}
                    />
                    {/* <Box style={{ marginTop: 20 }}>
                          <DataTableForChart
                            businessProcessDetails={dataToShow}
                          />
                        </Box> */}
                  </>
                ) : selectedChartType === 5 ? (
                  <>
                    <StackedChart
                      businessProcessDetails={dataToShow}
                      isBar={false}
                      isStacked={isStacked}
                      colors={
                        isStacked
                          ? [
                              // "#1984c5",
                              // "#22a7f0",
                              "#df8879",
                              "#a7d5ed",
                              "#a4a2a8",
                              "#48b5c4",
                              "#76c68f",
                            ] || [
                              "#54bebe",
                              "#76c8c8",
                              "#98d1d1",
                              "#48b5c4",
                              "#76c68f",
                              // "#badbdb",
                              // "#dedad2",
                            ] || [
                              // "#115f9a",
                              "#1984c5",
                              "#22a7f0",
                              "#48b5c4",
                              "#76c68f",
                              "#a6d75b",
                            ] || [...colors]
                          : colors[Math.floor(Math.random() * 11)]
                      }
                      height={chartHeight}
                    />
                  </>
                ) : selectedChartType === 7 ||
                  selectedChartType === 8 ||
                  selectedChartType === 9 ? (
                  <>
                    <StackedChart
                      businessProcessDetails={dataToShow}
                      isBar={true}
                      colors={colors[Math.floor(Math.random() * 11)]}
                      height={chartHeight}
                    />
                    {/* <Box style={{ marginTop: 20 }}>
                          <DataTableForChart
                            businessProcessDetails={dataToShow}
                          />
                        </Box> */}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} style={{ paddingRight: "0px", paddingLeft: "1px" }}>
          <Card className="mb-1">
            <CardBody>
              <DataTableForChart
                businessProcessDetails={dataToShow}
                chartName={tableHeaderName || chartName}
                height={"370px"}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} style={{ paddingLeft: "1px" }}>
          <Card className="mb-1">
            <CardBody>
              <Col
                style={{
                  backgroundColor: grey[200],
                  border: `1px solid ${lightGreen[200]}`,
                  borderRadius: "20px",
                  marginTop: 30,
                  height: "340px",
                }}
              >
                <RenderChartRightSection />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box padding={4}>
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={6}>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 1 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 1 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 1 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 1)}
                >
                  <DonutLargeRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 1 ? "primary" : "default"}
                >
                  Donut
                </Typography>
              </Box>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 2 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 2 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 2 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 2)}
                >
                  <TimelineRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 2 ? "primary" : "default"}
                >
                  Line
                </Typography>
              </Box>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 3 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 3 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 3 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 3)}
                >
                  <BubbleChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 3 ? "primary" : "default"}
                >
                  Bubble
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" spacing={4}>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 4 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 4 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 4 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 4)}
                >
                  <StackedBarChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 4 ? "primary" : "default"}
                  style={{ width: 60 }}
                >
                  Clustered Column
                </Typography>
              </Box>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 5 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 5 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 5 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 5)}
                >
                  <StackedBarChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 5 ? "primary" : "default"}
                  style={{ width: 60 }}
                >
                  Stacked Column
                </Typography>
              </Box>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 6 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 6 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 6 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 6)}
                >
                  <BarChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 6 ? "primary" : "default"}
                >
                  100% Column
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" spacing={4}>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 7 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 7 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 7 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 7)}
                >
                  <StackedBarChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 7 ? "primary" : "default"}
                >
                  Clustered Bar
                </Typography>
              </Box>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 8 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 8 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 8 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 8)}
                >
                  <StackedBarChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 8 ? "primary" : "default"}
                >
                  Stacked Bar
                </Typography>
              </Box>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 9 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 9 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 9 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 9)}
                >
                  <BarChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 9 ? "primary" : "default"}
                >
                  100% Bar
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" spacing={4}>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 10 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 10 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 10 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 10)}
                >
                  <StackedLineChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 10 ? "primary" : "default"}
                >
                  Overlain Area
                </Typography>
              </Box>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 11 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 11 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 11 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 11)}
                >
                  <StackedLineChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 11 ? "primary" : "default"}
                >
                  Stacked Area
                </Typography>
              </Box>
              <Box
                display={"inline-grid"}
                className={selectedChartType === 12 ? "selected-box" : ""}
              >
                <IconButton
                  className={selectedChartType === 12 ? "selected-icon" : ""}
                  aria-label="View"
                  color={selectedChartType === 12 ? "primary" : "default"}
                  onClick={(e) => handleClickChart(e, 12)}
                >
                  <StackedLineChartRoundedIcon />
                </IconButton>
                <Typography
                  variant="caption"
                  color={selectedChartType === 12 ? "primary" : "default"}
                >
                  100% Area
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default InsightsChart;
