// Import required modules and components
import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { connect, useSelector } from "react-redux";
import AreaCards from "./AreaCards";
import {
  setFAAreaSelection,
  setLoadingState,
} from "../../store/dashboard/actions";
import { generatePDF } from "../generalFunctions";
import { ExcelExportIcon } from "../../components/ExcelExportIcon";

// Functional component FunctionalArea
const FunctionalArea = ({
  dashboardData,
  FASelection,
  setFAAreaSelection,
  setLoadingState,
}) => {
  const handleGeneratePDF = async () => {
    setLoadingState(true); // Set loading state to true initially

    try {
      const mcardholderDiv = document.getElementById("mcardholder");
      mcardholderDiv.classList.remove(
        "row",
        "flex-nowrap",
        "overflow-auto",
        "ms-3",
        "me-4",
        "col"
      );
      mcardholderDiv.classList.add("row", "ms-3", "me-4", "col");

      const docfinder = document.getElementById("root");

      if (docfinder) {
        const positionAbsoluteCenterDivs = docfinder.querySelectorAll(
          ".position-absolute-center"
        );

        positionAbsoluteCenterDivs.forEach((positionAbsoluteCenterDiv) => {
          positionAbsoluteCenterDiv.classList.remove(
            "position-absolute-center"
          );
          positionAbsoluteCenterDiv.classList.add("position-absolute-print");
        });

        // Call the generatePDF function with the element's ID
        generatePDF("root", "Functional_Area_Summary");

        positionAbsoluteCenterDivs.forEach((positionAbsoluteCenterDiv) => {
          positionAbsoluteCenterDiv.classList.remove("position-absolute-print");
          positionAbsoluteCenterDiv.classList.add("position-absolute-center");
        });
      }

      mcardholderDiv.classList.remove("row", "ms-3", "me-4", "col");
      mcardholderDiv.classList.add(
        "row",
        "flex-nowrap",
        "overflow-auto",
        "ms-3",
        "me-4",
        "col"
      );
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error(
        "An error occurred on exporting Functioanl Area Report:",
        error
      );
      // You can add error handling logic here, such as showing an error message to the user.
    } finally {
      setLoadingState(false); // Set loading state to false in both success and error cases
    }
  };

  // Event handler for changing the selected functional area
  const handleSuiteChange = async (event) => {
    const faAreaSelection = parseInt(event.target.value);
    setFAAreaSelection(faAreaSelection);
  };
  const faAreas = useSelector((state) => state.dashboardData.faAreas || []);

  if (faAreas.length === 0) {
    return <p>No records found</p>;
  }
  // Return JSX content
  return (
    <Col id="component">
      <Card className="mb-3">
        <CardBody className="card-body-dash">
          {/* Header section with functional area selection */}
          <CardHeader className="border-bottom-1-5px-solid-f3f3f4 bg-transparent">
            <Row>
              <Col className="col-sm-1 w150px">
                <h5 className="mb-0 font-size-14 mt-2">Functional Area</h5>
              </Col>
              <Col className="page-title-box d-sm-flex align-items-center justify-content-between">
                {/* Dropdown to select functional area */}
                <select
                  className="form-select p-1 w-10 w200px"
                  id="inlineFormSelectPref"
                  onChange={handleSuiteChange}
                  value={FASelection}
                >
                  {dashboardData?.length ? (
                    dashboardData.map((data) => (
                      // Render options for each functional area in the dropdown
                      <option key={data.faId} value={data.faId}>
                        {data.faShortName}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
                <ExcelExportIcon
                  handleExport={handleGeneratePDF}
                  getFilteredRows={""}
                  className={"page-title-right"}
                  mouseEnterColor={"#0875e1"}
                  mouseLeaveColor={"#4aa3ff"}
                  iconClass={
                    "mdi mdi-printer align-middle me-2 text-primary hide-on-mobile"
                  }
                />
              </Col>
            </Row>
          </CardHeader>

          {/* Container for displaying functional area cards */}
          <Row id="CardHolder" className="pt-3 pb-3">
            <Col
              id="mcardholder"
              className="row flex-nowrap overflow-auto ms-3 me-4"
            >
              {/* Render the AreaCards component to display functional area cards */}
              <AreaCards dashboardData={dashboardData} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

// Map the Redux state to component props
const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData.faAreas || [],
  FASelection: state.dashboardData.faAreaSelection,
});

// Connect the component with Redux store and export it
export default connect(mapStateToProps, {
  setFAAreaSelection,
  setLoadingState,
})(FunctionalArea);
