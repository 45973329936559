import { Typography } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

export const PieChart = (props) => {
  const { name, series, labels, width = 250 } = props;
  const chartDetails = {
    series: series,
    options: {
      chart: {
        width: width,
        title: "My Chart",
        type: "donut",
      },
      stroke: {
        width: 0,
      },
      // ["Mismatch", "Newly Added", "No-Change"],
      colors: ["#3971B8", "#1D2753", "#EF8621"],
      // colors: ["#63A6F7", "#FFBDD6", "#FF5C9A"],
      // colors: ["#FDD49F", "#FFA126", "#ACECF3"],
      // colors: ["#44C8D7", "#FFE89C", "#D5C2FA"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: false,
                show: false,
              },
            },
          },
        },
      },
      labels: labels,
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return `${val}`;
        },
        // dropShadow: {
        //   blur: 3,
        //   opacity: 0.8,
        // },
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 330,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };
  return (
    <>
      {/* {name} */}
      <div>
        {/* <div id="chart"> */}
        <ReactApexChart
          options={chartDetails.options}
          series={chartDetails.series}
          type="donut"
          width={width}
        />
        <div id="html-dist"></div>
        <Typography>
          <strong>{name || ""}</strong>
        </Typography>
        {/* </div> */}
        {/* <div id="html-dist"></div> */}
      </div>
    </>
  );
};
