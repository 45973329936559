import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

const Breadcrumbs = (props) => {
  try {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
              <div className="page-title-right">
                {/* Breadcrumb component */}
                <Breadcrumb listClassName="m-0">
                  {/* Home breadcrumb item */}
                  <BreadcrumbItem>
                    <Link to={props?.titleRoute ? props.titleRoute : "/home"}>
                      {props.title}
                    </Link>
                  </BreadcrumbItem>
                  {/* Active breadcrumb item */}
                  <BreadcrumbItem active>
                    <Link to="#">{props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  } catch (error) {
    // Log the error using the logger instance
    // Replace 'logger' with your actual logger instance from Log4js or another library
    console.error("Error in Breadcrumbs component");

    // Return an alternative error message or null to suppress rendering the component
    return (
      <div>
        <p>An error occurred while rendering the Breadcrumbs component.</p>
      </div>
    );
  }
};

export default Breadcrumbs;
