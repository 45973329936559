import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export const LineChart = (props) => {
  const { businessProcessDetails, lineType, colors, height } = props;
  const chartDetails = {
    series: [],
    options: {
      chart: {
        height: height,
        type: "line",
      },
      plotOptions: {
        line: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      stroke: {
        show: true,
        curve: "straight",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      colors: colors || ["#57ebcb"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: [],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
        labels: {
          show: true,
          height: 150,
        },
      },
      responsive: [
        {
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };

  const [chartData, setChartData] = useState(chartDetails);

  useEffect(() => {
    if (businessProcessDetails?.length) {
      const seriesData = [];
      const xAxisCategories = [];

      businessProcessDetails.forEach((rec) => {
        if (rec?.processName !== "Total") {
          seriesData.push(rec?.Count || 0);
          xAxisCategories.push(rec?.processName || "");
        }
      });
      const series =
        lineType === 2
          ? [
              {
                name: "Termination",
                data: [1, 3, 0, 1, 2, 1],
              },
              {
                name: "Hire",
                data: [2, 5, 1, 1, 5, 6],
              },
              {
                name: "Add Probation",
                data: [2, 1, 2, 3, 6, 2],
              },
              {
                name: "Contract Contingent Worker",
                data: [4, 6, 3, 2, 4, 2],
              },
              {
                name: "Change Job",
                data: [1, 0, 2, 0, 1, 0],
              },
            ]
          : [
              {
                name: "Inflation",
                data: seriesData,
              },
            ];
      const categories = xAxisCategories;
      const yAxisDetails = lineType === 2 ? { max: 8 } : {};
      setChartData({
        ...chartData,
        series: series,
        options: {
          ...chartData.options,
          yaxis: {
            ...chartData.options.yaxis,
            ...yAxisDetails,
          },
          xaxis: {
            ...chartData.options.xaxis,
            categories:
              lineType === 2
                ? [
                    "October",
                    "November",
                    "December",
                    "January",
                    "February",
                    "March",
                  ]
                : categories,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessProcessDetails]);

  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={height}
      />
    </div>
  );
};
