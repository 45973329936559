import { all, fork } from "redux-saga/effects";
import LayoutSaga from "./layout/saga";
import DashboardSaga from "./dashboard/saga";
import authSaga from "./auth/login/saga";

export default function* rootSaga() {
  const storedAuthUser = localStorage.getItem("authUser");
  const obj = storedAuthUser ? JSON.parse(storedAuthUser) : null;
  const testerRoleFlag = obj && obj.user && obj.user.testerRole;
  const viewerRoleFlag = obj && obj.user && obj.user.viewerRole;
  const privilegedRoleFlag = obj && obj.user && obj.user.privilegeUserRole;
  yield all([
    //public
    (testerRoleFlag || viewerRoleFlag || privilegedRoleFlag) &&
      fork(DashboardSaga),
    fork(LayoutSaga),
    fork(authSaga),
  ]);
}
