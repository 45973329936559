import React from "react";

const ProgressBar = ({
  Passed = 0,
  Failed = 0,
  Warning = 0,
  Undefined = 0,
}) => {
  const circleStyle = {
    width: "13px",
    height: "13px",
    borderRadius: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "8px",
    color: "white",
    marginLeft: "2px",
  };

  const marginZero = {
    display: "flex",
    flexWrap: "nowrap",
  };

  const redCircleStyle = {
    ...circleStyle,
    backgroundColor: "#ff3d60",
  };

  const greenCircleStyle = {
    ...circleStyle,
    backgroundColor: "#11c46e",
  };

  const blueCircleStyle = {
    ...circleStyle,
    backgroundColor: "#9da1a6",
  };

  const yellowCircleStyle = {
    ...circleStyle,
    backgroundColor: "#f1b44c",
  };

  return (
    <div style={marginZero}>
      <div style={greenCircleStyle}>{Passed}</div>

      <div style={redCircleStyle}>{Failed}</div>

      <div style={yellowCircleStyle}>{Warning}</div>

      <div style={blueCircleStyle}>{Undefined}</div>
    </div>
  );
};

export default ProgressBar;
