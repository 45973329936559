import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export const DonutChart = (props) => {
  const { businessProcessDetails, height } = props;
  // console.log(businessProcessDetails);
  const chartDetails = {
    series: [],
    options: {
      chart: {
        height: height,
        type: "donut",
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
      },
      responsive: [
        {
          //   breakpoint: 480,
          options: {
            // chart: {
            //   width: 200,
            // },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      // title: {
      //   text: "",
      //   floating: true,
      //   offsetY: 330,
      //   align: "center",
      //   style: {
      //     color: "#444",
      //   },
      // },
    },
  };

  const [chartData, setChartData] = useState(chartDetails);

  useEffect(() => {
    if (businessProcessDetails?.length) {
      const seriesData = [];
      const xAxisCategories = [];

      businessProcessDetails.forEach((rec) => {
        if (rec?.processName !== "Total") {
          seriesData.push(rec?.Count || 0);
          xAxisCategories.push(rec?.processName || "");
        }
      });
      const series = [...seriesData];
      // [
      //   {
      //     name: "Inflation",
      //     data: seriesData,
      //   },
      // ];
      const categories = xAxisCategories;
      setChartData({
        ...chartData,
        series: series,
        options: {
          ...chartData.options,
          labels: [...categories],
          // xaxis: {
          //   ...chartData.options.xaxis,
          //   categories: categories,
          // },
        },
      });
    }
  }, [businessProcessDetails]);

  return (
    <>
      {/* {name} */}
      <div style={{ width: "100%" }}>
        {/* <div id="chart"> */}
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
          height={height}
        />
        {/* <Typography>{name || ""}</Typography> */}
        {/* </div> */}
        {/* <div id="html-dist"></div> */}
      </div>
    </>
  );
};
