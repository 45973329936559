import { useState } from "react";


const useProfile = () => {


  const getLoggedinUser = () => {
    const user = localStorage.getItem("authUser");
    if (!user) {
      return null;
    } else {
      return JSON.parse(user);
    }
  };


  const userProfileSession = getLoggedinUser();

  const [loading] = useState(userProfileSession ? false : true);
  const [userProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  return { userProfile, loading };
};

export { useProfile };
