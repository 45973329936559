export const newApiEndpoints = {
  CREATE_USER: "/internal/createUser",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  GET_TENANTS: "/api/tenant",
  GET_SCENARIO_RUN: "/uiapi/scenariorun",
  GET_TEST_STEPS_LOG: "/uiapi/teststeplog",
  QUICK_LINKS: "/uiapi/quicklinks",
  ANNOUNCEMENTS: "/uiapi/announcement",
  NOTIFICATIONS: "/uiapi/notifications",
  SCENARIO_RUN_DETAILS: "/uiapi/scenarioRIIdDetails",
  SUITE_RUN_STATUS: "/uiapi/statusSuiteRunId",
  TEST_STEPS: "/uiapi/teststeps",
  SUITE_DETAILS: "/uiapi/suitedetails",
  FA_DETAILS: "/uiapi/fa",
  DASHBOARD: "/uiapi/dashboard",
};

export const apiEndpoints = {
  // ====================================================
  // ============== Same in Both ========================
  GET_TENANTS: "/api/tenant",
  QUICK_LINKS: "/uiapi/quicklinks",
  ANNOUNCEMENTS: "/uiapi/announcement",
  NOTIFICATIONS: "/uiapi/notifications",
  SCENARIO_RUN_DETAILS: "/uiapi/scenarioRIIdDetails",
  SUITE_DETAILS: "/uiapi/suitedetails",
  FA_DETAILS: "/uiapi/fa",
  DASHBOARD: "/uiapi/dashboard",
  // ====================================================
  // ============== Not available in new APIs=============
  // LOGIN: "/uiapi/login",
  TEST_RUN_SRI: "/api/testrun/sri/", // NOT IN NEW
  TEST_RUN_SCRI: "/api/testrun/scri/", // NOT IN NEW
  TM_START: "/uiapi/tm/details", // NOT IN NEW
  TEST_RUN_SUITE: "/uiapi/tm/runSuite", // NOT IN NEW
  // ====================================================
  // ============== Not available in old APIs============
  CREATE_USER: "/internal/createUser",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  GET_SCENARIO_RUN: "/uiapi/scenariorun",
  GET_TEST_STEPS_LOG: "/uiapi/teststeplog",
  SUITE_RUN_STATUS: "/uiapi/statusSuiteRunId",
  TEST_STEPS: "/uiapi/teststeps",
  SUIT_RUN_LIST: "/uiapi/suiteRIList",
  // ==============
  GET_ALL_SCHEDULE: "/uiapi/jobs",
  CREATE_UPDATE_DELETE_SCHEDULE: "/uiapi/job",
  // File Upload ==============
  TEST_SUITE_FILE_UPLOAD: "/file/customerdata",
  GET_UPLOADED_FILE_LIST: "/file/customerdata/list/",
  DOWNLOAD_TEMPLATE_FILE: "/file/template",
  EXECUTED_TEST_LIST: "/uiapi/testmanager",
  CANCEL_SUITE_RUN: "/uiapi/tm/stopSuite",
  // Admin Manage User
  USER_LIST: "/user/list",
  USER_DETAILS: "/user/other",
  USER_PASSWORD_UPDATE: "/user/password",
  USER_UPDATE_SELF: "/user/self",
  USER_DELETE: "/user",
};
