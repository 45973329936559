export const AES_KEY = "Secur3_Key!_f0r_AES_Encrypt1on_!";
export const AES_IV = "seWBVfrgMwPQl5dc"; //"seWBVfrgMwPQl5dccJu7WS7AwDJKx1Kd";
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const weekNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const recurrenceConstant = [
  "Daily Recurrence Criteria",
  "Weekly Recurrence Criteria",
  "Monthly Recurrence Criteria",
];
