// Import required modules and components
import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Box } from "@mui/material";

// Functional component ComparisonFunctionalArea
export const ComparisonFunctionalArea = ({ RenderComparisonTypeDetails }) => {
  return (
    <Col id="component_2">
      <Card className="mb-3">
        <CardBody className="card-body-dash">
          <CardHeader className="border-bottom-1-5px-solid-f3f3f4 bg-transparent">
            <Row>
              <Col className="col-sm-1 w150px">
                <h5 className="mb-0 font-size-14 mt-2">Functional Area</h5>
              </Col>
              <Col className="page-title-box d-sm-flex align-items-center justify-content-between"></Col>
            </Row>
          </CardHeader>
          <Row className="">
            <Col className="" style={{ height: "250px" }}>
              <Box marginLeft={2}>{RenderComparisonTypeDetails}</Box>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
