import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

const TableCellDetail = ({ row, keyName, type, rowIndex, handleEdit }) => {
  return (
    <>
      {row.isEditable ? (
        <TextField
          size="small"
          type={type || "text"}
          value={row[keyName]}
          onChange={(e) =>
            handleEdit(e, row.id, keyName, e.target.value, rowIndex)
          }
        />
      ) : (
        <Typography>{row[keyName]}</Typography>
      )}
    </>
  );
};

export const TenantDetailsDrawer = (props) => {
  const { tenantDrawerDetails, setTenantDrawerDetails, tenantData } = props;

  useEffect(() => {
    setTenantDrawerDetails(tenantData);
  }, []);

  const handleEdit = (event, id, field, value, rowIndex) => {
    let updatedTenantData = getTenantDrawerDetails();
    updatedTenantData = [...updatedTenantData].map((rec) => {
      const data = { ...rec };
      if (data.id === id) {
        data[field] = value;
      }
      return data;
    });

    setTenantDrawerDetails(updatedTenantData);
  };
  const handleSaveRowClick = (event, row, rowIndex) => {
    let updatedTenantData = getTenantDrawerDetails();
    updatedTenantData = [...updatedTenantData].map((rec) => {
      const data = { ...rec };
      if (data.id === row.id) {
        data.isEditable = false;
      }
      // else {
      // data.isEditable = false;
      // }
      return data;
    });

    setTenantDrawerDetails(updatedTenantData);
  };
  const handleDeleteRowClick = (event, row, rowIndex) => {
    let updatedTenantData = getTenantDrawerDetails();
    updatedTenantData = [...updatedTenantData].filter(
      (rec) => rec.id !== row.id
    );

    setTenantDrawerDetails(updatedTenantData);
  };
  const handleEditRowClick = (event, row, rowIndex) => {
    let updatedTenantData = getTenantDrawerDetails();
    updatedTenantData = [...updatedTenantData].map((rec) => {
      const data = { ...rec };
      if (data.id === row.id) {
        data.isEditable = true;
      }
      return data;
    });

    setTenantDrawerDetails(updatedTenantData);
  };
  const handleShowPasswordClick = (event, row, rowIndex, type) => {
    let updatedTenantData = getTenantDrawerDetails();
    updatedTenantData = [...updatedTenantData].map((rec) => {
      const data = { ...rec };
      if (data.id === row.id) {
        data.isPasswordVisible = Boolean(type === "Show");
      }
      return data;
    });

    setTenantDrawerDetails(updatedTenantData);
  };
  const insertNewRecord = (event) => {
    let updatedTenantData = getTenantDrawerDetails();
    const lastItem = updatedTenantData[updatedTenantData.length - 1];
    const newRecord = {
      id: lastItem?.id ? lastItem.id + 1 : 1,
      tenantName: "",
      loginURL: "",
      nameDetails: "",
      pwddetails: "",
      isEditable: true,
      isPasswordVisible: false,
    };
    updatedTenantData.unshift({ ...newRecord });
    setTenantDrawerDetails(updatedTenantData);
  };

  const getTenantDrawerDetails = () => tenantDrawerDetails || [];

  const getPasswordDisplay = (row) => {
    return row.isPasswordVisible ? row.pwddetails : "******";
  };
  const renderPasswordToggle = (isVisible, row, rowIndex) => {
    const isPasswordPresent = !!row.pwddetails;
    const tooltipTitle = isVisible ? "Hide Password" : "Show Password";
    const icon = isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />;
    const actionLabel = isVisible ? "Hide" : "Show";

    return isPasswordPresent ? (
      <Tooltip title={tooltipTitle} arrow>
        <IconButton
          aria-label={tooltipTitle}
          color="primary"
          onClick={(event) =>
            handleShowPasswordClick(event, row, rowIndex, actionLabel)
          }
        >
          {icon}
        </IconButton>
      </Tooltip>
    ) : null;
  };

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
        <Box>
          <Tooltip title="Create" arrow>
            <IconButton
              aria-label="Create"
              color="primary"
              onClick={(event) => insertNewRecord(event)}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                width={200}
                style={{ fontWeight: "bold" }}
              >
                Tenant Name
              </TableCell>
              <TableCell
                align="left"
                width={200}
                style={{ fontWeight: "bold" }}
              >
                Login URL
              </TableCell>
              <TableCell
                align="left"
                width={200}
                style={{ fontWeight: "bold" }}
              >
                Username
              </TableCell>
              <TableCell
                align="left"
                width={200}
                style={{ fontWeight: "bold" }}
              >
                Password
              </TableCell>
              <TableCell
                align="left"
                width={100}
                style={{ fontWeight: "bold" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getTenantDrawerDetails()?.map((row, rowIndex) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="left"
                  width={200}
                  component="th"
                  scope="row"
                  style={{ padding: 10 }}
                >
                  <TableCellDetail
                    row={row}
                    keyName={"tenantName"}
                    type={"text"}
                    rowIndex={rowIndex}
                    handleEdit={handleEdit}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  width={200}
                  component="th"
                  scope="row"
                  style={{ padding: 10 }}
                >
                  <TableCellDetail
                    row={row}
                    keyName={"loginURL"}
                    type={"url"}
                    rowIndex={rowIndex}
                    handleEdit={handleEdit}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  width={200}
                  component="th"
                  scope="row"
                  style={{ padding: 10 }}
                >
                  <TableCellDetail
                    row={row}
                    keyName={"nameDetails"}
                    type={"text"}
                    rowIndex={rowIndex}
                    handleEdit={handleEdit}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  width={200}
                  component="th"
                  scope="row"
                  style={{ padding: 10 }}
                >
                  {row.isEditable ? (
                    <TextField
                      size="small"
                      type="pwddetails"
                      value={row.pwddetails}
                      onChange={(e) =>
                        handleEdit(
                          e,
                          row.id,
                          "pwddetails",
                          e.target.value,
                          rowIndex
                        )
                      }
                    />
                  ) : (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"start"}
                    >
                      <Typography marginTop={row.isPasswordVisible ? 0 : 1}>
                        {getPasswordDisplay(row)}
                      </Typography>
                      {renderPasswordToggle(
                        row.isPasswordVisible,
                        row,
                        rowIndex
                      )}
                    </Box>
                  )}
                </TableCell>

                <TableCell
                  align="left"
                  width={100}
                  component="th"
                  scope="row"
                  style={{ padding: 10 }}
                >
                  {row.isEditable ? (
                    <>
                      <Tooltip title="Save" arrow>
                        <IconButton
                          aria-label="Save"
                          color="primary"
                          onClick={(event) =>
                            handleSaveRowClick(event, row, rowIndex)
                          }
                        >
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel" arrow>
                        <IconButton
                          aria-label="Cancel"
                          color="primary"
                          onClick={(event) =>
                            handleSaveRowClick(event, row, rowIndex)
                          }
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          aria-label="Edit"
                          color="primary"
                          onClick={(event) =>
                            handleEditRowClick(event, row, rowIndex)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          aria-label="Delete"
                          color="primary"
                          onClick={(event) =>
                            handleDeleteRowClick(event, row, rowIndex)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

TableCellDetail.prototype = {
  row: PropTypes.object,
  keyName: PropTypes.string,
  type: PropTypes.string,
  rowIndex: PropTypes.number,
  handleEdit: PropTypes.func,
};

TenantDetailsDrawer.prototype = {
  tenantDrawerDetails: PropTypes.arrayOf(PropTypes.object),
  setTenantDrawerDetails: PropTypes.func,
  tenantData: PropTypes.arrayOf(PropTypes.object),
};

TableCellDetail.defaultProps = {
  row: {},
  keyName: "",
  type: "",
  rowIndex: 0,
  handleEdit: () => {},
};
TenantDetailsDrawer.defaultProps = {
  tenantDrawerDetails: [],
  setTenantDrawerDetails: () => {},
  tenantData: [],
};
