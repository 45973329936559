import React, { useState } from "react";
import { Row, Col } from "reactstrap";

const LogsPopUp = (props) => {
  const {
    logDetails,
    createServiceNowTicket,
    openTicketForm,
    previewFile,
    viewFile,
    isImage,
  } = props;
  const [selectedStatuses, setSelectedStatuses] = useState([
    "alerts",
    "error",
    "data",
    "debug",
  ]);

  function handleCheckboxChange(status) {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter((s) => s !== status));
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  }

  // const logDetails = props.logD;

  // Filter logDetails based on selectedStatuses
  const filteredLogDetails = logDetails.filter(
    (item) =>
      selectedStatuses.length === 0 || selectedStatuses.includes(item.logStatus)
  );

  return (
    <React.Fragment>
      <div>
        {/* <Row>
          <Col className="col">
            <label>
              <input
                type="checkbox"
                checked={selectedStatuses.includes("alerts")}
                onChange={() => handleCheckboxChange("alerts")}
              />
              Alerts
            </label>
          </Col>
          <Col className="col">
            <label>
              <input
                type="checkbox"
                checked={selectedStatuses.includes("error")}
                onChange={() => handleCheckboxChange("error")}
              />
              Error
            </label>
          </Col>
          <Col className="col">
            <label>
              <input
                type="checkbox"
                checked={selectedStatuses.includes("data")}
                onChange={() => handleCheckboxChange("data")}
              />
              Data
            </label>
          </Col>
          <Col className="col">
            <label>
              <input
                type="checkbox"
                checked={selectedStatuses.includes("debug")}
                onChange={() => handleCheckboxChange("debug")}
              />
              Debug
            </label>
          </Col>
        </Row> */}

        <div
          className="table-responsive"
          style={{ backgroundColor: "#ffffff !important" }}
        >
          <table
            style={{ backgroundColor: "#ffffff !important" }}
            className="table table-centered table-nowrap mb-0"
          >
            {/* <thead>
              <tr>
                <th scope="col">Log Ids</th>
                <th scope="col">Status</th>
                <th scope="col">Log Text</th>
                <th scope="col">Download</th>
                <th scope="col">Preview</th>
                <th scope="col"></th>
              </tr>
            </thead> */}
            <tbody>
              {filteredLogDetails.map((item, key) => (
                <tr key={item.stepRILogId}>
                  {/* <td>{item.stepRILogId}</td> */}
                  {/* <td>{item.logStatus}</td>
                  <td>{item.logText}</td> */}
                  <td>
                    <a
                      href={item.screenShotURL}
                      className="btn btn-success btn-sm waves-effect waves-light"
                    >
                      Download
                    </a>
                  </td>
                  {isImage(logDetails[0]?.screenShotURL.split("?")[0]) ? (
                    <td>
                      <button
                        className="btn btn-success btn-sm waves-effect waves-light"
                        onClick={(e) => {
                          previewFile(e, logDetails);
                        }}
                        disabled={viewFile?.open && viewFile?.imagePath}
                      >
                        View
                      </button>
                    </td>
                  ) : (
                    <></>
                  )}
                  <td>
                    <button
                      disabled={openTicketForm}
                      className="btn btn-success btn-sm waves-effect waves-light"
                      onClick={(e) => {
                        createServiceNowTicket(e, logDetails);
                      }}
                    >
                      Create Ticket
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LogsPopUp;
