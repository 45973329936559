import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import {
  downloadTemplateFileForTestSuite,
  suiteRunAPI,
  uploadFile,
} from "../../store/dashboard/orchestractor";
import { useDispatch } from "react-redux";
import { setLoadingState, showError } from "../../store/dashboard/actions";
import { Box, FormControl, Link, Select, Typography } from "@mui/material";
import { FileUpload } from "./FileUpload";
import { useProfile } from "../../Hooks/UserHooks";
import { sortByName } from "../../config/util";

const RunTestmgr = (props) => {
  const { rawData, tenants } = props;
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const [selectedFileDetails, setSelectedFileDetails] = useState([]);
  const [selectedSuiteScripts, setSelectedSuiteScripts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [fileUploadError, setFileUploadError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [fileUploadSuccess, setFileUploadSuccess] = useState({
    isError: false,
    errorMessage: "",
  });
  const [fileUploaded, setFileUploaded] = useState(true);

  useEffect(() => {
    if (rawData?.suiteScriptListByFA?.length) {
      getDefaultTestSuitId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  const getDefaultTestSuitId = async () => {
    const defaultFunctionArea = rawData?.suiteScriptListByFA[0];
    const defaultModules = defaultFunctionArea?.modules[0];
    const defaultSelection = defaultModules?.suiteScripts;

    const defaultModuleName = defaultModules?.moduleName;

    const defaultSuiteId = defaultSelection?.length
      ? defaultSelection[0]?.suiteId
      : 0;
    const defaultSuiteName = defaultSelection?.length
      ? defaultSelection[0]?.suiteName
      : 0;
    const updatedFormValue = {
      ...formDetails,
      functionalArea: defaultFunctionArea?.faId || "",
      functionalAreaName: defaultFunctionArea?.faShortName || "",
      testSuite: defaultSuiteId ? parseInt(defaultSuiteId) : 0,
      testSuiteName: defaultSuiteName || "",
      selectedTenant: tenants?.length ? tenants[0]?.tenantId : 1,
      suiteScript: defaultSelection[0],
      item: rawData?.suiteScriptListByFA[0],
      moduleList: defaultFunctionArea?.modules,
      moduleName: defaultModuleName,
      fileList: [],
      selectedFile: null,
    };
    setSelectedSuiteScripts(defaultSelection);
    setFormDetails(updatedFormValue);
  };

  const handleRunClick = async () => {
    const {
      testSuite,
      testSuiteName,
      functionalArea,
      functionalAreaName,
      selectedTenant,
    } = formDetails;
    const filename = selectedFileDetails[0].fileDetails.name;
    dispatch(setLoadingState(true));
    const response = await suiteRunAPI(
      functionalArea,
      testSuite,
      selectedTenant,
      filename
    );
    if (response?.error) {
      const message = `${response?.error}`;
      dispatch(showError(true, message, "error"));
    } else {
      const message = `"${functionalAreaName}-${testSuiteName}" is initiated`;
      dispatch(showError(true, message, "info"));
    }
    setFileUploaded(true);
    setSelectedFileDetails([]);
    dispatch(setLoadingState(false));
  };

  if (!rawData) {
    // You can show a loading state or handle the absence of data
    return <div>Loading...</div>;
  }
  const getSelectedFileDetails = (suiteScript, item) => {
    if (selectedFileDetails?.length) {
      const selectedFile = selectedFileDetails?.find(
        (rec) => rec?.suiteId === formDetails?.testSuite
      );
      return selectedFile?.fileDetails?.name || "";
    } else {
      return "";
    }
  };
  const handleFileUpload = async (files) => {
    try {
      const { testSuite, testSuiteName, functionalArea } = formDetails;

      const fileName = testSuiteName
        ? testSuiteName.replace(/[ -]/g, "")
        : "test_template";
      const aFileName = files[0]?.name.replace(/[ _ -]/g, "");
      const completeFileName = `${fileName}.xlsx`;
      if (files?.length) {
        if (aFileName?.toLowerCase() === completeFileName?.toLowerCase()) {
          setLoader(true);
          dispatch(setLoadingState(true));
          const selectedFile = files[0];
          const formData = new FormData();
          formData.append("file", selectedFile);
          const response = await uploadFile(
            functionalArea,
            testSuite,
            formData
          );
          if (response) {
            setFileUploadSuccess({
              isError: true,
              errorMessage: "File uploaded successfully.",
            });
            setFileUploadError({
              isError: false,
              errorMessage: "",
            });
            setFileUploaded(false);
            setSelectedFileDetails([
              {
                fileDetails: selectedFile,
                suiteId: testSuite || 0,
                faId: functionalArea || 0,
              },
            ]);
            setTimeout(() => {
              setFileUploadSuccess({
                isError: false,
                errorMessage: "",
              });
            }, 10000);
          } else {
            setFileUploadError({
              isError: true,
              errorMessage: "Error with file upload...",
            });
            setFileUploadSuccess({
              isError: false,
              errorMessage: "",
            });
            setFileUploaded(true);
          }
          setLoader(false);
          dispatch(setLoadingState(false));
        } else {
          setFileUploadError({
            isError: true,
            errorMessage: "Please select correct file...",
          });
          setFileUploadSuccess({
            isError: false,
            errorMessage: "",
          });
          setFileUploaded(true);
        }
      } else {
        setFileUploadError({
          isError: true,
          errorMessage: "Please select correct file...",
        });
        setFileUploadSuccess({
          isError: false,
          errorMessage: "",
        });
        setFileUploaded(true);
      }
    } catch (error) {
      console.error(error);
      setFileUploaded(true);
    }
  };
  const handleRemoveUploadedFile = (event, suiteScript, item) => {
    try {
      if (suiteScript?.suiteId) {
        const isExist = selectedFileDetails?.filter(
          (rec) => rec?.suiteId !== suiteScript?.suiteId
        );
        setSelectedFileDetails([...isExist]);
      } else {
        console.info("Please remove correct file...");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getTestSuiteName = (value = 0, funcAreaId = 0) => {
    const data = rawData?.suiteScriptListByFA?.length
      ? rawData?.suiteScriptListByFA
      : [];
    let testSuiteName = "";
    data.forEach((item) => {
      if (item?.faId === parseInt(funcAreaId)) {
        if (item?.modules?.length) {
          item?.modules?.forEach((rec) => {
            if (rec?.suiteScripts?.length) {
              rec?.suiteScripts.forEach((suiteScript) => {
                if (suiteScript?.suiteId === value) {
                  testSuiteName = suiteScript?.suiteName || "";
                }
              });
            }
          });
        }
      }
    });

    return testSuiteName || "";
  };
  const handleChangeFunctionalArea = (event) => {
    const { name, value } = event.target;
    const idVal = value ? parseInt(value) : 0;
    const selectedFunctionalAreaDetails = rawData?.suiteScriptListByFA.find(
      (item) => item.faId === idVal
    );
    const selectedFAreaModules = selectedFunctionalAreaDetails?.modules || [];
    const selectedSuitScripts =
      selectedFAreaModules?.length &&
      selectedFAreaModules[0]?.suiteScripts?.length
        ? selectedFAreaModules[0]?.suiteScripts[0]
        : null;
    const functionalAreaName = selectedFunctionalAreaDetails?.faShortName || "";
    const defaultModuleName = selectedFAreaModules?.length
      ? selectedFAreaModules[0]?.moduleName
      : "";

    setFileUploaded(true);
    setFileUploadSuccess({
      isError: false,
      errorMessage: "",
    });
    setSelectedSuiteScripts(selectedFAreaModules[0]?.suiteScripts || []);
    setFormDetails({
      ...formDetails,
      [name]: idVal,
      functionalAreaName: functionalAreaName || "",
      testSuite: selectedSuitScripts?.suiteId,
      testSuiteName: selectedSuitScripts?.suiteName,
      fileDetails: null,
      moduleList: selectedFAreaModules,
      moduleName: defaultModuleName,
    });
  };
  const handleChangeModule = (event) => {
    const { name, value } = event.target;
    const { functionalArea, functionalAreaName } = formDetails || {};
    const selectedFunctionalAreaDetails = rawData?.suiteScriptListByFA.find(
      (item) => item.faId === functionalArea
    );
    const selectedFAreaModules = selectedFunctionalAreaDetails?.modules?.length
      ? selectedFunctionalAreaDetails?.modules?.find(
          (mod) => mod?.moduleName === value
        )
      : {};
    const selectedSuitScripts = selectedFAreaModules?.suiteScripts?.length
      ? selectedFAreaModules?.suiteScripts[0]
      : null;

    setFileUploaded(true);
    setFileUploadSuccess({
      isError: false,
      errorMessage: "",
    });
    setSelectedSuiteScripts(selectedFAreaModules?.suiteScripts || []);
    setFormDetails({
      ...formDetails,
      [name]: value,
      functionalAreaName: functionalAreaName || "",
      testSuite: selectedSuitScripts?.suiteId,
      testSuiteName: selectedSuitScripts?.suiteName,
      fileDetails: null,
    });
  };
  const handleChangeTestSuite = (event) => {
    const { name, value } = event.target;
    const idVal = value ? parseInt(value) : 0;
    const testSuiteName = getTestSuiteName(idVal, formDetails?.functionalArea);
    setFileUploaded(true);
    setFileUploadSuccess({
      isError: false,
      errorMessage: "",
    });
    setFormDetails({
      ...formDetails,
      [name]: idVal,
      testSuiteName: testSuiteName || "",
      fileDetails: null,
    });
  };
  const handleTenantChange = (event) => {
    const { name, value } = event.target;
    const idVal = value ? parseInt(value) : 0;
    setFormDetails({
      ...formDetails,
      [name]: idVal,
    });
  };
  const downloadTestSuiteTemplate = async (suiteScript, item) => {
    try {
      dispatch(setLoadingState(true));
      console.log(formDetails, formDetails?.testSuiteName);
      const resp = await downloadTemplateFileForTestSuite(
        formDetails?.functionalArea,
        formDetails?.testSuite
      );
      if (resp?.data?.fileUrl) {
        const url = resp?.data?.fileUrl;
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch(setLoadingState(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoadingState(false));
    }
  };
  const handleViewErrorClick = (event) => {
    event.preventDefault();
    console.log("View Details...");
  };
  const capitalizeFLetter = (string = "") => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="page-title-right ml-auto"
                  style={{ display: "flex" }}
                >
                  <div style={{ width: "250px" }}>Suite Script Listing</div>
                </div>
              </div>
            </CardTitle>
            <div style={{ background: "#ffffff" }}>
              <Row>
                <div className="col-md-6 col-sm-12">
                  <Row className="mb-2 row">
                    <div className="card-title-desc-filters">
                      Click the Functional Areas below to expand/collapse
                    </div>
                  </Row>
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="run-test-tenant-select"
                      >
                        Choose your Tenant
                      </label>
                    </Col>
                    <Col className="col-9">
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 350,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                          <Select
                            native
                            aria-labelledby="run-test-tenant-select"
                            defaultValue=""
                            value={formDetails?.selectedTenant || null}
                            name="selectedTenant"
                            onChange={handleTenantChange}
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            {tenants?.length &&
                              tenants.map((tenant) => {
                                return (
                                  <option
                                    className="font-size-12"
                                    key={tenant.tenantId || 0}
                                    value={tenant.tenantId || 0}
                                  >
                                    {tenant.tenantName || ""}
                                  </option>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Col>
                  </Row>
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="run-test-functional-area"
                      >
                        Functional Area
                      </label>
                    </Col>
                    <Col className="col-9">
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 350,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                          <Select
                            native
                            aria-labelledby="run-test-functional-area"
                            defaultValue=""
                            value={formDetails?.functionalArea || null}
                            name="functionalArea"
                            onChange={handleChangeFunctionalArea}
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            {rawData &&
                              rawData?.suiteScriptListByFA &&
                              rawData?.suiteScriptListByFA?.length &&
                              rawData?.suiteScriptListByFA
                                ?.filter((rec) => rec?.modules?.length)
                                ?.map((testSuite) => {
                                  return (
                                    <option
                                      className="font-size-12"
                                      key={testSuite.faId || 0}
                                      value={testSuite.faId || 0}
                                    >
                                      {testSuite?.faShortName || ""}
                                    </option>
                                  );
                                })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Col>
                  </Row>
                  {formDetails?.moduleList?.length &&
                  formDetails?.moduleList[0].moduleName !== "default" ? (
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="run-test-module-category"
                        >
                          {formDetails?.functionalAreaName === "HCM"
                            ? "Module"
                            : "Category"}
                        </label>
                      </Col>
                      <Col className="col-9">
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControl
                            sx={{
                              m: 1,
                              minWidth: 350,
                              minHeight: 20,
                              margin: "3px",
                            }}
                          >
                            <Select
                              native
                              aria-labelledby="run-test-module-category"
                              defaultValue=""
                              value={formDetails?.moduleName || null}
                              name="moduleName"
                              onChange={handleChangeModule}
                              style={{ height: 25 }}
                              className="font-size-12"
                            >
                              {formDetails?.moduleList?.length ? (
                                sortByName(
                                  formDetails?.moduleList,
                                  "moduleName"
                                ).map((rec, index) => {
                                  return (
                                    <option
                                      className="font-size-12"
                                      key={`${index}_${
                                        formDetails?.functionalArea
                                      }_${rec?.moduleName || ""}`}
                                      value={rec?.moduleName || ""}
                                    >
                                      {capitalizeFLetter(rec?.moduleName || "")}
                                    </option>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row className="mb-2 row">
                    <Col className="col-3">
                      <label
                        className="small-select"
                        htmlFor="run-test-test-suite"
                      >
                        Test Suite
                      </label>
                    </Col>
                    <Col className="col-9">
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 350,
                            minHeight: 20,
                            margin: "3px",
                          }}
                        >
                          <Select
                            native
                            aria-labelledby="run-test-test-suite"
                            defaultValue=""
                            value={formDetails?.testSuite || null}
                            name="testSuite"
                            onChange={handleChangeTestSuite}
                            style={{ height: 25 }}
                            className="font-size-12"
                          >
                            {selectedSuiteScripts?.length ? (
                              sortByName(selectedSuiteScripts, "suiteName").map(
                                (suiteScript) => {
                                  return (
                                    <option
                                      className="font-size-12"
                                      key={suiteScript?.suiteId || 0}
                                      value={suiteScript?.suiteId || 0}
                                    >
                                      {suiteScript?.suiteName || ""}
                                    </option>
                                  );
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </Select>
                        </FormControl>
                        <button
                          className="btn btn-success btn-sm waves-effect waves-light"
                          style={{ width: 80, marginLeft: "10px" }}
                          onClick={() =>
                            downloadTestSuiteTemplate(
                              formDetails?.suiteScript,
                              formDetails?.item
                            )
                          }
                        >
                          Download
                        </button>
                      </Box>
                    </Col>
                  </Row>
                </div>
                <div className="col-md-6 col-sm-12 ">
                  {userProfile?.user?.viewerRole ? (
                    <></>
                  ) : (
                    <>
                      <Box>
                        <FileUpload
                          formDetails={formDetails}
                          handleFileUpload={(files) => handleFileUpload(files)}
                          getSelectedFileDetails={getSelectedFileDetails}
                          handleRemoveUploadedFile={handleRemoveUploadedFile}
                        />
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent:
                            fileUploadError?.isError ||
                            fileUploadSuccess?.isError
                              ? "space-between"
                              : "flex-end",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        {fileUploadError?.isError ? (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{ color: "#D32F2F", marginRight: "10px" }}
                            >
                              {fileUploadError?.errorMessage}
                            </Typography>
                            <Link href="#" onClick={handleViewErrorClick}>
                              View Error
                            </Link>
                          </Box>
                        ) : (
                          <></>
                        )}
                        {fileUploadSuccess?.isError ? (
                          <Box>
                            <Typography
                              variant="body1"
                              paragraph
                              style={{ color: "#0ac074" }}
                            >
                              {fileUploadSuccess?.errorMessage}
                            </Typography>
                          </Box>
                        ) : (
                          <></>
                        )}
                        <button
                          className="btn btn-success btn-sm waves-effect waves-light"
                          style={{ width: 61 }}
                          disabled={fileUploaded}
                          onClick={() => handleRunClick()}
                        >
                          Run
                        </button>
                      </Box>
                    </>
                  )}
                </div>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
      {loader ? <span></span> : null}
    </>
  );
};

export default RunTestmgr;
