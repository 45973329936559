import React, { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Radial4Cards from "./Charts/CardChart";
import { connect } from "react-redux";
import { setFAAreaSelection } from "../../store/dashboard/actions";

const AreaCards = ({ dashboardData, FASelection, setFAAreaSelection }) => {
  // This component is called AreaCards and receives props dashboardData, FASelection, and setFAAreaSelection.

  const handleButtonClick = (faId) => {
    // This function is called when a radio button is clicked. It sets the FA area selection.
    setFAAreaSelection(faId);
  };

  useEffect(() => {
    // This effect runs when the FASelection prop changes.
    // It checks if the FASelection is not null and if so, it selects the corresponding radio button.
    if (FASelection !== null) {
      const radioElement = document.getElementById(`card-${FASelection}`);
      if (radioElement) {
        radioElement.checked = true;
      }
    }
  }, [FASelection]);

  if (!dashboardData || dashboardData.length === 0) {
    return <p>No records found.</p>;
  }

  return (
    // The component returns JSX elements to be rendered.
    <React.Fragment>
      {/* Using React.Fragment to avoid unnecessary wrapper divs */}
      {dashboardData.map((data) => (
        // Mapping through the dashboardData array to render each data as a card.
        <Col xl={3} sm={6} key={data.faId} className="mt-2">
          {/* Using Bootstrap's grid system to layout the cards and adding the mt-2 class for margin top. */}
          <Card className="card-containerv2">
            {/* Using Bootstrap's Card component and adding a custom class "card-containerv2" */}
            <input
              type="radio"
              className="btn-check"
              name="btnradiocards"
              id={`card-${data.faId}`}
              autoComplete="off"
              onClick={() => handleButtonClick(data.faId)}
              defaultChecked={data.faId === FASelection}
            />
            {/* Input element to act as a radio button with custom classes */}
            <label className="btn btn-outline-cards btnOverRide" htmlFor={`card-${data.faId}`}>
              {/* Label for the radio button with custom classes */}
              <CardBody className="paddingtopBottom">
                {/* CardBody containing the card content with class "paddingtopBottom" */}
                <Row>
                  {/* Bootstrap's Row component */}
                  <Col className="col-5 cardBodyCol">
                    {/* Column containing the FA short name with class "cardBodyCol" */}
                    <p className="mb-1">{data.faShortName}</p>
                  </Col>
                  <Col className="col-7 myBorder">
                    {/* Column containing the Radial4Cards component with class "myBorder" */}
                    <div id={`radialchart-${data.faId}`} className="apex-charts" dir="ltr">
                      {/* Div element containing the Radial4Cards component with class "apex-charts" */}
                      <Radial4Cards Scores={data.faScenarioRISummary} />
                      {/* Rendering the Radial4Cards component and passing props */}
                      <div className="position-absolute-center">
                        {/* Div to position the total count in the center of the radial chart */}
                        {data.faScenarioTotal}
                      </div>
                    </div>
                    <p className="smallerfontsize"># Scenario Run</p>
                  </Col>
                </Row>
                <Row className="mb-2 row"></Row>
                <Row className="topBorder"></Row>
                <Row className="d-flex align-items-center justify-content-center text-center">
                  {/* Rows for the table headers */}
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <p className="smallTable">Passed</p>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <p className="smallTable">Failed</p>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <p className="smallTable">Warning</p>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <p className="smallTable">Undefined</p>
                  </Col>
                </Row>
                <Row className="paddingz">
                  {/* Rows for the scenario run counts */}
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <small className="font-size-4">{data.faScenarioRISummary.passedCount}</small>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <small className="font-size-4">{data.faScenarioRISummary.failedCount}</small>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <small className="font-size-4">{data.faScenarioRISummary.warningCount}</small>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-center text-center paddingz">
                    <small className="font-size-4">{data.faScenarioRISummary.undefinedCount}</small>
                  </Col>
                </Row>
              </CardBody>
            </label>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  // Mapping the state properties to the component props
  FASelection: state.dashboardData.faAreaSelection,
});

export default connect(mapStateToProps, { setFAAreaSelection })(AreaCards);
