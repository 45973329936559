import {
  GET_TENANTS,
  SET_TENANT_SELECTION,
  GET_FA_AREAS,
  SET_FA_AREA_SELECTION,
  GET_SUITE_LIST,
  SET_SUITE_SELECTION,
  SET_SCENARIO_SELECTION,
  GET_SCENARIO_LIST,
  GET_STEP_LIST,
  CURRENT_SUITE_NAME_SELECTION,
  CURRENT_SUITE_INSTANCE_LIST,
  CURRENT_SCENARIO_NAME_SELECTION,
  SET_LOADING_STATE,
  SET_SEARCH,
  SET_SEARCH_FLAG,
  SHOW_ERROR,
  MENU_SELECTION
} from "./actions";

const initialState = {
  tenants: null,  
  tenantSelection: null,
  startDate: null,
  endDate: null,
  faAreas: null,
  faAreaSelection: null,
  suiteList: null,
  suiteSelection: null,
  scenaioList: null,
  scenarioSelection: null,
  stepList: null,
  suiteInstanceListing:null,
  suiteNameSelectionID:null,
  scenarioNameSelection:0,
  loading:false,
  searchText:null,
  searchType:null,
  searchFlag:false,
  error_flag:false,
  error_msg:null,
  error_type:null,
  menuSelection:0,
};
 
const dashboardData = (state = initialState, action) => {
  switch (action.type) {
    case GET_TENANTS:
      return {
        ...state,
        tenants: action.payload,
      };

    case SET_TENANT_SELECTION:
      return {
        ...state,
        tenantSelection: action.payload.tenantSelection,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case GET_FA_AREAS:
      return {
        ...state,
        faAreas: action.payload,
      };

    case SET_FA_AREA_SELECTION:
      return {
        ...state,
        faAreaSelection: action.payload,
      };

    case GET_SUITE_LIST:
      return {
        ...state,
        suiteList: action.payload,
      };

    case SET_SUITE_SELECTION:
      return {
        ...state,
        suiteSelection: action.payload,
      };
    case GET_STEP_LIST:
      return {
        ...state,
        stepList: action.payload,
      };

    case GET_SCENARIO_LIST:
      return {
        ...state,
        scenaioList: action.payload,
      };

    case SET_SCENARIO_SELECTION:
      return {
        ...state,
        scenarioSelection: action.payload,
      };
    case CURRENT_SUITE_NAME_SELECTION:
        return {
          ...state,
          suiteNameSelectionID: action.payload,
        };
    case CURRENT_SUITE_INSTANCE_LIST:
          return {
            ...state,
            suiteInstanceListing: action.payload,
          };
          
    case CURRENT_SCENARIO_NAME_SELECTION:
            return {
              ...state,
              scenarioNameSelection: action.payload,
            };
    
    case SET_LOADING_STATE:
              return {
                ...state,
                loading: action.payload, 
              };

    case SET_SEARCH:
                return {
                  ...state,
                  searchText: action.payload.searchText, 
                  searchType: action.payload.searchType,
                
                };
              
                
                case SET_SEARCH_FLAG:
                  return {
                    ...state,
                  
                    searchFlag: action.payload
                  };
                  
                  case SHOW_ERROR:
                    return {
                      ...state,
                      error_flag: action.payload.error_flag,
                      error_msg: action.payload.error_msg,
                      error_type: action.payload.error_type
                    };
                    
                    
                    case MENU_SELECTION:
                      return {
                        ...state,
                        menuSelection: action.payload,
                      
                      };

    default:
      return state;
  }
};

export default dashboardData;
 