import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Col,
  Row,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import { useProfile } from "../../Hooks/UserHooks";
import { useState } from "react";
import { StyledDataGrid } from "../generalFunctions";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { EditUserDetails } from "./EditUserDetails";
import { ChangePassword } from "./ChangePassword";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  deleteUser,
  updateSelfUserDetails,
  updateUserDetails,
  updateUserPassword,
} from "../../store/dashboard/orchestractor";
import { setLoadingState, showError } from "../../store/actions";
import { AES_IV, AES_KEY } from "../../config/constant";
import { encryptData } from "../../config/util";
import { CreateUser } from "./CreateUser";

const renderUserRole = (params) => {
  const userRole = params.row.userRole;
  return userRole === "t"
    ? "Tester"
    : userRole === "v"
    ? "Viewer"
    : userRole === "a"
    ? "Admin"
    : userRole === "pv"
    ? "Privileged"
    : "";
};
// const getUserActiveComponent = (params) => {
//   const label = { inputProps: { "aria-label": "Active/In-Active User" } };
//   return <Switch {...label} size="small" />;
// };

export const UserList = (props) => {
  const { userList, getAllUserDetails } = props;
  const dispatch = useDispatch();
  const { userProfile, loading } = useProfile();

  const headerHeight = 28;
  const rowHeight = 26;
  const roleDetails = [
    { name: "Tester", value: "t" },
    { name: "Viewer", value: "v" },
    { name: "Admin", value: "a" },
    { name: "Privileged", value: "pv" },
  ];
  const fNameErrMsg = "First Name is required.";
  const sNameErrMsg = "Last Name is required.";
  // const oldPassErrMsg = "Old Password is required.";
  const newPassErrMsg = "New Password is required.";
  const newPassValueErrMsg = "Password should match given complexity.";
  const confirmPassErrMsg = "Confirm Password is required.";
  // const oldNewSameErrMsg = "Old and new password can not be same.";
  const newCnfMatchErrMsg = "New and Confirm password does not match.";
  const defaultPasswordDetails = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const defaultError = {
    isError: false,
    message: null,
  };
  const defaultCreateUserDetails = {
    userFirstName: "",
    userLastName: "",
    userEmailId: "",
    userRole: "",
    userPassword: "",
    userDisplayName: "",
    // userEmailId: "privileged_user@gmail.com",
    // userLastName: "User",
    // userFirstName: "Privileged",
    // userRole: "pv",
  };

  const [selectedTab, setSelectedTab] = useState(1);
  const [profileDetails, setProfileDetails] = useState(userProfile?.user || {});
  const [rows, setRows] = useState([]);
  const [selectedUserSelection, setSelectedUserSelection] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [userModelType, setUserModelType] = useState(null);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [view, setView] = React.useState("thumbnail");
  const [searchText, setSearchText] = useState("");
  const [passwordDetails, setPasswordDetails] = useState({
    ...defaultPasswordDetails,
  });
  const [profileEditDetails, setProfileEditDetails] = useState({});
  const [fNameErrorMessages, setFNameErrorMessages] = useState(defaultError);
  const [lNameErrorMessages, setLNameErrorMessages] = useState(defaultError);
  // ==================================}
  const [oldPasswordErrorMessages, setOldPasswordErrorMessages] =
    useState(defaultError);
  const [newPasswordErrorMessages, setNewPasswordErrorMessages] =
    useState(defaultError);
  const [confirmPasswordErrorMessages, setConfirmPasswordErrorMessages] =
    useState(defaultError);
  const [passwordCommonErrorMessages, setPasswordCommonErrorMessages] =
    useState(defaultError);

  const [createUserDetails, setCreateUserDetails] = useState(
    defaultCreateUserDetails
  );
  const [fNameCreateErrorMessages, setFNameCreateErrorMessages] =
    useState(defaultError);
  const [lNameCreateErrorMessages, setLNameCreateErrorMessages] =
    useState(defaultError);
  const [emailCreateErrorMessages, setEmailCreateErrorMessages] =
    useState(defaultError);
  const [newPasswordCreateErrorMessages, setNewPasswordCreateErrorMessages] =
    useState(defaultError);

  useEffect(() => {
    // Effect to handle changes in selected suite selection
  }, [selectedUserSelection]);

  useEffect(() => {
    setProfileDetails(userProfile?.user || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userList && userList?.length) {
      const updatedUserList =
        userList && userList?.length
          ? [...userList].map((item, index) => {
              return { ...item, id: index + 1, isActive: true };
            })
          : [];
      setRows(updatedUserList);
    } else {
      setRows([]);
    }
  }, [userList]);

  const handleActiveInactiveChange = (event, params) => {
    event.preventDefault();
    event.stopPropagation();
    const { checked } = event?.target || {};
    let updatedRows = [...rows];
    updatedRows = updatedRows?.map((rec) => {
      let isActive = rec?.isActive || false;
      isActive = params?.row?.userId === rec?.userId ? checked : isActive;
      return { ...rec, isActive };
    });

    setRows([...updatedRows]);
  };
  const handleDeleteUserClick = (params) => {
    setSelectedUserDetails(params);
    setOpen(true);
  };
  const handleEditUserClick = (params, type) => {
    setSelectedUserDetails(type !== "Create" ? params : null);
    setFNameErrorMessages(defaultError);
    setLNameErrorMessages(defaultError);
    resetCreateUserErrors();
    setUserModelType(type);
    setOpenEditUser(true);
  };
  const resetCreateUserErrors = () => {
    setFNameCreateErrorMessages(defaultError);
    setLNameCreateErrorMessages(defaultError);
    setEmailCreateErrorMessages(defaultError);
    setNewPasswordCreateErrorMessages(defaultError);
    setCreateUserDetails(defaultCreateUserDetails);
  };

  const columns = [
    {
      field: "userDisplayName",
      headerName: "User Name",
      width: 200,
      flex: 1,
    },
    {
      field: "userEmailId",
      headerName: "User Email Id",
      width: 350,
      flex: 1,
    },
    {
      field: "userRole",
      headerName: "User Role",
      renderCell: renderUserRole,
      width: 200,
      maxWidth: 200,
    },
    // {
    //   field: "isActive",
    //   headerName: "Active/In-Active",
    //   renderCell: (params) => {
    //     const isActive = params?.row?.isActive || false;
    //     const label = { inputProps: { "aria-label": "Active/In-Active User" } };

    //     return (
    //       <Switch
    //         {...label}
    //         size="small"
    //         checked={isActive}
    //         onChange={(event) => handleActiveInactiveChange(event, params)}
    //       />
    //     );
    //   },
    //   width: 120,
    //   maxWidth: 120,
    // },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        // const status = params.row.status;
        // warning  undefined  passed  failed  running  cancelled
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleDeleteUserClick(params.row)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleEditUserClick(params?.row, "Edit")}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    setSelectedUserSelection(suiteSelection);
    // setSuiteSelection(suiteSelection); // Call setSuiteSelection here when needed
  };
  const getFilteredRows = ({ apiRef }) => {
    gridExpandedSortedRowIdsSelector(apiRef);
  };
  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    // Function to handle export button click to export data as CSV

    const handleExport = (options) => {
      // Set a custom file name for the exported CSV file
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      options.fileName = `manage_user_listing_${timestamp}`;

      apiRef.current.exportDataAsCsv(options);
    };

    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
      >
        <div>
          <h6 style={{ marginBottom: "5px", fontSize: "11px" }}>
            User Listing
          </h6>
        </div>

        <div style={{ display: "none" }}>
          <i
            onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
            className="mdi mdi-microsoft-excel"
            style={{ cursor: "pointer", fontSize: "16px", color: "#4aa3ff" }}
            onMouseEnter={(e) => (e.target.style.color = "#0875e1")}
            onMouseLeave={(e) => (e.target.style.color = "#4aa3ff")}
          ></i>
        </div>
      </div>
    );
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedUserDetails(null);
    setPasswordDetails({ ...defaultPasswordDetails });
  };
  const handleDeleteUser = async () => {
    if (selectedUserDetails) {
      dispatch(setLoadingState(true));
      const response = await deleteUser(selectedUserDetails?.userId);
      if (response) {
        dispatch(setLoadingState(false));
        getAllUserDetails(); // Refresh User List
        setOpen(false);
        setSelectedUserDetails(null);
        setPasswordDetails({ ...defaultPasswordDetails });
        // const message = response || "User deleted successfully";
        // dispatch(showError(true, message, "info"));
      } else {
        dispatch(setLoadingState(false));
      }
    }
  };
  const handleEditUserClose = () => {
    setOpenEditUser(false);
    setSelectedTab(1);
    setSelectedUserDetails(null);
    setPasswordDetails({ ...defaultPasswordDetails });
    resetCreateUserErrors();
  };
  const validateUserProfile = () => {
    if (!profileEditDetails?.userFirstName) {
      setFNameErrorMessages({
        isError: true,
        message: fNameErrMsg,
      });
      return false;
    } else if (profileEditDetails?.userFirstName?.trim() === "") {
      setFNameErrorMessages({
        isError: true,
        message: fNameErrMsg,
      });
      return false;
    }
    if (!profileEditDetails?.userLastName) {
      setLNameErrorMessages({
        isError: true,
        message: sNameErrMsg,
      });
      return false;
    } else if (profileEditDetails?.userLastName?.trim() === "") {
      setLNameErrorMessages({
        isError: true,
        message: sNameErrMsg,
      });
      return false;
    }
    return true;
  };
  const validateUserPassword = () => {
    let regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/;
    // oldPassword
    // if (!passwordDetails?.oldPassword) {
    //   setOldPasswordErrorMessages({
    //     isError: true,
    //     message: oldPassErrMsg,
    //   });
    //   return false;
    // } else if (passwordDetails?.oldPassword?.trim() === "") {
    //   setOldPasswordErrorMessages({
    //     isError: true,
    //     message: oldPassErrMsg,
    //   });
    //   return false;
    // }

    // newPassword
    if (!passwordDetails?.newPassword) {
      setNewPasswordErrorMessages({
        isError: true,
        message: newPassErrMsg,
      });
      return false;
    } else if (passwordDetails?.newPassword?.trim() === "") {
      setNewPasswordErrorMessages({
        isError: true,
        message: newPassErrMsg,
      });
      return false;
    } else if (!regex.test(passwordDetails?.newPassword?.trim())) {
      setNewPasswordErrorMessages({
        isError: true,
        message: newPassValueErrMsg,
      });
      return false;
    }
    // if (
    //   passwordDetails?.oldPassword?.trim() ===
    //   passwordDetails?.newPassword?.trim()
    // ) {
    //   setPasswordCommonErrorMessages({
    //     isError: true,
    //     message: oldNewSameErrMsg,
    //   });
    //   return false;
    // }

    // confirmPassword
    if (!passwordDetails?.confirmPassword) {
      setConfirmPasswordErrorMessages({
        isError: true,
        message: confirmPassErrMsg,
      });
      return false;
    } else if (passwordDetails?.confirmPassword?.trim() === "") {
      setConfirmPasswordErrorMessages({
        isError: true,
        message: confirmPassErrMsg,
      });
      return false;
    }
    if (
      passwordDetails?.confirmPassword?.trim() !==
      passwordDetails?.newPassword?.trim()
    ) {
      setPasswordCommonErrorMessages({
        isError: true,
        message: newCnfMatchErrMsg,
      });
      return false;
    }

    return true;
  };
  const handleUserUpdate = async () => {
    if (selectedTab === 1) {
      if (validateUserProfile()) {
        const requestPayload = {
          isActive: profileEditDetails?.isActive || true,
          userDisplayName: `${profileEditDetails?.userFirstName?.trim()} ${profileEditDetails?.userLastName?.trim()}`,
          userEmailId: profileEditDetails?.userEmailId || "",
          userFirstName: profileEditDetails?.userFirstName?.trim() || "",
          userLastName: profileEditDetails?.userLastName?.trim() || "",
          userRole: profileEditDetails?.userRole || "",
        };
        setFNameErrorMessages(defaultError);
        setLNameErrorMessages(defaultError);

        if (profileEditDetails?.userId) {
          let response = null;

          dispatch(setLoadingState(true));
          if (profileEditDetails?.userId === profileDetails?.userId) {
            response = await updateSelfUserDetails(
              profileEditDetails?.userId,
              requestPayload
            );
          } else {
            response = await updateUserDetails(
              profileEditDetails?.userId,
              requestPayload
            );
          }
          if (response) {
            getAllUserDetails(); // Refresh User List
            setOpenEditUser(false);
            setSelectedTab(1);
            setSelectedUserDetails(null);
            setPasswordDetails({ ...defaultPasswordDetails });
            // const message = response || "User details updated successfully";
            // dispatch(showError(true, message, "info"));
          }

          dispatch(setLoadingState(false));
        }
      }
    } else if (selectedTab === 2) {
      if (validateUserPassword()) {
        const key = AES_KEY;
        const iv = AES_IV;
        const passwordToEncrypt = passwordDetails?.newPassword;
        const encryptedPassword = encryptData(passwordToEncrypt, key, iv);
        console.log("encrypted Password:", encryptedPassword);
        const requestPayload = {
          encrypted: true,
          password: encryptedPassword || passwordDetails?.newPassword || "",
          userEmailId: profileEditDetails?.userEmailId || "",
        };
        setOldPasswordErrorMessages(defaultError);
        setNewPasswordErrorMessages(defaultError);
        setConfirmPasswordErrorMessages(defaultError);
        setPasswordCommonErrorMessages(defaultError);
        console.log("handleUserUpdate ===>", selectedTab, passwordDetails);
        console.log(requestPayload);

        // dispatch(setLoadingState(true));
        // const response = await updateUserPassword(requestPayload);
        // if (response) {
        //   getAllUserDetails(); // Refresh User List
        //   setOpenEditUser(false);
        //   setSelectedTab(1);
        //   setSelectedUserDetails(null);
        //   setPasswordDetails({ ...defaultPasswordDetails });
        //   dispatch(setLoadingState(true));
        // }
      }
    }
  };
  const handleChange = (event, nextView) => {
    setView(nextView);
  };
  const handleTabChange = (newTabValue) => {
    setSelectedTab(newTabValue);
  };
  const handleRoleChange = (event) => {
    const { value, name } = event.target;
    setProfileEditDetails({ ...profileEditDetails, [name]: value });
  };
  const handleProfileFieldsChangeClick = (event, fieldName) => {
    const { value } = event.target;

    setFNameErrorMessages(defaultError);
    setLNameErrorMessages(defaultError);
    setProfileEditDetails({ ...profileEditDetails, [fieldName]: value });
  };
  const handleEnableScreenshot = (event, fieldName) => {
    const { checked } = event.target;
    setProfileEditDetails({ ...profileEditDetails, [fieldName]: checked });
  };
  const handleChangePasswordClick = (event, fieldName) => {
    const { value } = event.target;

    setOldPasswordErrorMessages(defaultError);
    setNewPasswordErrorMessages(defaultError);
    setConfirmPasswordErrorMessages(defaultError);
    setPasswordCommonErrorMessages(defaultError);
    setPasswordDetails({ ...passwordDetails, [fieldName]: value });
  };
  const handleSearchClick = (event) => {
    try {
      if (searchText && searchText?.trim() !== "") {
        const filterData = rows?.filter(
          (rec) =>
            rec?.userFirstName
              ?.toLocaleLowerCase()
              ?.includes(searchText?.toLocaleLowerCase()) ||
            rec?.userLastName
              ?.toLocaleLowerCase()
              ?.includes(searchText?.toLocaleLowerCase())
        );
        setRows(filterData);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClearSearch = () => {
    if (userList && userList?.length) {
      const updatedUserList =
        userList && userList?.length
          ? [...userList].map((item, index) => {
              return {
                ...item,
                id: index + 1,
                isActive: true,
              };
            })
          : [];
      setRows(updatedUserList);
    } else {
      setRows([]);
    }
    setSearchText("");
  };
  //
  const handleCreateUserFieldsChangeClick = (event, fieldName) => {
    const { value } = event.target;
    resetCreateUserErrors();
    setCreateUserDetails({ ...createUserDetails, [fieldName]: value });
  };
  const handleCreateUserRoleChange = (event) => {
    const { value, name } = event.target;
    setCreateUserDetails({ ...createUserDetails, [name]: value });
  };
  const handleCreateUserChangePasswordClick = (event, fieldName) => {
    const { value } = event.target;
    resetCreateUserErrors();
    setCreateUserDetails({ ...createUserDetails, [fieldName]: value });
  };
  const handleCreateUser = async () => {
    try {
      console.log(createUserDetails);
      setOpenEditUser(false);
      resetCreateUserErrors();
      setUserModelType(null);
    } catch (error) {
      console.error(error);
    }
  };
  //

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className="card">
            <div className="card-body p-0">
              {/* <div className="col-md-6 col-sm-12"> */}
              <div className="text-muted" style={{ padding: 20 }}>
                <div className="col-md-12 col-sm-12">
                  <Typography>Manage User(s)</Typography>
                </div>
                <div>
                  <Row>
                    <div className="col-md-8">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ width: "40%", marginRight: "10px" }}>
                          <TextField
                            label=""
                            fullWidth
                            className="search-ai-insights"
                            style={{ height: 35 }}
                            placeholder="Search for person"
                            value={searchText}
                            onChange={(event) => {
                              const { value } = event?.target || {};
                              setSearchText(value);
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                              endAdornment: searchText ? (
                                <InputAdornment
                                  position="end"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClearSearch}
                                >
                                  <CloseIcon />
                                </InputAdornment>
                              ) : (
                                <></>
                              ),
                            }}
                            variant="outlined"
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSearchClick}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ marginRight: "5px" }}>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleEditUserClick(null, "Create")}
                          >
                            Create User
                          </button>
                        </div>
                        <ToggleButtonGroup
                          orientation="horizontal"
                          value={view}
                          exclusive
                          onChange={handleChange}
                        >
                          <ToggleButton
                            value="thumbnail"
                            aria-label="thumbnail"
                          >
                            <ViewModuleIcon />
                          </ToggleButton>
                          <ToggleButton value="list" aria-label="list">
                            <ViewListIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </Row>
                </div>
                {/* Thumbnail Form */}
                {view === "thumbnail" ? (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {rows?.length ? (
                      rows?.map((user) => {
                        return (
                          <div
                            style={{
                              height: "200px",
                              width: "210px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              margin: "10px",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Stack direction="row" spacing={1}>
                                {user?.userId !== profileDetails?.userId ? (
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => handleDeleteUserClick(user)}
                                  >
                                    <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  onClick={() =>
                                    handleEditUserClick(user, "Edit")
                                  }
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </Stack>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Avatar sx={{ height: 60, width: 60 }}>
                                <PersonIcon />
                              </Avatar>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                              }}
                            >
                              {user?.userDisplayName?.length > 25 ? (
                                <Tooltip title={user?.userDisplayName}>
                                  <Typography variant="body1">
                                    {`${user?.userDisplayName?.substring(
                                      0,
                                      22
                                    )}...` || ""}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography variant="body1">
                                  {user?.userDisplayName || ""}
                                </Typography>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {/* {user?.userEmailId?.length > 25 ? (
                                <Tooltip title={user?.userEmailId}>
                                  <Typography variant="body2">
                                    {`${user?.userEmailId?.substring(
                                      0,
                                      22
                                    )}...` || ""}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography variant="body2">
                                  {user?.userEmailId || ""}
                                </Typography>
                              )} */}
                              <Typography variant="body2">
                                {renderUserRole({
                                  row: {
                                    userRole: user?.userRole,
                                  },
                                })}
                              </Typography>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {/* TABLE Form */}
                {view === "list" ? (
                  <div
                    style={{
                      background: "#ffffff",
                      overflow: "auto",
                      height: "445px",
                      width: "100%",
                      flexGrow: 1,
                    }}
                  >
                    {/* Render the StyledDataGrid component with the specified rows, columns, and props */}
                    <StyledDataGrid
                      initialState={
                        {
                          // columns: {
                          //   columnVisibilityModel: {
                          //     // Hide columns status and traderName, the other columns will remain visible
                          //     warning: false,
                          //     passed: false,
                          //     failed: false,
                          //     undefined: false,
                          //   },
                          // },
                        }
                      }
                      rows={rows}
                      columns={columns}
                      rowHeight={rowHeight}
                      columnHeaderHeight={headerHeight}
                      onCellClick={handleCellClick}
                      hideFooter
                      hideFooterPagination
                      hideFooterSelectedRowCount
                      slots={{ toolbar: CustomToolbar }}
                      onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                      }}
                      rowSelectionModel={rowSelectionModel}
                      componentsProps={{
                        // Set custom styles for the datafg grid components

                        basePopper: {
                          // sx: {
                          //   backgroundColor: "red",
                          //   "& .MuiPaper-root": { backgroundColor: "green",fontSize:"2px" },
                          //   "&.MuiDataGrid-menu .MuiPaper-root": { backgroundColor: "blue",fontSize:"10px" }
                          // }
                          sx: {
                            "& .MuiTypography-root": { fontSize: "12px" },
                            // '& .MuiDataGrid-filterForm': {backgroundColor: 'lightblue'},
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </Col>
      </Row>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, you want to delete user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDeleteUser} autoFocus variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit User Details */}
      <Dialog
        open={openEditUser}
        onClose={handleEditUserClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{`${
          userModelType === "Create" ? "Create" : "Edit"
        } User`}</DialogTitle>
        {userModelType === "Create" ? (
          <DialogContent>
            <CreateUser
              roleDetails={roleDetails}
              createUserDetails={createUserDetails}
              handleCreateUserFieldsChangeClick={
                handleCreateUserFieldsChangeClick
              }
              fNameCreateErrorMessages={fNameCreateErrorMessages}
              lNameCreateErrorMessages={lNameCreateErrorMessages}
              emailCreateErrorMessages={emailCreateErrorMessages}
              handleCreateUserRoleChange={handleCreateUserRoleChange}
              handleCreateUserChangePasswordClick={
                handleCreateUserChangePasswordClick
              }
              newPasswordCreateErrorMessages={newPasswordCreateErrorMessages}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={selectedTab === 1 ? "active" : ""}
                  onClick={() => handleTabChange(1)}
                >
                  Profile Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={selectedTab === 2 ? "active" : ""}
                  onClick={() => handleTabChange(2)}
                >
                  Change Password
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={selectedTab}>
              {selectedTab === 1 ? (
                <TabPane tabId={selectedTab}>
                  <Row>
                    <EditUserDetails
                      profileDetails={profileDetails}
                      roleDetails={roleDetails}
                      editUserDetails={selectedUserDetails}
                      handleRoleChange={handleRoleChange}
                      profileEditDetails={profileEditDetails}
                      setProfileEditDetails={setProfileEditDetails}
                      handleProfileFieldsChangeClick={
                        handleProfileFieldsChangeClick
                      }
                      handleEnableScreenshot={handleEnableScreenshot}
                      fNameErrorMessages={fNameErrorMessages}
                      lNameErrorMessages={lNameErrorMessages}
                    />
                  </Row>
                </TabPane>
              ) : (
                <></>
              )}
              {selectedTab === 2 ? (
                <TabPane tabId={selectedTab}>
                  <Row>
                    <ChangePassword
                      editUserDetails={selectedUserDetails}
                      handleChangePasswordClick={handleChangePasswordClick}
                      passwordDetails={passwordDetails}
                      oldPasswordErrorMessages={oldPasswordErrorMessages}
                      newPasswordErrorMessages={newPasswordErrorMessages}
                      confirmPasswordErrorMessages={
                        confirmPasswordErrorMessages
                      }
                      passwordCommonErrorMessages={passwordCommonErrorMessages}
                    />
                  </Row>
                </TabPane>
              ) : (
                <></>
              )}
            </TabContent>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleEditUserClose}>Close</Button>
          <Button
            onClick={
              userModelType === "Create" ? handleCreateUser : handleUserUpdate
            }
          >
            {userModelType === "Create" ? "Save" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
