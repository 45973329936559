import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Container } from "reactstrap";

import { menuSelectionChange } from "../../store/actions";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import { ManageProfile } from "./ManageProfile";

export const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    handleMenuClick();
  }, []);

  const handleMenuClick = () => {
    dispatch(menuSelectionChange(5));
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: "30px" }}>
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Manage Profile"
            style={{ backgroundColor: "#f8f9fa" }}
          />
          <React.Fragment>
            {/* <WBFilters /> */}
            <Row>
              <ManageProfile />
            </Row>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};
