import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export const StackedChart = (props) => {
  const {
    businessProcessDetails,
    isBar,
    colors,
    isStacked = false,
    height,
  } = props;

  const chartDetails = {
    series: [],
    options: {
      chart: {
        height: height,
        type: "bar",
      },
      plotOptions: {},
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      colors: colors,
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: [],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
        labels: {
          show: true,
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
          },
          rotate: -90,
          formatter: function (val) {
            return val;
          },
        },
      },
      responsive: [
        {
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };

  const [chartData, setChartData] = useState(chartDetails);

  useEffect(() => {
    if (businessProcessDetails?.length) {
      const seriesData = [];
      const stackedSeriesData = [];
      const xAxisCategories = [];

      businessProcessDetails.forEach((rec) => {
        if (rec?.processName !== "Total") {
          if (isStacked) {
            stackedSeriesData.push({
              name: rec?.processName || "",
              data: rec?.data || [],
            });
          } else {
            seriesData.push(rec?.Count || 0);
            xAxisCategories.push(rec?.processName || "");
          }
        }
      });
      const series = isStacked
        ? stackedSeriesData
        : [
            {
              name: "Inflation",
              data: seriesData,
            },
          ];
      const categories = xAxisCategories;
      const forStacked = isStacked
        ? {
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          }
        : {};
      setChartData({
        ...chartData,
        series: series,
        options: {
          ...chartData.options,
          chart: {
            ...chartData.options.chart,
            ...forStacked,
          },
          fill: {
            opacity: 1,
          },
          dataLabels: isStacked
            ? {
                enabled: true,
                formatter: function (val) {
                  return val;
                },
                offsetY: 2,
              }
            : { ...chartData.options.dataLabels },
          plotOptions: {
            ...chartData.options.plotOptions,
            bar: isBar
              ? {
                  borderRadius: 4,
                  horizontal: true,
                  dataLabels: {
                    position: "top", // top, center, bottom
                  },
                }
              : isStacked
              ? {
                  horizontal: false,
                  borderRadius: 5,
                  dataLabels: {
                    total: {
                      enabled: true,
                      style: {
                        fontSize: "13px",
                        fontWeight: 900,
                      },
                    },
                  },
                }
              : {
                  horizontal: false,
                  dataLabels: {
                    position: "top", // top, center, bottom
                  },
                },
          },
          xaxis: {
            ...chartData.options.xaxis,
            categories: isStacked
              ? [
                  "October",
                  "November",
                  "December",
                  "January",
                  "February",
                  "March",
                ]
              : categories,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessProcessDetails, isBar, isStacked]);

  console.log(chartData.options, chartData.series);
  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={height}
      />
    </div>
  );
};
