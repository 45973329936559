// Import required modules and components
import React from "react";
import ReactApexChart from "react-apexcharts";

// Define the color array
const colorArr = ['#11c46e', '#ff3d60', '#f1b44c', '#9da1a6'];

// Functional component DashRadialChart
const DashRadialChart = ({ totalCountValue, passedCount, failedCount, warningCount, undefinedCount }) => {
  // Define the data for the RadialChart
  const RadialChartData = {
    series: [passedCount, failedCount, warningCount, undefinedCount],
    options: {
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              showAlways: true,
              label: "# Scenarios",
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of a custom formatter function
                return "";
              },
            },
          },
        },
      },

      labels: ["Passed", "Failed", "Warning", "Undefined"],
      colors: ['#11c46e', '#ff3d60', '#f1b44c', "#9da1a6"],
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let total = 0;
          for (let x of series) {
            total += x;
          }
          let selected = series[seriesIndex];

          // Custom tooltip to display percentage of each series
          return `
            <div style="color: white; font-size: 10px; padding: 5px; background-color: ${colorArr[seriesIndex]};">
              ${w.config.labels[seriesIndex]}: ${(selected / total * 100).toFixed(0).toLocaleString()}%
            </div>
          `;
        },
      },

    },
    legend: {
      show: false,
      position: "bottom",
    },
  };

  // Return JSX content
  return (
    <React.Fragment>
      <ReactApexChart
        options={RadialChartData.options}
        series={RadialChartData.series}
        type="radialBar"
        height="280"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

// Export the component
export default DashRadialChart;
