import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export const DonutChart = (props) => {
  const { businessProcessDetails, height } = props;
  const chartDetails = {
    series: [],
    options: {
      chart: {
        height: height,
        type: "donut",
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
      },
      responsive: [
        {
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const [chartData, setChartData] = useState(chartDetails);

  useEffect(() => {
    if (businessProcessDetails?.length) {
      const seriesData = [];
      const xAxisCategories = [];

      businessProcessDetails.forEach((rec) => {
        if (rec?.processName !== "Total") {
          seriesData.push(rec?.Count || 0);
          xAxisCategories.push(rec?.processName || "");
        }
      });
      const series = [...seriesData];
      const categories = xAxisCategories;
      setChartData({
        ...chartData,
        series: series,
        options: {
          ...chartData.options,
          labels: [...categories],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessProcessDetails]);

  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height={height}
      />
    </div>
  );
};
