import React from "react";
import ReactApexChart from "react-apexcharts";
const colorArr = ["#11c46e", "#ff3d60", "#f1b44c", "#9da1a6"]; // Define the color array

const Radial4Cards = ({ Scores }) => {
  // Data and options for the donut chart
  const chartData = {
    series: [
      Scores.passedCount,
      Scores.failedCount,
      Scores.warningCount,
      Scores.undefinedCount,
    ],
    options: {
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let total = 0;
          for (let x of series) {
            total += x;
          }
          let selected = series[seriesIndex];

          return `
            <div style="color :white;font-size:10px;padding:5px; background-color: ${
              colorArr[seriesIndex]
            };">
              ${w.config.labels[seriesIndex]}: ${((selected / total) * 100)
            .toFixed(0)
            .toLocaleString()}%
            </div>
          `;
        },
      },

      labels: ["Passed", "Failed", "Warning", "Undefined"],
      colors: ["#11c46e", "#ff3d60", "#f1b44c", "#9da1a6"],
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "12px",
        offsetX: 0,
        offsetY: -10,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,

          donut: {
            size: "72%",
            labels: {
              show: false,
              name: {
                offsetY: 6,
              },
              total: {
                show: true,
                showAlways: true,
                label: "256",
                fontSize: "14px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                formatter: (val) => "", // Set the total value to a constant value
              },
            },
          },
        },
      },
      stroke: {
        colors: ["transparent"], // Set stroke color to transparent
      },
      responsive: [
        {
          breakpoint: 200,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      {/* Render the donut chart */}
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height="120"
        className="apex-charts"
      />
    </div>
  );
};

export default Radial4Cards;
