import React, { useEffect } from "react";
import WBFilters from "./WbFiltersArea";
import WBworkArea from "./WBWorkArea";
import { Row, Container } from "reactstrap";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import { menuSelectionChange } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const WorkBoard = () => {
  const dispatch = useDispatch();

  const faAreas = useSelector((state) => state.dashboardData.faAreas || []);

  useEffect(() => {
    document.title = "WorkBoard | Ilumn";
  }, [faAreas]); // Re-run the effect whenever faAreas changes

  useEffect(() => {
    dispatch(menuSelectionChange(3));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: "30px" }}>
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Work Board"
            style={{ backgroundColor: "#f8f9fa" }}
          />
          {faAreas.length === 0 ? (
            <div>
              No Details found on Current Tenant.Please Change Tenant Filters on
              Dashboard Page
            </div>
          ) : (
            <React.Fragment>
              <WBFilters />
              <Row>
                <WBworkArea />
              </Row>
            </React.Fragment>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WorkBoard;
