import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionData } from "./AccordionData";
import newlyAddedData from "./NewlyAddedData.json";
import missMatchData from "./MissMatchData.json";
import consolidatedViewData from "./ConsolidatedViewData.json";

export const ComparisonDetails = ({
  RenderComparisonTypeDetails,
  chartName,
  selectedBP,
  BusinessProcessesData,
  handleBusinessProcessChange,
  comparisonTabsData,
  setRows,
  setIsViewClicked,
}) => {
  const [selectedTypeData, setSelectedTypeData] = useState();
  const [expanded, setExpanded] = useState(1);

  useEffect(() => {
    setSelectedTypeData([BusinessProcessesData[selectedBP]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBP]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleViewClick = (type, compType, processName) => {
    try {
      // console.log(type, compType); // 1 - first, 2 - second
      // console.log(compType); // 1 - MissMatch, 2 - Newly Added, 3 - Consolidated View
      if (processName === "Hire (Default Definition)") {
        let rowsDetails = [];
        if (type === 1 && compType === 1) {
          rowsDetails = [...missMatchData.S_Production_Hire];
        } else if (type === 2 && compType === 1) {
          rowsDetails = [...missMatchData.D_Sandbox_Hire];
        } else if (type === 1 && compType === 2) {
          rowsDetails = [...newlyAddedData.S_Production_Hire];
        } else if (type === 2 && compType === 2) {
          rowsDetails = [...newlyAddedData.D_Sandbox_Hire];
        } else if (type === 1 && compType === 3) {
          rowsDetails = [...consolidatedViewData.S_Production_Hire]; // colorApplied can be Array or String with "All"
        } else if (type === 2 && compType === 3) {
          rowsDetails = [...consolidatedViewData.D_Sandbox_Hire];
        }
        // console.log(rowsDetails);
        rowsDetails = [...rowsDetails].map((item, index) => {
          return { ...item, id: index };
        });
        setRows(rowsDetails);
        setIsViewClicked(true);
      } else {
        setIsViewClicked(true);
        setRows([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDownloadClick = () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Row
        style={{
          background: "#f3f3f4",
          paddingTop: "0px",
          paddingRight: "0px",
        }}
      >
        <Col xl={5} style={{ paddingRight: "0px" }}>
          <Card className="mb-1">
            <CardBody className="card-body-dash">
              <Box className="d-flex align-items-center">
                <Box className="flex-grow-1">
                  <Typography className="card-title">
                    {chartName || "Chart"}
                  </Typography>
                </Box>
              </Box>
              <Box style={{ padding: 20, height: "462px" }}>
                {/* RenderSelected Chart */}
                {RenderComparisonTypeDetails}
              </Box>
            </CardBody>
          </Card>
        </Col>
        <Col xl={7} style={{ paddingRight: "0px" }}>
          <Card className="mb-1">
            <CardBody>
              {/* Need to render dropdown and accordian */}
              <Row className="mb-2 row">
                <Col className="col-3">
                  <label className="small-select">Business Process</label>
                </Col>
                <Col className="col-9">
                  <select
                    className="form-select small-select"
                    id="inlineFormSelectPref"
                    value={selectedBP}
                    onChange={handleBusinessProcessChange}
                  >
                    {BusinessProcessesData && BusinessProcessesData?.length ? (
                      BusinessProcessesData.map((rec, indexVal) => {
                        const headerName = `${rec?.processName || ""}`;
                        return (
                          <option key={`${indexVal}_bp_list`} value={indexVal}>
                            {`${headerName || ""}`}
                          </option>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </select>
                </Col>
              </Row>
              <Row className="mb-2 row">
                <Col>
                  <Box style={{ overflow: "auto", height: "416px" }}>
                    {selectedTypeData && selectedTypeData?.length
                      ? selectedTypeData.map((rec, indexVal) => {
                          const expandIndex = indexVal + 1;
                          const headerName = `${
                            rec?.processName || ""
                          }-Business Process`;
                          const { id, fName, sName } = comparisonTabsData || {};
                          return (
                            <Accordion
                              expanded={expanded === expandIndex}
                              onChange={handleChange(expandIndex)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                {headerName?.length > 150 ? (
                                  <Tooltip arrow title={`${headerName || ""}`}>
                                    <Typography sx={{ flexShrink: 0 }}>
                                      {`${
                                        headerName
                                          ? headerName.substring(0, 150)
                                          : ""
                                      }...`}
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography sx={{ flexShrink: 0 }}>
                                    {`${headerName || ""}`}
                                  </Typography>
                                )}
                              </AccordionSummary>
                              <AccordionDetails>
                                <AccordionData
                                  type={1}
                                  title={`${fName} Vs ${sName}`}
                                  handleViewClick={(type, compType) =>
                                    handleViewClick(
                                      type,
                                      compType,
                                      rec?.processName || ""
                                    )
                                  }
                                  handleDownloadClick={handleDownloadClick}
                                />
                                <AccordionData
                                  type={2}
                                  title={`${sName} Vs ${fName}`}
                                  handleViewClick={(type, compType) =>
                                    handleViewClick(
                                      type,
                                      compType,
                                      rec?.processName || ""
                                    )
                                  }
                                  handleDownloadClick={handleDownloadClick}
                                />
                              </AccordionDetails>
                            </Accordion>
                          );
                        })
                      : null}
                  </Box>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ComparisonDetails;
