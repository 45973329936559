import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { StyledDataGrid } from "../generalFunctions";
import {
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import timezoneData from "./timezones.json";
import { CreateSchedule } from "./CreateSchedule";
import moment from "moment";
import {
  createSchedule,
  deleteSchedule,
  updateSchedule,
} from "../../store/dashboard/orchestractor";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  monthNames,
  recurrenceConstant,
  weekNames,
} from "../../config/constant";
import {
  generateTimeList,
  getJobTypeName,
  getMonthDay,
  getMonthDayNumber,
  getWeekDay,
  getWeekDayNumber,
} from "../../config/util";
import { useProfile } from "../../Hooks/UserHooks";
import { ExcelExportIcon } from "../../components/ExcelExportIcon";

const smallSelect = {
  fontSize: "12px",
  lineHeight: "15px",
  width: "100%",
  borderRadius: "2px",
  padding: "3px",
  marginBottom: "3px",
};

const getStatus = (params) => {
  const jobStatus = params.row.jobStatus;
  // EXPIRED ACTIVE & NOTACTIVE
  return jobStatus === "ACTIVE" ? "Active" : "In-active";
};
const getJobType = (params) => {
  const jobType = params.row.jobType;
  // DAILY WEEKLY & MONTHLY
  const updatedValue = jobType
    ? jobType.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
    : "";
  return updatedValue ? `${updatedValue} Frequency` : "";
};

export const Schedule = (props) => {
  const { testSuitDetails, scheduleList, getScheduleList } = props;

  const { userProfile } = useProfile();

  const headerHeight = 28;
  const rowHeight = 26;
  const columns = [
    {
      field: "nextRun",
      headerName: "Next Scheduled Date Time",
      width: 200,
      type: "date",
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date.toLocaleDateString("en-UK", options);
        return formattedDateTime;
      },
    },
    {
      field: "jobName",
      headerName: "Scheduled Process",
      width: 200,
      flex: 1,
    },
    {
      field: "jobType",
      renderCell: getJobType,
      headerName: "Run Frequency",
      width: 200,
    },
    {
      field: "userEmail",
      headerName: "Owned by User",
      width: 200,
    },
    {
      field: "startDate",
      headerName: "Recurrence Start Date",
      width: 190,
      type: "date",
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date.toLocaleDateString("en-UK", options);
        return formattedDateTime;
      },
    },
    {
      field: "endDate",
      headerName: "Recurrence End Date",
      width: 190,
      type: "date",
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date.toLocaleDateString("en-UK", options);
        return formattedDateTime;
      },
    },
    {
      field: "jobStatus",
      renderCell: getStatus,
      headerName: "Status",
      width: 100,
      maxWidth: 200,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => {
        return userProfile?.user?.viewerRole
          ? []
          : [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={EditScheduler(params)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={deleteUser(params.id)}
                showInMenu
              />,
            ];
      },
    },
  ];

  const defaultFormDetails = {
    recurrenceType: 1,
    recurrenceTypeValue: 0,
    monthlyRecurrenceType: 1,
    monthlyRecurrenceTypeValue: 0,
    testSuiteTimezone: 38,
    testSuiteTimeSelection: "06:00",
    weeklyRecurrenceValue: 0,
  };
  // eslint-disable-next-line no-unused-vars
  const [selectedSuiteSelection, setSelectedSuiteSelection] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({ ...defaultFormDetails });
  const [daysOfWeek, setDaysOfWeek] = React.useState([]);
  const [monthlyMonthDetails, setMonthlyMonthDetails] = useState([]);
  const [monthlyWeekDetails, setMonthlyWeekDetails] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rows, setRows] = React.useState([]);

  // Example usage
  const timeList = generateTimeList();
  //
  useEffect(() => {
    const rowsData = [...scheduleList].map((item) => {
      return { ...item, id: item?.jobId };
    });
    setRows([...rowsData]);
  }, [scheduleList]);

  const handleScheduleEdit = (selectedRow) => {
    try {
      const selectedRecord = selectedRow?.row || {};

      let defaultData = {};
      if (selectedRecord?.jobType === "DAILY") {
        defaultData = {
          jobId: selectedRecord?.jobId || 0,
          testSuite: selectedRecord?.suiteId || 0,
          testSuiteName: selectedRecord?.jobName || "",
          recurrenceType: selectedRecord?.jobConfiguration?.dayFrequency?.length
            ? 2
            : 1,
          recurrenceTypeValue: selectedRecord?.jobConfiguration?.dayFrequency
            ?.length
            ? selectedRecord?.jobConfiguration?.dayFrequency[0]
            : 0,
          monthlyRecurrenceType: 1,
          monthlyRecurrenceTypeValue: 0,
          testSuiteTimezone: 39,
          testSuiteTimeSelection: selectedRecord?.startTime || "06:00",
          weeklyRecurrenceValue: 0,
          testSuiteFrequency: 1, // Run Frequency
        };
        setStartDate(new Date(startDate || new Date()));
        setEndDate(new Date(endDate || new Date()));
        setFormDetails({ ...formDetails, ...defaultData });
        setOpen(true);
      } else if (selectedRecord?.jobType === "WEEKLY") {
        defaultData = {
          jobId: selectedRecord?.jobId || 0,
          testSuite: selectedRecord?.suiteId || 0,
          testSuiteName: selectedRecord?.jobName || "",
          recurrenceType: 1,
          recurrenceTypeValue: 0,
          monthlyRecurrenceType: 1,
          monthlyRecurrenceTypeValue: 0,
          testSuiteTimezone: 39,
          testSuiteTimeSelection: selectedRecord?.startTime || "06:00",
          weeklyRecurrenceValue:
            selectedRecord?.jobConfiguration?.weekFrequency &&
            selectedRecord?.jobConfiguration?.weekFrequency?.length
              ? selectedRecord?.jobConfiguration?.weekFrequency[0]
              : 0,
          testSuiteFrequency: 2, // Run Frequency
        };
        const dayWeek = selectedRecord?.jobConfiguration?.daysOfWeek || [];
        const updatedWeekName =
          dayWeek && dayWeek?.length
            ? dayWeek?.map((item) => {
                return getWeekDay(item);
              })
            : [];
        setDaysOfWeek(updatedWeekName);
        setStartDate(new Date(startDate || new Date()));
        setEndDate(new Date(endDate || new Date()));
        setFormDetails({ ...formDetails, ...defaultData });
        setOpen(true);
      } else if (selectedRecord?.jobType === "MONTHLY") {
        const monthsDetails = selectedRecord?.jobConfiguration?.months || [];
        const daysOfMonthsDetails =
          selectedRecord?.jobConfiguration?.daysOfMonth || [];
        const dayWeek = selectedRecord?.jobConfiguration?.daysOfWeek || [];

        defaultData = {
          jobId: selectedRecord?.jobId || 0,
          testSuite: selectedRecord?.suiteId || 0,
          testSuiteName: selectedRecord?.jobName || "",
          recurrenceType: 1,
          recurrenceTypeValue: 0,
          monthlyRecurrenceType: 1,
          monthlyRecurrenceTypeValue: daysOfMonthsDetails[0] || 0,
          testSuiteTimezone: 39,
          testSuiteTimeSelection: selectedRecord?.startTime || "06:00",
          weeklyRecurrenceValue: 0,
          testSuiteFrequency: 3, // Run Frequency
        };
        const updatedMonthName =
          monthsDetails && monthsDetails?.length
            ? monthsDetails?.map((item) => {
                return getMonthDay(item);
              })
            : [];
        const updatedWeekName =
          dayWeek && dayWeek?.length
            ? dayWeek?.map((item) => {
                return getWeekDay(item);
              })
            : [];
        setMonthlyWeekDetails(updatedWeekName);
        setMonthlyMonthDetails(updatedMonthName);
        setStartDate(new Date(startDate || new Date()));
        setEndDate(new Date(endDate || new Date()));
        setFormDetails({ ...formDetails, ...defaultData });
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteAPICall = async (id) => {
    try {
      const response = await deleteSchedule(id);
      if (response) {
        getScheduleList();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const EditScheduler = useCallback(
    (selectedRecord) => () => {
      setTimeout(() => {
        // const selectedRecord = rows.find((row) => row.id === id);
        handleScheduleEdit(selectedRecord);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const deleteUser = useCallback(
    (id) => () => {
      setTimeout(() => {
        handleDeleteAPICall(id);
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = () => {
    if (testSuitDetails?.suiteScriptListByFA?.length) {
      getDefaultTestSuitId(2);
      setStartDate(null);
      setEndDate(null);
      setDaysOfWeek([]);
      setMonthlyWeekDetails([]);
      setMonthlyMonthDetails([]);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (testSuitDetails?.suiteScriptListByFA?.length) {
      getDefaultTestSuitId(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSuitDetails]);

  const getDefaultTestSuitId = (type) => {
    const defaultSelection =
      testSuitDetails?.suiteScriptListByFA[0]?.modules[0]?.suiteScripts;
    const defaultSuiteId = defaultSelection?.length
      ? defaultSelection[0]?.suiteId
      : 0;
    const defaultSuiteName = defaultSelection?.length
      ? defaultSelection[0]?.suiteName
      : 0;
    const updatedFormValue =
      type === 1
        ? {
            ...formDetails,
            testSuite: defaultSuiteId ? parseInt(defaultSuiteId) : 0,
            testSuiteName: defaultSuiteName || "",
          }
        : {
            ...defaultFormDetails,
            testSuite: defaultSuiteId ? parseInt(defaultSuiteId) : 0,
            testSuiteName: defaultSuiteName || "",
          };
    setFormDetails(updatedFormValue);
  };
  const resetForm = (formValues) => {
    const defaultSelection =
      testSuitDetails?.suiteScriptListByFA[0]?.modules[0].suiteScripts;
    const defaultSuiteId = defaultSelection?.length
      ? defaultSelection[0]?.suiteId
      : 0;
    const defaultSuiteName = defaultSelection?.length
      ? defaultSelection[0]?.suiteName
      : 0;
    setFormDetails({
      testSuite:
        formDetails?.testSuite || defaultSuiteId ? parseInt(defaultSuiteId) : 0,
      testSuiteName: formDetails?.testSuiteName || defaultSuiteName || "",
      ...defaultFormDetails,
      ...formValues,
    });
    setDaysOfWeek([]);
    setStartDate(null);
    setEndDate(null);
  };
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.jobId;
    setSelectedSuiteSelection(suiteSelection);
    // setSuiteSelection(suiteSelection); // Call setSuiteSelection here when needed
  };
  const getFilteredRows = ({ apiRef }) => {
    gridExpandedSortedRowIdsSelector(apiRef);
  };
  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    // Function to handle export button click to export data as CSV

    const handleExport = (options) => {
      // Set a custom file name for the exported CSV file
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      options.fileName = `suite_instance_listing_${timestamp}`;

      apiRef.current.exportDataAsCsv(options);
    };
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
      >
        <div>
          <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>
            Suite Run Instance Listing
          </h6>
        </div>

        <ExcelExportIcon
          handleExport={handleExport}
          getFilteredRows={getFilteredRows}
        />
      </div>
    );
  };
  const getTimeZoneData = (abbrValue) => {
    let timeZoneDetails = null;
    timezoneData?.forEach((item) => {
      if (item?.id === abbrValue) {
        timeZoneDetails = item;
      }
    });
    return timeZoneDetails?.utc && timeZoneDetails?.utc?.length
      ? timeZoneDetails?.utc[0]
      : "Europe/Isle_of_Man";
  };
  const getTestSuiteFaId = (value = 0) => {
    const data = testSuitDetails?.suiteScriptListByFA?.length
      ? testSuitDetails?.suiteScriptListByFA
      : [];
    let faId = 0;
    data.forEach((item) => {
      item?.modules?.length &&
        item?.modules[0].suiteScripts &&
        item?.modules[0].suiteScripts.forEach((suiteScript) => {
          if (suiteScript?.suiteId === value) {
            faId = item?.faId || 0;
          }
        });
    });
    return faId;
  };
  const getTestSuiteName = (value = 0) => {
    const data = testSuitDetails?.suiteScriptListByFA?.length
      ? testSuitDetails?.suiteScriptListByFA
      : [];
    let testSuiteName = "";
    data.forEach((item) => {
      item?.modules?.length &&
        item?.modules[0].suiteScripts &&
        item?.modules[0].suiteScripts.forEach((suiteScript) => {
          if (suiteScript?.suiteId === value) {
            testSuiteName = suiteScript?.suiteName || "";
          }
        });
    });
    return testSuiteName || "";
  };
  const handleChangeTestSuite = (event) => {
    const { name, value } = event.target;
    const idVal = value ? parseInt(value) : 0;
    const testSuiteName = getTestSuiteName(idVal);
    setFormDetails({
      ...formDetails,
      [name]: idVal,
      testSuiteName: testSuiteName || "",
    });
  };
  const handleChangeTestSuiteFrequency = (event) => {
    const { name, value } = event.target;

    resetForm({ [name]: value });
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setFormDetails({
      ...formDetails,
      recurrenceType: value ? parseInt(value) : 1,
      recurrenceTypeValue: 0,
    });
    setMonthlyMonthDetails([]);
  };
  const handleMonthlyChange = (event) => {
    const { value } = event.target;
    setFormDetails({
      ...formDetails,
      monthlyRecurrenceType: value ? parseInt(value) : 1,
      monthlyRecurrenceTypeValue: 0,
    });
    setDaysOfWeek([]);
    setMonthlyWeekDetails([]);
  };
  const handleWeekChange = (event) => {
    const {
      target: { value },
    } = event;
    setDaysOfWeek(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMonthlyMonthChange = (event) => {
    const { value } = event?.target || {};
    setMonthlyMonthDetails(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMonthlyWeekChange = (event) => {
    const { value } = event?.target || {};
    setMonthlyWeekDetails(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeTimeZone = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value ? parseInt(value) : 38 });
  };
  const handleChangeTimeSelection = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value });
  };
  const handleRecurrenceTypeValueChange = (event) => {
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value ? parseInt(value) : 0 });
  };
  const handleWeeklyRecurrenceValueChange = (event) => {
    // weeklyRecurrenceValue
    const { value, name } = event?.target || {};
    setFormDetails({ ...formDetails, [name]: value ? parseInt(value) : 0 });
  };
  const checkCreateScheduleFormValidation = () => {
    if (formDetails?.testSuiteFrequency) {
      if (!startDate) {
        return false;
      }
      if (!endDate) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  };
  const handleCreateSchedule = async (event) => {
    try {
      if (checkCreateScheduleFormValidation()) {
        const formattedStartDate = startDate
          ? moment(startDate).format("YYYY-MM-DD")
          : null;
        const formattedEndDate = endDate
          ? moment(endDate).format("YYYY-MM-DD")
          : null;
        const storedAuthUser = localStorage.getItem("authUser");
        const obj = storedAuthUser ? JSON.parse(storedAuthUser) : null;
        if (obj?.user?.userId) {
          // formDetails?.testSuiteFrequency // 1 === Daily, 2 === Weekly & 3 === Monthly
          let requestPayload = {
            jobId: formDetails?.jobId || 0, // For Update
            suiteId: formDetails?.testSuite || 0,
            jobName: formDetails?.testSuiteName || "",
            jobDescription: formDetails?.testSuiteName || "",
            jobType: getJobTypeName(formDetails?.testSuiteFrequency) || "",
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            startTime: formDetails?.testSuiteTimeSelection || "",
            userId: obj?.user?.userId,
            faId: getTestSuiteFaId(formDetails?.testSuite || 0),
            jobStatus: "ACTIVE",
            timezone: getTimeZoneData(formDetails?.testSuiteTimezone),
          };
          if (formDetails?.testSuiteFrequency === 1) {
            let jobConfiguration = {};
            if (formDetails?.recurrenceType === 2) {
              jobConfiguration = {
                dayFrequency: [formDetails?.recurrenceTypeValue || 0],
              };
            }
            requestPayload = {
              ...requestPayload,
              jobConfiguration: {
                ...jobConfiguration,
              },
            };
          } else if (
            formDetails?.testSuiteFrequency === 2 ||
            formDetails?.testSuiteFrequency === 3
          ) {
            let jobConfiguration = {};
            if (formDetails?.testSuiteFrequency === 2) {
              jobConfiguration = {
                weekFrequency: [formDetails?.weeklyRecurrenceValue || 0],
                daysOfWeek:
                  daysOfWeek && daysOfWeek?.length
                    ? daysOfWeek?.map((item) => {
                        return getWeekDayNumber(item);
                      })
                    : [0],
              };
            } else if (formDetails?.testSuiteFrequency === 3) {
              if (formDetails?.recurrenceType === 1) {
                // delete jobConfiguration.months;
              } else if (formDetails?.recurrenceType === 2) {
                jobConfiguration = {
                  ...jobConfiguration,
                  months:
                    monthlyMonthDetails && monthlyMonthDetails?.length
                      ? monthlyMonthDetails?.map((item) => {
                          return getMonthDayNumber(item);
                        })
                      : [0],
                };
              }
              if (formDetails?.monthlyRecurrenceType === 1) {
                jobConfiguration = {
                  ...jobConfiguration,
                  daysOfMonth: [formDetails?.monthlyRecurrenceTypeValue || 0],
                };
              } else if (formDetails?.monthlyRecurrenceType === 2) {
                jobConfiguration = {
                  ...jobConfiguration,
                  daysOfWeek:
                    monthlyWeekDetails && monthlyWeekDetails?.length
                      ? monthlyWeekDetails?.map((item) => {
                          return getWeekDayNumber(item);
                        })
                      : [0],
                };
              }
            }
            requestPayload = {
              ...requestPayload,
              jobConfiguration: {
                ...jobConfiguration,
              },
            };
          }

          let response = null;
          if (requestPayload?.jobId) {
            response = await updateSchedule(
              requestPayload,
              requestPayload?.jobId
            );
          } else {
            delete requestPayload.jobId;
            response = await createSchedule(requestPayload);
          }
          if (response) {
            handleClose();
            getScheduleList();
          }
        }
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="page-title-right ml-auto"
                  style={{ display: "flex" }}
                >
                  <div style={{ width: "250px" }}>Scheduled Jobs</div>
                  <button
                    style={smallSelect}
                    disabled={userProfile?.user?.viewerRole}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    + Schedule
                  </button>
                </div>
              </div>
            </CardTitle>

            <div
              style={{
                background: "#ffffff",
                overflow: "auto",
                height: "445px",
                width: "100%",
                flexGrow: 1,
              }}
            >
              {/* Render the StyledDataGrid component with the specified rows, columns, and props */}
              <StyledDataGrid
                initialState={{}}
                rows={rows}
                columns={columns}
                rowHeight={rowHeight}
                columnHeaderHeight={headerHeight}
                onCellClick={handleCellClick}
                hideFooter
                hideFooterPagination
                hideFooterSelectedRowCount
                slots={{ toolbar: CustomToolbar }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                componentsProps={{
                  // Set custom styles for the datafg grid components

                  basePopper: {
                    sx: {
                      "& .MuiTypography-root": { fontSize: "12px" },
                    },
                  },
                }}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
      {open ? (
        <CreateSchedule
          open={open}
          handleClose={handleClose}
          formDetails={formDetails}
          handleChangeTestSuite={handleChangeTestSuite}
          testSuitDetails={testSuitDetails}
          handleChangeTestSuiteFrequency={handleChangeTestSuiteFrequency}
          recurrenceConstant={recurrenceConstant}
          handleChange={handleChange}
          daysOfWeek={daysOfWeek}
          handleWeekChange={handleWeekChange}
          names={weekNames}
          timeList={timeList}
          timezoneData={timezoneData}
          callingType={"TestManager"}
          handleMonthlyChange={handleMonthlyChange}
          monthlyMonthDetails={monthlyMonthDetails}
          handleMonthlyMonthChange={handleMonthlyMonthChange}
          monthlyWeekDetails={monthlyWeekDetails}
          handleMonthlyWeekChange={handleMonthlyWeekChange}
          monthNames={monthNames}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleChangeTimeZone={handleChangeTimeZone}
          handleChangeTimeSelection={handleChangeTimeSelection}
          handleRecurrenceTypeValueChange={handleRecurrenceTypeValueChange}
          handleCreateSchedule={handleCreateSchedule}
          handleWeeklyRecurrenceValueChange={handleWeeklyRecurrenceValueChange}
        />
      ) : (
        <></>
      )}
    </>
  );
};
