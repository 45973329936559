import React from 'react';
 import { BrowserRouter } from 'react-router-dom';
 import { createRoot } from 'react-dom/client'; 
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import App from './App';
import { configureStore } from './store/store';
import LoadingOverlay from './Pages/LoadingnErrorPages/LoadingOverlay';
import ScrollToTop from './Pages/generalFunctions';
import GlobalErrorMsg from './Pages/LoadingnErrorPages/GlobalErrorMsg';
const { store, persistor } = configureStore({}); // Obtain both the store and persistor


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);



root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.Fragment>
        <BrowserRouter>
        <ScrollToTop />
          <App />
        </BrowserRouter>
        <LoadingOverlay /> 
        <GlobalErrorMsg />
      </React.Fragment>
    </PersistGate>
  </Provider> 
);

