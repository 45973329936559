import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { StyledDataGrid } from "../generalFunctions";
import {
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";
import {
  cancelExecutedTestSuite,
  suiteRunAPI,
} from "../../store/dashboard/orchestractor";
import { setLoadingState, showError } from "../../store/actions";
import { useDispatch } from "react-redux";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { IconButton } from "@mui/material";
import { useProfile } from "../../Hooks/UserHooks";

const smallSelect = {
  fontSize: "12px",
  lineHeight: "15px",
  width: "100%",
  borderRadius: "2px",
  padding: "3px",
  marginBottom: "3px",
};
const circleStyle = {
  width: "13px",
  height: "13px",
  borderRadius: "55%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "8px",
  color: "white",
  marginLeft: "2px",
};

const marginZero = {
  display: "flex",
  flexWrap: "nowrap",
};

const redCircleStyle = {
  ...circleStyle,
  backgroundColor: "#ff3d60",
};

const greenCircleStyle = {
  ...circleStyle,
  backgroundColor: "#11c46e",
};

const blueCircleStyle = {
  ...circleStyle,
  backgroundColor: "#9da1a6",
};

const yellowCircleStyle = {
  ...circleStyle,
  backgroundColor: "#f1b44c",
};

const getStatus = (params) => {
  const status = params.row.status;
  // status = warning   running   passed  undefined failed  cancelled

  return (
    <div style={marginZero}>
      {status === "passed" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={greenCircleStyle}></div>
          <div>&nbsp;{"Completed"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "failed" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={redCircleStyle}></div>
          <div>&nbsp;{"Failed"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "running" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={yellowCircleStyle}></div>
          <div>&nbsp;{"In Progress"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "warning" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={blueCircleStyle}></div>
          <div>&nbsp;{"Warning"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "cancelled" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={blueCircleStyle}></div>
          <div>&nbsp;{"Cancelled"}</div>
        </div>
      ) : (
        <></>
      )}
      {status === "undefined" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={blueCircleStyle}></div>
          <div>&nbsp;{"Undefined"}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const RecentJobs = (props) => {
  const { executedRunList, getExecutedTestRunList } = props;
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const headerHeight = 28;
  const rowHeight = 26;
  const columns = [
    // {
    //   field: "testSuiteId",
    //   headerName: "Suite ID",
    //   // align: "center",
    //   width: 60,
    //   maxWidth: 60,
    // },
    {
      field: "testSuiteName",
      headerName: "Suite Name",
      // align: "center",
      width: 300,
      maxWidth: 300,
    },
    {
      field: "functionalAreaName",
      headerName: "Functional Area",
      // align: "center",
      width: 150,
      maxWidth: 150,
    },
    {
      field: "tenantName",
      headerName: "Tenant",
      // align: "center",
      width: 150,
      maxWidth: 150,
    },
    {
      field: "startedAt",
      headerName: "Started Date",
      width: 160,
      type: "date",
      // align: "center",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "stoppedAt",
      headerName: "Completed Date",
      width: 160,
      type: "date",
      // align: "center",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "userEmailId",
      headerName: "User Email",
      // align: "center",
      width: 200,
      maxWidth: 200,
    },
    {
      field: "status",
      renderCell: getStatus,
      headerName: "Status",
      // // align: "center",
      width: 100,
      maxWidth: 200,
      // disableColumnMenu: true,
      // sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        const status = params.row.status;
        // warning  undefined  passed  failed  running  cancelled
        return (status === "failed" || status === "running") &&
          !userProfile?.user?.viewerRole ? (
          <button
            style={smallSelect}
            type="button"
            className="btn btn-primary"
            onClick={() => handleAction(params, status)}
          >
            {status === "running"
              ? "Cancel"
              : status === "failed"
              ? "Re-Run"
              : ""}
          </button>
        ) : (
          <></>
        );
      },
    },
    {
      field: "downloadFilePath",
      headerName: "File Download",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        const fileUrl = params?.row?.fileUrl;
        return fileUrl ? (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => handleFileDownloadClick(fileUrl)}
          >
            <FileDownloadOutlinedIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        );
      },
    },
    // {
    //   field: "history",
    //   headerName: "History",
    //   align: "center",
    //   width: 100,
    //   maxWidth: 100,
    //   renderCell: (params) => {
    //     const id = params.row.id;
    //     return (
    //       <div class="btn-group" role="group">
    //         <button
    //           style={{
    //             height: 25,
    //             width: 25,
    //           }}
    //           id={`btnGroupDrop_${id}`}
    //           type="button"
    //           className="btn btn-outline-info btn-sm dropdown-toggle"
    //           onClick={() => {}}
    //           data-toggle="dropdown"
    //           aria-haspopup="true"
    //           aria-expanded="false"
    //         >
    //           {"..."}
    //         </button>
    //         <div class="dropdown-menu" aria-labelledby={`btnGroupDrop_${id}`}>
    //           <a class="dropdown-item" href="#">
    //             Dropdown link
    //           </a>
    //           <a class="dropdown-item" href="#">
    //             Dropdown link
    //           </a>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];
  const rows =
    executedRunList && executedRunList?.length
      ? [...executedRunList].map((item, index) => {
          return {
            ...item,
            id: index + 1,
            downloadFilePath: item?.fileUrl,
          };
        })
      : [];
  const [selectedSuiteSelection, setSelectedSuiteSelection] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  useEffect(() => {
    // Effect to handle changes in selected suite selection
  }, [selectedSuiteSelection]);

  const handleFileDownloadClick = (filePath) => {
    try {
      if (filePath) {
        const url = filePath;
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleAction = async (record, status) => {
    try {
      dispatch(setLoadingState(true));
      const id = record?.row?.testSuiteId;
      if (status === "running") {
        if (id) {
          const response = await cancelExecutedTestSuite(id);
          if (response) {
            handleRefreshClick();
          }
        }
      } else if (status === "failed") {
        const {
          testSuiteId = 0,
          filename = "",
          testSuiteName = "",
          faId = 0,
          functionalAreaName = "",
          tenantId = 0,
        } = record?.row;
        if (filename && faId && testSuiteId && tenantId) {
          const response = await suiteRunAPI(
            faId,
            testSuiteId,
            tenantId,
            filename
          );
          console.log(response);
          const message = `"${functionalAreaName}-${testSuiteName}" is initiated`;
          dispatch(showError(true, message, "info"));
          handleRefreshClick(); // Refresh list
        } else {
          const message = !filename
            ? "Uploaded file is not available."
            : !faId
            ? "Functional Area Id is not available."
            : !testSuiteId
            ? "Test Run Suite Id is not available."
            : !tenantId
            ? "Tenant Id is not available."
            : `Some error ocurred.`;
          dispatch(showError(true, message, "error"));
        }
        // dispatch(setLoadingState(false));
      }
      dispatch(setLoadingState(false));
    } catch (error) {
      console.error(error);
      dispatch(setLoadingState(false));
    }
  };
  const handleRefreshClick = () => {
    try {
      getExecutedTestRunList();
    } catch (error) {
      console.error(error);
    }
  };
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    setSelectedSuiteSelection(suiteSelection);
    // setSuiteSelection(suiteSelection); // Call setSuiteSelection here when needed
  };
  const getFilteredRows = ({ apiRef }) => {
    gridExpandedSortedRowIdsSelector(apiRef);
  };
  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    // Function to handle export button click to export data as CSV

    const handleExport = (options) => {
      // Set a custom file name for the exported CSV file
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      options.fileName = `suite_instance_listing_${timestamp}`;

      apiRef.current.exportDataAsCsv(options);
    };
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
      >
        <div>
          <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>
            Suite Run Instance Listing
          </h6>
        </div>

        <div>
          <i
            onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
            className="mdi mdi-microsoft-excel"
            style={{ cursor: "pointer", fontSize: "16px", color: "#4aa3ff" }}
            onMouseEnter={(e) => (e.target.style.color = "#0875e1")}
            onMouseLeave={(e) => (e.target.style.color = "#4aa3ff")}
          ></i>
        </div>
      </div>
    );
  };

  return (
    <Col xl={12}>
      <Card>
        <CardBody>
          <CardTitle className="h4">
            <div className="d-flex justify-content-between align-items-center">
              <div
                className="page-title-right ml-auto"
                style={{ display: "flex" }}
              >
                <div style={{ width: "250px" }}>Suite Run Jobs</div>
                <button
                  style={smallSelect}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleRefreshClick()}
                >
                  Refresh
                </button>
              </div>
            </div>
          </CardTitle>

          <div
            style={{
              background: "#ffffff",
              overflow: "auto",
              height: "445px",
              width: "100%",
              flexGrow: 1,
            }}
          >
            {/* Render the StyledDataGrid component with the specified rows, columns, and props */}
            <StyledDataGrid
              initialState={
                {
                  // columns: {
                  //   columnVisibilityModel: {
                  //     // Hide columns status and traderName, the other columns will remain visible
                  //     warning: false,
                  //     passed: false,
                  //     failed: false,
                  //     undefined: false,
                  //   },
                  // },
                }
              }
              rows={rows}
              columns={columns}
              rowHeight={rowHeight}
              columnHeaderHeight={headerHeight}
              onCellClick={handleCellClick}
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
              slots={{ toolbar: CustomToolbar }}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              componentsProps={{
                // Set custom styles for the datafg grid components

                basePopper: {
                  // sx: {
                  //   backgroundColor: "red",
                  //   "& .MuiPaper-root": { backgroundColor: "green",fontSize:"2px" },
                  //   "&.MuiDataGrid-menu .MuiPaper-root": { backgroundColor: "blue",fontSize:"10px" }
                  // }
                  sx: {
                    "& .MuiTypography-root": { fontSize: "12px" },
                    // '& .MuiDataGrid-filterForm': {backgroundColor: 'lightblue'},
                  },
                },
              }}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
