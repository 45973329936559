import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import DashDountChart from "./Charts/Dashdountchart";
import DashBarchart from "./Charts/Dashbarchart";
import GridMain from "./DataGrid/gridDash";
import { connect, useSelector } from "react-redux";
import {
  currentSuiteNameSelection,
  fetchSuiteInstanceListing,
  setSuiteSelection,
  setLoadingState,
} from "../../store/actions";

import { generatePDF } from "../generalFunctions";
import { sortSuites } from "../../config/util";
import { ExcelExportIcon } from "../../components/ExcelExportIcon";

// Define the SuiteArea component, which receives props from the Redux store.
const SuiteArea = ({
  suites,
  currentSuiteName,
  currentSuiteid,
  setSuiteSelection,
  currentSuiteNameSelection,
  fetchSuiteInstanceListing,
  setLoadingState,
}) => {
  // Define a state variable to keep track of the selected suite.
  const [selectedSuite, setSelectedSuite] = useState(null);

  // This useEffect hook runs when the 'suites' or 'currentSuiteName' props change.
  useEffect(() => {
    // Check if there are suites available.
    if (suites.length > 0) {
      // Find the selected suite based on the 'currentSuiteName' prop.
      const selectedSuitelist = suites.find(
        (suite) => suite.suiteRISummary.suiteId === parseInt(currentSuiteName)
      );
      // Set the selected suite in the state.
      setSelectedSuite(selectedSuitelist || null);
    }
  }, [suites, currentSuiteName]);

  // Function to handle changes to the selected suite from the dropdown.
  const handleSuiteChange = (event) => {
    const selectedSuiteId = event.target.value;
    // Find the selected suite based on the chosen suite ID.
    const selectedSuite = suites.find(
      (suite) => suite.suiteRISummary.suiteId === parseInt(selectedSuiteId)
    );
    // Update the selected suite in the state and Redux store.
    setSelectedSuite(selectedSuite);
    setSuiteSelection(selectedSuite.suiteRIDetails[0].suiteRIId);
    currentSuiteNameSelection(parseInt(selectedSuiteId));
    // Fetch suite instance listing for the selected suite.
    fetchSuiteInstanceListing(selectedSuite.suiteRIDetails);
  };

  const handleGeneratePDF = async () => {
    setLoadingState(true); // Set loading state to false in both success and error cases

    try {
      generatePDF("AreaCharts", "Suite Report Summary"); // Call the generatePDF function with the element's ID
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error("An error occurred on exporting Suite Report:", error);
      // You can add error handling logic here, such as showing an error message to the user.
    } finally {
      setLoadingState(false); // Set loading state to false in both success and error cases
    }
  };

  const faAreas = useSelector((state) => state.dashboardData.faAreas || []);

  if (faAreas.length === 0) {
    return "";
  }

  // Render the component.
  return (
    <Col xl={8} className="minimizeGapSa">
      {/* Suite Name Filter */}
      <Row>
        <Col>
          <Card className="mb-1">
            <CardBody className="card-body-dash">
              <Row>
                <Col className="col-sm-1 w100px">
                  <h5 className="mb-0 mt-1 font-size-12">Suite Name</h5>
                </Col>
                <Col className="page-title-box d-sm-flex align-items-center justify-content-between">
                  {/* Dropdown to select the suite */}
                  <select
                    className="form-select smallSelectSa"
                    id="inlineFormSelectPref"
                    onChange={handleSuiteChange}
                    value={selectedSuite?.suiteRISummary.suiteId}
                  >
                    {suites?.length ? (
                      sortSuites(suites).map((suite) => (
                        <option
                          key={suite.suiteRISummary.suiteId}
                          value={suite.suiteRISummary.suiteId}
                        >
                          {suite.suiteRISummary.suiteName}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                  <ExcelExportIcon
                    handleExport={handleGeneratePDF}
                    getFilteredRows={""}
                    className={"page-title-right"}
                    mouseEnterColor={"#0875e1"}
                    mouseLeaveColor={"#4aa3ff"}
                    iconClass={
                      "mdi mdi-printer align-middle me-2 text-primary hide-on-mobile"
                    }
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={6} style={{ paddingRight: "0px" }}>
          {/* Donut Chart for Suite */}
          {selectedSuite && (
            <DashDountChart dataChart={selectedSuite.suiteRISummary} />
          )}

          {/* Horizontal Bar Chart for Suite */}
          {selectedSuite && (
            <DashBarchart dataChart={selectedSuite.suiteRISummary} />
          )}
        </Col>

        <Col xl={6} style={{ paddingLeft: "6px" }}>
          {/* DataGrid for Suite */}
          <GridMain />
        </Col>
      </Row>
    </Col>
  );
};

// Map the Redux state to props of the component.
const mapStateToProps = (state) => ({
  suites: state.dashboardData.suiteList || [],
  currentSuiteName: state.dashboardData.suiteNameSelectionID || [],
  currentSuiteid: state.dashboardData.suiteSelection || [],
});

// Map dispatch actions to props of the component.
const mapDispatchToProps = {
  setSuiteSelection,
  currentSuiteNameSelection,
  fetchSuiteInstanceListing,
  setLoadingState,
};

// Connect the component to the Redux store and export it.
export default connect(mapStateToProps, mapDispatchToProps)(SuiteArea);
