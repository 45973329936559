import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
// import logodark from "../../assets/images/logo-dark.png";
import newLogoDark from "../../assets/images/logo/Ilumn Coloured Logo.png";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  // Visibility,
  InputAdornment,
  Input,
} from "@mui/material";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  // Input,
  FormFeedback,
  Label,
} from "reactstrap";
// import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../AHeaderFooterSections/HeaderComponents/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, setLoadingState } from "../../store/actions";
import { encryptData } from "../../config/util";
import { AES_IV, AES_KEY } from "../../config/constant";

const Login = (props) => {
  document.title = "Login - Ilumn";

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(setLoadingState(true));
      const encryptedText = null; // encryptData(values?.password, AES_KEY, AES_IV);
      const encrypted = false;
      const requestPayload = {
        email: values?.email,
        password: encryptedText || values?.password,
        encrypted,
      };
      dispatch(loginUser(requestPayload, props.router.navigate));
    },
  });

  const { loading, error } = useSelector((state) => state.login);
  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const [open, setOpen] = useState(false);
  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <div className="account-pages my-4">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4} className="mt-3">
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={newLogoDark}
                          alt=""
                          height="40"
                          className="auth-logo logo-dark mx-auto"
                        />
                      </Link>
                    </div>
                    <p
                      style={{
                        paddingTop: "10px",
                        marginBottom: "5px !important",
                        textAlign: "center",
                      }}
                    >
                      Welcome to Ilumn!
                    </p>
                    <h4 className="font-size-18 text-muted mt-2 text-center">
                      Sign In
                    </h4>

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? (
                        <Alert color="danger">
                          <div>{error}</div>
                        </Alert>
                      ) : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              variant="outlined"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                <div>{validation.errors.email}</div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              className="form-control"
                              variant="outlined"
                              value={validation.values.password || ""}
                              // type="password"
                              type={values.showPassword ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                              endAdornment={
                                <>
                                  {validation.values.password ? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showPassword ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                <div> {validation.errors.password} </div>
                              </FormFeedback>
                            ) : null}
                          </div>

                          <Row>
                            <Col>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-label form-check-label"
                                  htmlFor="customControlInline"
                                  style={{ fontSize: "13px" }}
                                >
                                  Remember me
                                </label>
                              </div>
                            </Col>
                            <Col className="col-7">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link
                                  to="/auth-recoverpw"
                                  className="text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  <i className="mdi mdi-lock"></i> Forgot your
                                  password?
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-grid mt-4">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                          <div className="mt-4 text-center"></div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                <p className="text-white-50">
                  <Link
                    onClick={handleButtonClick}
                    style={{ color: "whitesmoke" }}
                  >
                    Get Help{" "}
                  </Link>
                  |{" "}
                  <Link
                    onClick={handleButtonClick}
                    style={{ color: "whitesmoke" }}
                  >
                    Legal
                  </Link>
                </p>
                <p className="mt-0 mb-0" style={{ color: "whitesmoke" }}>
                  © {new Date().getFullYear()} Ilumn. All rights reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Terms</DialogTitle>
        <DialogContent style={{ width: "600px", height: "280px" }}>
          <p></p>
          <p>
            <strong>Terms of Service:</strong> The rules and guidelines that
            users must agree to when using your software application.
          </p>
          <p>
            <strong>Privacy Policy:</strong> A statement outlining how you
            collect, use, and protect user data.
          </p>
          <p>
            <strong>End-User License Agreement (EULA):</strong> A legal contract
            between the software developer and the user that specifies the terms
            of software usage.
          </p>
          <p>
            <strong>Intellectual Property:</strong> The legal rights associated
            with your software application, including copyrights, trademarks,
            and patents.
          </p>
          <p>
            <strong>Compliance:</strong> Ensuring that your software application
            adheres to relevant laws, regulations, and industry standards.
          </p>
          <p>
            <strong>User Consent:</strong> Obtaining explicit permission from
            users before collecting or using their personal data.
          </p>
          <p>
            <strong>Liability:</strong> The legal responsibility and
            accountability associated with your software application's usage or
            any harm caused by it.
          </p>
          <p>
            <strong>Dispute Resolution:</strong> The process for resolving legal
            conflicts or disagreements that may arise from the use of your
            software application.
          </p>
          <p>
            <strong>Data Retention:</strong> The policy specifying how long user
            data will be stored and when it will be permanently deleted.
          </p>
          <p>
            <strong>Jurisdiction:</strong> Determining the legal framework and
            location that governs your software application and user
            interactions.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
