import React from "react";
import Login from "../Pages/Authentication/Login";
import Logout from "../Pages/Authentication/Logout";
import {
  adminRoutes,
  commonRoutes,
  testerRoutes,
  viewerRoutes,
  privilegedRoutes,
} from "../Pages/accessSettingsMenu";

// Getting UserProfile Object from Local Storage....
const storedAuthUser = localStorage.getItem("authUser");
const obj = storedAuthUser ? JSON.parse(storedAuthUser) : null;

// Getting Admin Roles Flag from Storage
const adminRoleFlag = obj && obj.user && obj.user.adminRole;

// Getting Testing Roles Flag from Storage
const testerRoleFlag = obj && obj.user && obj.user.testerRole;

// Getting Viewer Roles Flag from Storage
const viewerRoleFlag = obj && obj.user && obj.user.viewerRole;
const privilegedRoleFlag = obj && obj.user && obj.user.privilegeUserRole;

const authProtectedRoutes = [
  ...(adminRoleFlag ? adminRoutes : []),
  ...(testerRoleFlag ? testerRoutes : []),
  ...(viewerRoleFlag ? viewerRoutes : []),
  ...(privilegedRoleFlag ? privilegedRoutes : []),
  ...(adminRoleFlag ? [] : commonRoutes),
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
