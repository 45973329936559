import React, { useState } from "react";
import { Collapse, Container } from "reactstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Import Data
import MenuData from "../../CommonData/MenuData";
import { menuSelectionChange } from "../../store/actions";
import {
  adminRoleMenuItems,
  privilegedRoleMenuItems,
  testerRoleMenuItems,
  viewerRoleMenuItems,
} from "../accessSettingsMenu";

const TopMenu = (props) => {
  const dispatch = useDispatch();

  const menuSelection = useSelector(
    (state) => state.dashboardData.menuSelection || []
  );
  const MenuDataVar = menuSetAccessControl();
  const [activeMenu, setActiveMenu] = useState(
    MenuDataVar.length > 0 ? MenuDataVar[0].id : null
  );

  React.useEffect(() => {
    setActiveMenu(menuSelection);
  }, [menuSelection]);

  const handleMenuClick = (menuId) => {
    setActiveMenu(menuId);
    dispatch(menuSelectionChange(menuId));
  };

  return (
    <React.Fragment>
      <div className="topnav">
        <Container style={{ marginRight: "0px", marginLeft: "0px" }}>
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {(MenuDataVar || []).map((item, key) => (
                  <li
                    key={item.id}
                    style={{
                      backgroundColor:
                        item.id === activeMenu ? "rgba(47,46,46,0.3)" : "",
                    }}
                    className={`nav-item dropdown`}
                  >
                    <Link
                      to={item.target}
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={() => handleMenuClick(item.id)}
                      style={{
                        color: item.id === activeMenu ? "white" : "#ffffff",
                      }}
                    >
                      <i className={item.icon}></i>
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </Collapse>
          </nav>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default connect(mapStatetoProps, {})(TopMenu);

function menuSetAccessControl() {
  // Getting UserProfile Object from Local Storage....
  const obj = JSON.parse(localStorage.getItem("authUser"));

  // Getting Admin Roles Flag from Storage
  const adminRoleFlag = obj.user.adminRole;

  // Getting Testing Roles Flag from Storage
  const testerRoleFlag = obj.user.testerRole;

  // Getting Viewer Roles Flag from Storage
  const viewerRoleFlag = obj.user.viewerRole;

  const privilegedRoleFlag = obj.user.privilegeUserRole;
  // Filter the menu items based on the role
  let filteredMenuNavData;

  const MenuDataVar = MenuData().props.children;

  const combinedMenuItems = [
    ...(adminRoleFlag ? adminRoleMenuItems : []),
    ...(testerRoleFlag ? testerRoleMenuItems : []),
    ...(viewerRoleFlag ? viewerRoleMenuItems : []),
    ...(privilegedRoleFlag ? privilegedRoleMenuItems : []),
  ];

  const uniqueCombinedMenuItems = Array.from(new Set(combinedMenuItems));

  filteredMenuNavData = MenuDataVar.filter((item) =>
    uniqueCombinedMenuItems.includes(item.id)
  );

  return filteredMenuNavData;
}
