import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export const BubbleChart = (props) => {
  const { businessProcessDetails, height } = props;

  const chartDetails = {
    series: [],
    options: {
      chart: {
        height: height,
        type: "bubble",
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 0.8,
      },
      title: {
        text: "",
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
      },
      xaxis: {
        categories: [],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
        labels: {
          show: true,
          height: 150,
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      responsive: [
        {
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const [chartData, setChartData] = useState(chartDetails);

  useEffect(() => {
    if (businessProcessDetails?.length) {
      const seriesData = [];
      const xAxisCategories = [];
      let dataCount = [];

      businessProcessDetails.forEach((rec, index) => {
        if (rec?.processName !== "Total") {
          seriesData.push({
            name: rec?.processName,
            data: [
              [
                index + 1,
                rec?.Count >= 20 ? rec?.Count - 20 : rec?.Count || 0,
                rec?.Count ? rec?.Count + 20 : 0,
              ],
            ],
          });
          dataCount.push(rec?.Count || 0);
          xAxisCategories.push(rec?.processName || "");
        }
      });

      dataCount = dataCount.sort((a, b) => a - b);
      dataCount = [...new Set(dataCount)];
      const series = [...seriesData];

      const categories = xAxisCategories;
      setChartData({
        ...chartData,
        series: series,
        options: {
          ...chartData.options,
          xaxis: {
            tickAmount: 15,
            type: "category",
            categories: categories,
          },
          yaxis: {
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessProcessDetails]);

  return (
    <div style={{ width: "100%" }}>
      {chartData.series ? (
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bubble"
          height={height}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
