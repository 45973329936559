// Import required modules and components
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Col, Collapse, InputGroup, Row } from "reactstrap";
import {
  getTenants,
  setRunSearch,
  setSearchFlag,
  setTenantSelection,
  showError,
} from "../../store/dashboard/actions";
import moment from "moment";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tenantfetch } from "../../store/dashboard/orchestractor";
import { initDate } from "../generalFunctions";

// Functional component Filters
const Filters = ({
  tenants,
  tenantSelection,
  startDateValue,
  endDateValue,
  setTenantSelection,
}) => {
  // State variables using React hooks
  const [col1, setCol1] = useState(true);
  const [selectedTenant, setSelectedTenant] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  // useEffect hook to set state variables based on props
  useEffect(() => {
    setSelectedTenant(tenantSelection);
    setStartDate(startDateValue);
    setEndDate(endDateValue);
  }, [tenantSelection, startDateValue, endDateValue]);

  // Function to toggle the collapse state for column 1
  const toggleCol1 = () => {
    setCol1(!col1);
  };

  // Event handler for selecting a tenant from the dropdown
  const handleTenantChange = (event) => {
    setSelectedTenant(event.target.value);
  };

  // Event handler for form submission
  const handleFormSubmit = async () => {
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    if (formattedStartDate <= formattedEndDate) {
      setTenantSelection(
        parseInt(selectedTenant, 10),
        formattedStartDate,
        formattedEndDate
      );
    } else {
      const message = "From-Date should not great than or equalto To-Date";
      dispatch(showError(true, message, "error"));
      setStartDate(startDateValue);
      setEndDate(endDateValue);
    }
  };

  // State variables for radio button and search text
  const [selectedRadio, setSelectedRadio] = useState("SuiteRunIDRadios");
  const [searchText, setSearchText] = useState("");

  const searchFlag = true;
  const dispatch = useDispatch();

  const notiId = localStorage.getItem("notiId");
  useEffect(() => {
    if (notiId) {
      handleSearch(parseInt(notiId));
      setSearchText(parseInt(notiId));
      localStorage.removeItem("notiId");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notiId]);

  // Event handler for radio button change
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  // Event handler for search button click
  const handleSearch = (id) => {
    // Check if searchText is not a number or is blank
    const searchTextData = id || searchText;
    if (!/^\d+$/.test(searchTextData)) {
      // Display a message or take any other action to notify the user about invalid input
      const errormsg = "Please enter proper Run ID to Search";
      dispatch(showError(true, errormsg, "error"));
    } else {
      // Perform the search and navigation only if the input is valid
      dispatch(setRunSearch(searchTextData, selectedRadio));
      dispatch(setSearchFlag(searchFlag));
      if (selectedRadio === "scenRunIDRadios") {
        navigate("/workboard");
      }
    }
  };

  // Event handler for reset button click
  const handleReset = async () => {
    const tenantInit = await tenantfetch();
    dispatch(getTenants(tenantInit.tenants));
    dispatch(
      setTenantSelection(
        tenantInit.tenantSelection,
        initDate().startDate,
        initDate().endDate
      )
    );
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  return (
    <Row>
      <Col className="col-12">
        <div className="card">
          <div className="card-body p-0">
            <div className="accordion" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col1,
                    })}
                    type="button"
                    onClick={toggleCol1}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="mb-0 font-size-14">Filters</h4>
                  </button>
                </h2>

                <Collapse isOpen={col1} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="text-muted">
                      <Row>
                        <div className="col-md-6 col-sm-12">
                          <Row className="mb-2 row">
                            <div className="card-title-desc-filters">
                              Please choose tenant and from, to date to show
                              results.
                            </div>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-3">
                              <label className="small-select">
                                Choose your Tenant
                              </label>
                            </Col>
                            <Col className="col-9">
                              <select
                                className="form-select small-select"
                                id="inlineFormSelectPref"
                                value={selectedTenant}
                                onChange={handleTenantChange}
                              >
                                {tenants.map((tenant) => (
                                  <option
                                    key={tenant.tenantId}
                                    value={tenant.tenantId}
                                  >
                                    {tenant.tenantName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-3">
                              <label className="small-select">
                                Select From Date
                              </label>
                            </Col>
                            <Col className="col-9">
                              <InputGroup>
                                <Flatpickr
                                  id="startDatepicker"
                                  className="form-control-cal d-block"
                                  placeholder="Enter to Date"
                                  options={{
                                    altInput: true,
                                    altFormat: "d-M-Y",
                                    dateFormat: "Y-m-d",
                                    onChange: (selectedDates) =>
                                      setStartDate(selectedDates[0]),
                                    disable: [
                                      {
                                        from: tomorrow,
                                        to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                                      },
                                    ],
                                  }}
                                  value={startDate}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-3">
                              <label className="small-select">
                                Select To Date
                              </label>
                            </Col>
                            <Col className="col-9">
                              <InputGroup>
                                <Flatpickr
                                  id="endDatepicker"
                                  className="form-control-cal d-block"
                                  placeholder="Enter to Date"
                                  type="datetime-local"
                                  options={{
                                    altInput: true,
                                    altFormat: "d-M-Y",
                                    dateFormat: "Y-m-d",
                                    onChange: (selectedDates) =>
                                      setEndDate(selectedDates[0]),
                                    disable: [
                                      {
                                        from: tomorrow,
                                        to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                                      },
                                    ],
                                  }}
                                  value={endDate}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <Row className="mb-2 row"></Row>
                          <Row className="mb-4 row">
                            <Col className="col-3"></Col>
                            <Col className="col-6"></Col>
                            <Col className="col-3"></Col>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-3"></Col>
                            <Col className="col-6">
                              <button
                                type="button"
                                className="btn btn-primary small-select"
                                onClick={handleFormSubmit}
                              >
                                Submit
                              </button>
                            </Col>
                            <Col className="col-3"></Col>
                          </Row>

                          <Row className="mb-2 row pt-1">
                            <Col className="col-3"></Col>
                            <Col className="col-6">
                              <button
                                type="button"
                                className="btn btn-outline-danger small-select"
                                onClick={handleReset}
                              >
                                Reset
                              </button>
                            </Col>
                            <Col className="col-3"></Col>
                          </Row>
                        </div>

                        {/* Quick filters */}
                        <div className="col-md-3 col-sm-12 mystyle">
                          <Row className="mb-2 row">
                            <div className="card-title-desc-filters">
                              Search test cases with run IDs
                            </div>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-1"></Col>
                            <Col className="col-8">
                              <input
                                type="text"
                                className="form-control small-select"
                                placeholder="Search..."
                                value={searchText}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                onChange={(e) => setSearchText(e.target.value)}
                              />
                            </Col>
                            <Col
                              className="col-2"
                              style={{
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary btn-rounded waves-effect waves-light small-select"
                                onClick={() => handleSearch(searchText)}
                              >
                                Go
                              </button>
                            </Col>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-1"></Col>
                            <Col className="col-8">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadios"
                                  id="SuiteRunIDRadios"
                                  value="SuiteRunIDRadios"
                                  checked={selectedRadio === "SuiteRunIDRadios"}
                                  onChange={handleRadioChange}
                                />
                                <label
                                  className="form-check-label small-select"
                                  htmlFor="SuiteRunIDRadios"
                                >
                                  Suite Run ID
                                </label>
                              </div>
                            </Col>
                            <Col className="col-2"></Col>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-1"></Col>
                            <Col className="col-9">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="inlineRadios"
                                  id="scenRunIDRadios"
                                  value="scenRunIDRadios"
                                  checked={selectedRadio === "scenRunIDRadios"}
                                  onChange={handleRadioChange}
                                />
                                <label
                                  className="form-check-label small-select"
                                  htmlFor="scenRunIDRadios"
                                >
                                  Scenario Run ID
                                </label>
                              </div>
                            </Col>
                            <Col className="col-2"></Col>
                          </Row>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  tenants: Object.values(state.dashboardData.tenants) || [],
  tenantSelection: state.dashboardData.tenantSelection || "",
  startDateValue: state.dashboardData.startDate || "",
  endDateValue: state.dashboardData.endDate || "",
});

export default connect(mapStateToProps, { setTenantSelection })(Filters);
