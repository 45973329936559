import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "./landpagestyle.scss";
import { Card, CardBody, CardTitle } from "reactstrap";
import { notificationFetch } from "../../store/dashboard/orchestractor";
import { useDispatch, useSelector } from "react-redux";
import { menuSelectionChange } from "../../store/actions";

const Notifications = () => {
  const dispatch = useDispatch();
  const [NotificationsData, setNotificationsData] = useState([]);
  const tenantSelection = useSelector(
    (state) => state.dashboardData.tenantSelection || 0
  );

  const storedAuthUser = localStorage.getItem("authUser");
  const obj = storedAuthUser ? JSON.parse(storedAuthUser) : null;
  const userEmailId = obj && obj.user && obj.user.userEmailId;

  useEffect(() => {
    const fetchData = async () => {
      const data = await notificationFetch(tenantSelection, userEmailId);
      setNotificationsData(data || []);
    };
    if (tenantSelection) {
      fetchData();
    }
  }, [tenantSelection, userEmailId]);

  const handleNotificationClick = (event, item) => {
    try {
      localStorage.setItem("notiId", item?.suiteRIId);
      dispatch(menuSelectionChange(2));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Card style={{ height: "505px" }}>
        <CardBody>
          <CardTitle style={{ fontSize: "16px" }}>Notifications</CardTitle>
          <div className="pe-3">
            <SimpleBar style={{ maxHeight: "395px" }}>
              {NotificationsData.length === 0 ? (
                <p>No notification records found</p>
              ) : (
                NotificationsData.map((item, key) => (
                  <Link
                    key={key}
                    to="/dashboard"
                    className="text-body d-block highlight"
                    onClick={(e) => handleNotificationClick(e, item)}
                  >
                    <div className="d-flex py-3">
                      <div className="flex-shrink-0 me-3 align-self-center">
                        {item.src ? (
                          <img
                            className="rounded-circle avatar-xs"
                            alt=""
                            src={item.src}
                          />
                        ) : (
                          <div className="avatar-xs">
                            <span className="avatar-title bg-soft-primary rounded-circle text-primary">
                              <i
                                className="mdi mdi-email-outline"
                                style={{ fontSize: "20px" }}
                              ></i>
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-14 mb-1">
                          {item.suiteName} ({item.suiteRIId}) -{" "}
                          {item.suiteStatus}
                        </h5>
                        <p className="text-truncate mb-0">
                          Suite - {item.suiteName}({item.suiteRIId}) is{" "}
                          {item.suiteStatus}
                        </p>
                      </div>
                      <div className="flex-shrink-0 font-size-13">
                        {item?.updatedAt || item?.suiteStartDateTime
                          ? formatDate(
                              item?.updatedAt || item?.suiteStartDateTime
                            )
                          : ""}
                      </div>
                    </div>
                    {key !== NotificationsData.length - 1 && (
                      <hr className="my-3" />
                    )}
                  </Link>
                ))
              )}
            </SimpleBar>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Notifications;

// Helper function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  };
  const formattedDateTime = date.toLocaleDateString("en-UK", options);
  return formattedDateTime;
}
