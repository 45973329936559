import React from "react";

export const MyTextField = (props) => {
  const {
    value = "",
    handleChange = () => {},
    type = "text",
    className = "",
    placeholder = "",
    pattern = "",
    inputMode = "text",
    disabled = false,
    maxLength = 100,
  } = props;

  return (
    <input
      type={type || "text"}
      className={className || "form-control small-select"}
      placeholder={placeholder || ""}
      value={value || ""}
      inputMode={inputMode || "text"}
      pattern={pattern || ""}
      onChange={(e) => handleChange(e)}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};
