import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Container, Col } from "reactstrap";
import {
  menuSelectionChange,
  setLoadingState,
  showError,
} from "../../store/actions";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import {
  quickLinkCreate,
  quickLinkDelete,
  quickLinkFetch,
  quickLinkUpdate,
  quicklinkFetch,
} from "../../store/dashboard/orchestractor";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { StyledDataGrid } from "../generalFunctions";
import { QuickLinkDetails } from "./QuickLinkDetails";
import { useProfile } from "../../Hooks/UserHooks";

export const QuickLinks = () => {
  const dispatch = useDispatch();
  const { userProfile, loading } = useProfile();

  const headerHeight = 28;
  const rowHeight = 26;
  const defaultError = {
    isError: false,
    message: null,
  };

  const [rows, setRows] = useState([]);
  const [profileDetails, setProfileDetails] = useState(userProfile?.user || {});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedUserSelection, setSelectedUserSelection] = useState(null);
  const [openQuickLinkModal, setOpenQuickLinkModal] = useState({
    open: false,
    details: null,
    type: "Add",
  });
  const [selectedQuickLinkDetails, setSelectedQuickLinkDetails] =
    useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [quickLinkEditDetails, setQuickLinkEditDetails] = useState({
    id: 0,
    subject: "",
    description: "",
    linkURL: "",
  });
  const [subjectErrorMessages, setSubjectErrorMessages] =
    useState(defaultError);
  const [descriptionErrorMessages, setDescriptionErrorMessages] =
    useState(defaultError);
  const [linkURLErrorMessages, setLinkURLErrorMessages] =
    useState(defaultError);

  useEffect(() => {
    setProfileDetails(userProfile?.user || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    handleMenuClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllQuickLinkDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = () => {
    dispatch(menuSelectionChange(9));
  };
  const getAllQuickLinkDetails = async () => {
    try {
      dispatch(setLoadingState(true));
      const requestParam = `?per_page=9999`;
      const data = await quicklinkFetch(requestParam);
      setRows(data);
      dispatch(setLoadingState(false));
    } catch (error) {
      console.error("Error fetching quickLinksData:", error);
      setRows([]);
      dispatch(setLoadingState(false));
    }
  };
  // ======================================================
  const columns = [
    {
      field: "subject",
      headerName: "Subject",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        const subject = params.row.subject;
        return <div dangerouslySetInnerHTML={{ __html: subject }} />;
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      flex: 1,
      renderCell: (params) => {
        const description = params.row.description;
        return <div dangerouslySetInnerHTML={{ __html: description }} />;
      },
    },
    {
      field: "linkURL",
      headerName: "Link URL",
      width: 350,
      flex: 1,
      // renderCell: (params) => {
      //   const description = params.row.description;
      //   return <div dangerouslySetInnerHTML={{ __html: description }} />;
      // },
    },
    {
      field: "inserted_at",
      headerName: "Created Date",
      width: 160,
      type: "date",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "updated_at",
      headerName: "Updated Date",
      width: 160,
      type: "date",
      valueFormatter: (params) => {
        const date = params.value ? new Date(params.value) : "";
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const formattedDateTime = date
          ? date.toLocaleDateString("en-UK", options)
          : "";
        return params.value ? formattedDateTime : "";
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleDeleteUserClick(params?.row)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleEditUserClick(params?.row)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];
  const handleCellClick = async (params) => {
    const suiteSelection = params.row.id;
    setSelectedUserSelection(suiteSelection);
  };
  const getFilteredRows = ({ apiRef }) => {
    gridExpandedSortedRowIdsSelector(apiRef);
  };
  const CustomToolbar = () => {
    const apiRef = useGridApiContext();
    const handleExport = (options) => {
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      options.fileName = `manage_user_listing_${timestamp}`;
      apiRef.current.exportDataAsCsv(options);
    };

    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
      >
        <div>
          <h6 style={{ marginBottom: "5px", fontSize: "11px" }}>
            Quick Link Listing
          </h6>
        </div>

        <div style={{ display: "none" }}>
          <i
            onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
            className="mdi mdi-microsoft-excel"
            style={{ cursor: "pointer", fontSize: "16px", color: "#4aa3ff" }}
            onMouseEnter={(e) => (e.target.style.color = "#0875e1")}
            onMouseLeave={(e) => (e.target.style.color = "#4aa3ff")}
          ></i>
        </div>
      </div>
    );
  };
  // ======================================================
  const handleDeleteUserClick = (params) => {
    setSelectedQuickLinkDetails(params);
    setOpenDeleteConfirmation(true);
  };
  const handleEditUserClick = (params) => {
    setOpenQuickLinkModal({
      open: true,
      details: params,
      type: "Edit",
    });
    setQuickLinkEditDetails({
      id: params?.id || 0,
      subject: params?.subject || "",
      description: params?.description || "",
      linkURL: params?.linkURL || "",
    });
  };
  const handleDeleteQuickLinkConfirmationClose = () => {
    setOpenDeleteConfirmation(false);
  };
  const handleDeleteQuickLinkConfirmationClick = async () => {
    try {
      const { id } = selectedQuickLinkDetails || {};
      if (id) {
        dispatch(setLoadingState(true));
        const response = await quickLinkDelete(id);
        if (response) {
          const resp = await response.json();
          setSelectedQuickLinkDetails(null);
          setOpenDeleteConfirmation(false);
          dispatch(setLoadingState(false));

          const message = resp?.message || "QuickLink deleted successfully";
          dispatch(showError(true, message, "info"));
          getAllQuickLinkDetails();
        } else {
          const message = "Error with quickLink deletion";
          dispatch(showError(true, message, "error"));
          dispatch(setLoadingState(false));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCreateQuickLink = (event) => {
    try {
      setOpenQuickLinkModal({
        open: true,
        details: null,
        type: "Add",
      });
      setQuickLinkEditDetails({
        id: 0,
        subject: "",
        description: "",
        linkURL: "",
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleQuickLinkFieldsChangeClick = (event, fieldName) => {
    try {
      const { value } = event.target;
      setQuickLinkEditDetails({
        ...quickLinkEditDetails,
        [fieldName]: value,
      });
      setSubjectErrorMessages(defaultError);
      setDescriptionErrorMessages(defaultError);
      setLinkURLErrorMessages(defaultError);
    } catch (error) {
      console.error(error);
    }
  };
  const handleQuickLinkModalClose = () => {
    setOpenQuickLinkModal({ open: false, details: null, type: "Add" });
    setQuickLinkEditDetails({
      id: 0,
      subject: "",
      description: "",
      linkURL: "",
    });
  };
  const handleQuickLinkModalSave = async () => {
    try {
      const { id, subject, description, linkURL } = quickLinkEditDetails || {};
      if (subject && subject?.trim() !== "") {
        if (description && description?.trim() !== "") {
          if (linkURL && linkURL?.trim() !== "") {
            dispatch(setLoadingState(true));
            const requestParam = {
              quick_link: {
                description: description,
                subject: subject,
                linkURL: linkURL,
                userId: profileDetails?.userId || 0,
              },
            };
            setSubjectErrorMessages(defaultError);
            setDescriptionErrorMessages(defaultError);
            setLinkURLErrorMessages(defaultError);
            let response = null;
            if (openQuickLinkModal?.type === "Edit" && id) {
              response = await quickLinkUpdate(requestParam, id);
            } else {
              response = await quickLinkCreate(requestParam);
            }
            if (response) {
              handleQuickLinkModalClose();
              dispatch(setLoadingState(false));
              const message =
                response?.message ||
                `QuickLink ${
                  openQuickLinkModal?.type === "Edit" ? "updated" : "added"
                } successfully`;
              dispatch(showError(true, message, "info"));
              getAllQuickLinkDetails();
            } else {
              dispatch(setLoadingState(false));
              const message = `Error occurred while ${
                openQuickLinkModal?.type === "Edit" ? "updating" : "creating"
              } quickLink`;
              dispatch(showError(true, message, "info"));
            }
          } else {
            setLinkURLErrorMessages({
              isError: true,
              message: "Quick link is required",
            });
          }
        } else {
          setDescriptionErrorMessages({
            isError: true,
            message: "Description is required",
          });
        }
      } else {
        setSubjectErrorMessages({
          isError: true,
          message: "Subject is required",
        });
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoadingState(false));
      const message = `Error occurred while ${
        openQuickLinkModal?.type === "Edit" ? "updating" : "creating"
      } quickLink`;
      dispatch(showError(true, message, "error"));
    }
  };

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ marginTop: "30px" }}
      >
        <Container fluid>
          <Breadcrumbs
            title="Home"
            titleRoute={"/manage-users"}
            breadcrumbItem="Quick Links"
            style={{ backgroundColor: "#f8f9fa" }}
          />
          <React.Fragment>
            <Row>
              <Col className="col-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="col-md-12 col-sm-12">
                      <div
                        style={{
                          padding: 10,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>QuickLink List</Typography>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={handleCreateQuickLink}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        overflow: "auto",
                        height: "445px",
                        width: "100%",
                        flexGrow: 1,
                        padding: 10,
                      }}
                    >
                      <StyledDataGrid
                        initialState={{}}
                        rows={rows}
                        columns={columns}
                        rowHeight={rowHeight}
                        columnHeaderHeight={headerHeight}
                        onCellClick={handleCellClick}
                        hideFooter
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        slots={{ toolbar: CustomToolbar }}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                          setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        componentsProps={{
                          basePopper: {
                            sx: {
                              "& .MuiTypography-root": { fontSize: "12px" },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </div>

      {openDeleteConfirmation ? (
        <Dialog
          open={openDeleteConfirmation}
          onClose={handleDeleteQuickLinkConfirmationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure, you want to delete quick link?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteQuickLinkConfirmationClose}>
              Cancel
            </Button>
            <Button
              onClick={handleDeleteQuickLinkConfirmationClick}
              autoFocus
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
      {/* Edit User Details */}
      {openQuickLinkModal?.open ? (
        <Dialog
          open={openQuickLinkModal?.open}
          onClose={handleQuickLinkModalClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>{`${openQuickLinkModal?.type} Quick Link`}</DialogTitle>
          <DialogContent>
            <Row>
              <QuickLinkDetails
                subjectErrorMessages={subjectErrorMessages}
                descriptionErrorMessages={descriptionErrorMessages}
                linkURLErrorMessages={linkURLErrorMessages}
                quickLinkEditDetails={quickLinkEditDetails}
                handleQuickLinkFieldsChangeClick={
                  handleQuickLinkFieldsChangeClick
                }
              />
            </Row>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleQuickLinkModalClose}>Close</Button>
            <Button onClick={handleQuickLinkModalSave}>
              {openQuickLinkModal?.type === "Add" ? "Save" : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
