import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardTitle } from "reactstrap";
 


const DashBarchart = ({dataChart}) => {

  const suiteName=dataChart.suiteName
  const passedCount=dataChart.suiteRIStatusSummary.passedCount
  const failedCount = dataChart.suiteRIStatusSummary.failedCount
  const warningCount = dataChart.suiteRIStatusSummary.warningCount
  const undefinedCount = dataChart.suiteRIStatusSummary.undefinedCount




  const barchartData = {
    series: [
      {
        data: [passedCount, failedCount, warningCount, undefinedCount],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 100,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "40%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
            enabled: true, // Enable data labels
          },
        },
      },
      colors: [
        "rgba(17, 196, 110,0.90)",
        "rgba(255, 61, 96,0.90)",
        "rgba(252, 185, 44,0.90)",
        "rgba(157,161,166,1)",
      ],
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '8px',
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: ["Passed", "Failed", "Warning", "Undefined"],
      },
      yaxis: {
        labels: {
          show: true,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false, // Hide the grid lines
      },
    },
  };



  return (
    <React.Fragment>
      <Card style={{ marginBottom: "10px" }}>
        <CardBody className="card-body-dash">
          <CardTitle style={{ fontSize: "12px" }}>Suite - {suiteName}</CardTitle>
          <ReactApexChart
            options={barchartData.options}
            series={barchartData.series}
            type="bar"
            height="199"
            className="apex-charts"
            style={{ marginTop: "-10px" }}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DashBarchart;
