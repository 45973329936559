import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { MyTextField } from "../../components/TextField";
import { useProfile } from "../../Hooks/UserHooks";
import { useState } from "react";

import avatar2 from "../../assets/images/users/Ilumn-Avatar-05.png";

export const ManageProfile = () => {
  const { userProfile } = useProfile();

  const [profileDetails, setProfileDetails] = useState(userProfile?.user || {});
  const [selectedImage, setSelectedImage] = useState(avatar2);

  useEffect(() => {
    setProfileDetails(userProfile?.user || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // You can perform additional checks or validations here
      setSelectedImage(URL.createObjectURL(file));
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      // You can perform additional checks or validations here
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className="card">
            <div className="card-body p-0">
              <div
                className="text-muted"
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <Row>
                  <div className="col-md-1 col-sm-12"></div>
                  <div className="col-md-5 col-sm-12">
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="manage-profile-user-image"
                        >
                          Profile Avatar
                        </label>
                      </Col>
                      <Col className="col-9">
                        <img
                          ariaLabelledby="manage-profile-user-image"
                          style={{ height: 100, width: 100 }}
                          src={selectedImage || avatar2}
                          alt="User profile avatar"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="manage-profile-user-file-upload"
                        >
                          Upload Avatar
                        </label>
                      </Col>
                      <Col className="col-5">
                        <div className="image-upload-container">
                          <div
                            onDrop={handleDrop}
                            aria-labelledby="manage-profile-user-file-upload"
                            onDragOver={handleDragOver}
                            className="upload-area"
                          >
                            <input
                              type="file"
                              aria-labelledby="manage-profile-user-file-upload"
                              class="form-control-file"
                              id="fileInput"
                              name="fileInput"
                              accept="image/*"
                              onChange={handleImageChange}
                              className="file-input"
                            />
                            <label htmlFor="manage-profile-user-file-upload">
                              <span className="select-image">
                                Select an image
                              </span>
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-4"></Col>
                    </Row>
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="manage-profile-user-fName"
                        >
                          First Name
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          ariaLabelledby="manage-profile-user-fName"
                          value={profileDetails?.userFirstName || ""}
                          handleChange={() => {}}
                          type={"text"}
                          className={""}
                          placeholder={""}
                          pattern={""}
                          inputMode={"text"}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="manage-profile-user-lName"
                        >
                          Last Name
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          ariaLabelledby="manage-profile-user-lName"
                          value={profileDetails?.userLastName || ""}
                          handleChange={() => {}}
                          type={"text"}
                          className={""}
                          placeholder={""}
                          pattern={""}
                          inputMode={"text"}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="manage-profile-user-email"
                        >
                          Email
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          ariaLabelledby="manage-profile-user-email"
                          value={profileDetails?.userEmailId || ""}
                          handleChange={() => {}}
                          type={"email"}
                          className={""}
                          placeholder={""}
                          pattern={
                            "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
                          }
                          inputMode={"email"}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="col-md-1 col-sm-12"></div>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
