import React ,{ useEffect }from "react";
import AreaChartB from "./AreaChartB";
import SuiteArea from "./SuitArea";
import Filters from "./Filters";
import {  Row, Container } from "reactstrap";
import "./Dashboard.scss";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import FunctionalArea from "./FunctionalArea";
import { menuSelectionChange } from "../../store/actions";
import { useDispatch } from "react-redux";
 
const Dashboard = () => {
  const dispatch = useDispatch();
  
 
   useEffect(() => {
    document.title = "Home | Ilumn ";
    dispatch(menuSelectionChange(2))  }, [dispatch]);
  return (
   
      <div className="page-content" id="component">



        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Test Results" />
          {/* Filter Component */}
          <Filters />

          <Row>
            {/* FunctionalArea Component */}
            <FunctionalArea/>
          </Row>

          <Row id="AreaCharts" style={{background:"#f3f3f4",paddingTop:"10px"}}>
            {/* Functional Area Summary Chart */}
            <AreaChartB />

            {/* Suite Charts Area  */}
            <SuiteArea />
          </Row>
 


      
        </Container>
      </div>
   
  );
};

export default Dashboard;

 