import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Card, CardBody, CardTitle } from "reactstrap";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { announcementFetch } from "../../store/dashboard/orchestractor";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Announcements = () => {
  // const [currentIndex, setCurrentIndex] = useState(0);

  const [AnnouncementsData, setAnnouncementsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAnnouncementDetails, setSelectedAnnouncementDetails] =
    useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const requestParam = `?per_page=10`;
        const data = await announcementFetch(requestParam);
        setAnnouncementsData(data || []);
      } catch (error) {
        console.error("Error fetching AnnouncementsData:", error);
      }
    }

    fetchData();
    // const interval = setInterval(() => {
    //   setCurrentIndex((prevIndex) =>
    //     prevIndex === AnnouncementsData.length - 1 ? 0 : prevIndex + 1
    //   );
    // }, 5000); // Change rotation interval here (in milliseconds)

    // return () => clearInterval(interval);
  }, []);

  const handleOpenDetailsDialog = (announcementData) => {
    setOpenDialog(true);
    setSelectedAnnouncementDetails(announcementData);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setSelectedAnnouncementDetails(null);
  };

  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: true,
  //   autoplay: true,
  //   autoplaySpeed: 2000, // Change rotation interval here (in milliseconds)
  //   speed: 500,
  //   slidesToShow: 3, // Adjust the number of items to show in rotation
  //   slidesToScroll: 1,
  //   vertical: true,
  //   verticalSwiping: true,
  // };

  return (
    <React.Fragment>
      <Card style={{ height: "242px" }}>
        <CardBody>
          <CardTitle>Announcements</CardTitle>
          <div className="pe-3">
            <SimpleBar style={{ maxHeight: "180px" }}>
              {/* <Slider
                {...settings}
                slickGoTo={currentIndex}
                style={{ height: "160px" }}
              > */}
              {AnnouncementsData.map((item, key) => (
                <Link
                  key={item.id}
                  to="#"
                  className="text-body d-block"
                  onClick={(event) => {
                    event.preventDefault();
                    handleOpenDetailsDialog(item);
                  }}
                >
                  <div className="d-flex py-3">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      {item.src ? (
                        <img
                          className="rounded-circle avatar-xs"
                          alt=""
                          src={item.src}
                        />
                      ) : (
                        <div className="avatar-xs">
                          <span className="avatar-title bg-soft-primary rounded-circle text-primary">
                            <i
                              className="mdi mdi-bullhorn"
                              style={{ fontSize: "20px" }}
                            ></i>
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mb-1">{item.subject}</h5>
                      <p className="text-truncate mb-0">{item.description}</p>
                    </div>
                    <div className="flex-shrink-0 font-size-13">
                      {formatDate(item.published_date)}
                    </div>
                  </div>
                  {key !== AnnouncementsData.length - 1 && (
                    <hr className="my-1" />
                  )}
                </Link>
              ))}
              {/* </Slider> */}
            </SimpleBar>
          </div>
        </CardBody>
      </Card>
      <BootstrapDialog
        open={openDialog}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
          Announcement Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <DialogContentText> */}
          {/* <Typography gutterBottom>
            {selectedAnnouncementDetails?.subject || ""}
          </Typography>
          <Typography gutterBottom>
            {selectedAnnouncementDetails?.description || ""}
          </Typography> */}
          <Typography gutterBottom>
            {selectedAnnouncementDetails?.detailedDescription ||
              "No details available"}
          </Typography>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default Announcements;

// Helper function to format the date
function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  };
  const formattedDateTime = date.toLocaleDateString("en-UK", options);
  return formattedDateTime;
}
