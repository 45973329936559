import React, { useState } from "react";
import LogsPopUp from "./LogsPopup";
import { connect, useDispatch } from "react-redux";
import RenderExpandableCell from "./tooltipHelp";
import {
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
} from "@mui/x-data-grid";
import "./headerStyle.css";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { StyledDataGrid, StatusIconComponent } from "../../generalFunctions";
import { generateServiceNow } from "../../../store/dashboard/WrapperAPI";
import { useEffect } from "react";
import { setLoadingState, showError } from "../../../store/actions";
import { ExcelExportIcon } from "../../../components/ExcelExportIcon";

// Function to get filtered rows based on the current grid API

const getFilteredRows = ({ apiRef }) =>
  gridExpandedSortedRowIdsSelector(apiRef);

// CustomToolbar component for the data grid's toolbar section

const CustomToolbar = () => {
  const apiRef = useGridApiContext();
  // Function to handle export button click to export data as CSV

  const handleExport = (options) => {
    // Set a custom file name for the exported CSV file

    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    options.fileName = `steps_list${timestamp}`;

    apiRef.current.exportDataAsCsv(options);
  };
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "0 5px 0 5px", borderBottom: " 1px solid #f0f0f0" }}
    >
      <div>
        <h6 style={{ marginBottom: "0px", fontSize: "11px" }}>Step Lists</h6>
      </div>
      <ExcelExportIcon
        handleExport={handleExport}
        getFilteredRows={getFilteredRows}
      />
    </div>
  );
};
// WBstepsTest component

const WBstepsTest = ({ stepList, scenaioList, currentscenarioID }) => {
  const dispatch = useDispatch();

  // Define the columns for the data grid
  const columns = [
    {
      field: "stepName",
      headerName: "Step Name",
      width: 150,
      renderCell: (params) =>
        React.createElement(RenderExpandableCell, { ...params }),
      flex: 1,
    },
    {
      field: "stepStartDatetime",
      headerName: "Date",
      width: 130,
      align: "center",
      type: "date",

      valueFormatter: (params) => {
        const date = new Date(params.value);
        const options = {
          day: "2-digit",
          month: "short",
          year: "2-digit",
        };
        const formattedDateTime = date.toLocaleDateString("en-UK", options);
        return formattedDateTime;
      },
    },
    {
      field: "stepStatus",
      renderCell: (params) => StatusIconComponent(params, "stepStatus"),
      type: "singleSelect",
      valueOptions: ["Passed", "Failed", "Warning", "Undefined"],
      headerName: "Status",
      align: "center",
      width: 65,
    },
  ];

  columns.push({
    field: "logDetails",
    headerName: "Logs",
    sortable: false,
    align: "center",
    minWidth: 38,
    width: 38,
    renderCell: (params) => (
      <RenderCell
        params={params}
        details={{ stepList, scenaioList, currentscenarioID }}
        dispatch={dispatch}
      />
    ),
    disableExport: true,
  });

  // Define headerHeight and rowHeight for the data grid

  const headerHeight = 28;
  const rowHeight = 26;

  // Map the stepList data to rows for the data grid

  const rows = stepList?.ScenarioRIDetails?.scenarioRISteps
    ? stepList?.ScenarioRIDetails?.scenarioRISteps?.map((step) => {
        return {
          id: step.stepId,
          stepName: step.stepName,
          stepStartDatetime: step.stepStartDatetime,
          stepDuration: step.stepDuration,
          stepStatus:
            step.stepStatus.charAt(0).toUpperCase() + step.stepStatus.slice(1),
          stepsLog: "",
          logDetails: step.stepRILogs,
        };
      })
    : [];

  return (
    <div
      style={{
        background: "#ffffff",
        overflow: "auto",
        height: "445px",
        width: "100%",
        flexGrow: 1,
      }}
    >
      {/* Render the StyledDataGrid component with the specified rows, columns, and props */}

      <StyledDataGrid
        rows={rows}
        componentsProps={{
          // Set custom styles for the datafg grid components

          basePopper: {
            sx: {
              "& .MuiTypography-root": { fontSize: "12px" },
            },
          },
        }}
        getRowId={rows._id}
        columns={columns}
        rowHeight={rowHeight}
        columnHeaderHeight={headerHeight}
        hideFooter
        hideFooterPagination
        hideFooterSelectedRowCount
        slots={{ toolbar: CustomToolbar }}
      />
    </div>
  );
};
// Map the Redux state to props for WBstepsTest component

const mapStateToProps = (state) => ({
  stepList: state.dashboardData.stepList || [],
  scenaioList: state.dashboardData.scenaioList || [],
  currentscenarioID: state.dashboardData.scenarioSelection || [],
});

// Connect the WBstepsTest component to the Redux store

export default connect(mapStateToProps)(WBstepsTest);

// RenderCell function for the logs column cell rendering

function RenderCell({ params, details, dispatch }) {
  // ... (extract data from params)
  const statCheck = params.row.stepStatus;
  const logDetail = params.row.logDetails;

  // State to control the dialog open/close

  const [open, setOpen] = useState(false);
  const [viewFile, setViewFile] = useState({
    open: false,
    imagePath: null,
    excelPath: null,
  });
  const [openTicketForm, setOpenTicketForm] = useState(false);
  const [ticketFormDetails, seTicketFormDetails] = useState({
    short_description: null,
    description: null,
  });

  useEffect(() => {
    seTicketFormDetails({
      short_description: details?.stepList.ScenarioRIDetails.scenarioName,
      description: details?.stepList.ScenarioRIDetails.scenarioRIDesc,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValueChange = (event) => {
    try {
      const { name, value } = event?.target || {};
      if (name) {
        seTicketFormDetails({ ...ticketFormDetails, [name]: value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle button click to open the dialog

  const handleButtonClick = () => {
    if (statCheck === "Failed" || statCheck === "Warning") {
      setOpen(true);
    }
  };
  // Function to handle dialog close

  const handleClose = () => {
    setOpen(false);
    setOpenTicketForm(false);
    setViewFile({ open: false, imagePath: null, excelPath: null });
  };
  const hideView = () => {
    setViewFile({ open: false, imagePath: null, excelPath: null });
  };
  const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };
  const previewFile = (e, itemDetails) => {
    try {
      const ifsFileImage =
        itemDetails[0]?.screenShotURL &&
        isImage(itemDetails[0]?.screenShotURL.split("?")[0]);
      const ifsFileExcel =
        !ifsFileImage && itemDetails[0]?.screenShotURL
          ? itemDetails[0]?.screenShotURL.includes(".xlsx?")
          : false;
      setViewFile({
        open: true,
        imagePath: ifsFileImage ? itemDetails[0]?.screenShotURL : null,
        excelPath: ifsFileExcel ? itemDetails[0]?.screenShotURL : null,
      });
      setOpenTicketForm(false);
    } catch (error) {
      console.error(error);
    }
  };
  const createServiceNowTicket = (e, itemDetails) => {
    setOpenTicketForm(true);
    setViewFile({ open: false, imagePath: null, excelPath: null });
  };
  const callServiceNowTicketAPI = async () => {
    try {
      dispatch(setLoadingState(true));
      const { short_description, description } = ticketFormDetails;
      if (
        short_description &&
        short_description.trim() !== "" &&
        description &&
        description.trim() !== ""
      ) {
        const newRecord = {
          short_description: `${details?.currentscenarioID} - ${short_description}`,
          description: description,
        };

        const response = await generateServiceNow(newRecord);
        if (response?.result?.task_effective_number) {
          dispatch(setLoadingState(false));
          const message =
            "Incident " +
            response?.result?.task_effective_number +
            " created successfully";
          dispatch(showError(true, message, "info"));
        } else {
          dispatch(setLoadingState(false));
          const message = "Failed to create ServiceNow ticket:";
          dispatch(showError(true, message, "error"));
        }
        handleClose();
      }
    } catch (error) {
      dispatch(setLoadingState(false));
      const message = "Failed to create ServiceNow ticket:";
      dispatch(showError(true, message, "error"));
      handleClose();
    }
  };

  return (
    <div>
      {logDetail &&
      logDetail?.length &&
      (statCheck === "Failed" || statCheck === "Warning") ? (
        <ExcelExportIcon
          handleExport={handleButtonClick}
          getFilteredRows={""}
          iconClass={"mdi mdi-eye"}
        />
      ) : (
        <></>
      )}
      {/* Dialog component for displaying logs */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Log Details</DialogTitle>
        <DialogContent>
          {/* Render the LogsPopUp component with logDetails prop */}
          <LogsPopUp
            logDetails={logDetail}
            previewFile={previewFile}
            createServiceNowTicket={createServiceNowTicket}
            openTicketForm={openTicketForm}
            viewFile={viewFile}
            isImage={isImage}
          />
          {viewFile?.open && viewFile?.imagePath ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={viewFile?.imagePath}
                alt=""
                height={300}
                style={{ width: "100%" }}
              />
              <button
                onClick={hideView}
                style={{
                  position: "absolute",
                  marginLeft: "523px",
                  marginTop: "0px",
                  borderRadius: "20px",
                }}
              >
                X
              </button>
            </div>
          ) : (
            <></>
          )}
          {openTicketForm ? (
            <div style={{ marginTop: 30, display: "grid" }}>
              <span>Short Description</span>
              <textarea
                name="short_description"
                placeholder="Short Description"
                className="form-control small-select"
                rows="3"
                cols="50"
                onChange={handleValueChange}
              >
                {ticketFormDetails?.short_description}
              </textarea>
              <span>Description</span>
              <textarea
                name="description"
                placeholder="Description"
                className="form-control small-select"
                rows="3"
                cols="50"
                onChange={handleValueChange}
              >
                {ticketFormDetails?.short_description}
              </textarea>
              <button
                style={{ marginTop: 10, padding: 5 }}
                className="btn btn-success btn-sm waves-effect waves-light"
                onClick={callServiceNowTicketAPI}
                disabled={
                  !ticketFormDetails?.short_description ||
                  ticketFormDetails?.short_description.trim() === "" ||
                  !ticketFormDetails?.description ||
                  ticketFormDetails?.description.trim() === ""
                }
              >
                Create Ticket
              </button>
            </div>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
