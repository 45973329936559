import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import dashboardData from "./dashboard/reducer";
 
// Authentication
 import login from "./auth/login/reducer";
 

const rootReducer = combineReducers({
  // public
  dashboardData,
  Layout,
   login,
 });

export default rootReducer;
