import React, { useState } from "react";

const LogsPopUp = (props) => {
  const {
    logDetails,
    createServiceNowTicket,
    openTicketForm,
    previewFile,
    viewFile,
    isImage,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [selectedStatuses, setSelectedStatuses] = useState([
    "alerts",
    "error",
    "data",
    "debug",
  ]);

  // Filter logDetails based on selectedStatuses
  const filteredLogDetails = logDetails.filter(
    (item) =>
      selectedStatuses.length === 0 || selectedStatuses.includes(item.logStatus)
  );

  return (
    <React.Fragment>
      <div>
        <div
          className="table-responsive"
          style={{ backgroundColor: "#ffffff !important" }}
        >
          <table
            style={{ backgroundColor: "#ffffff !important" }}
            className="table table-centered table-nowrap mb-0"
          >
            <tbody>
              {filteredLogDetails.map((item, key) => (
                <tr key={item.stepRILogId}>
                  <td>
                    <a
                      href={item.screenShotURL}
                      className="btn btn-success btn-sm waves-effect waves-light"
                    >
                      Download
                    </a>
                  </td>
                  {isImage(logDetails[0]?.screenShotURL.split("?")[0]) ? (
                    <td>
                      <button
                        className="btn btn-success btn-sm waves-effect waves-light"
                        onClick={(e) => {
                          previewFile(e, logDetails);
                        }}
                        disabled={viewFile?.open && viewFile?.imagePath}
                      >
                        View
                      </button>
                    </td>
                  ) : (
                    <></>
                  )}
                  <td>
                    <button
                      disabled={openTicketForm}
                      className="btn btn-success btn-sm waves-effect waves-light"
                      onClick={(e) => {
                        createServiceNowTicket(e, logDetails);
                      }}
                    >
                      Create Ticket
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LogsPopUp;
