import React from "react";
import { Row, Col } from "reactstrap";
import { MyTextField } from "../../components/TextField";
import { Typography } from "@mui/material";

export const QuickLinkDetails = (props) => {
  const {
    quickLinkEditDetails,
    handleQuickLinkFieldsChangeClick,
    subjectErrorMessages,
    descriptionErrorMessages,
    linkURLErrorMessages,
  } = props;

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className="card">
            <div className="card-body p-0">
              <div
                className="text-muted"
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <Row>
                  <div className="col-md-12 col-sm-12">
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="quick-link-subject"
                        >
                          Subject <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          ariaLabelledby="quick-link-subject"
                          value={quickLinkEditDetails?.subject || ""}
                          handleChange={(event) =>
                            handleQuickLinkFieldsChangeClick(event, "subject")
                          }
                          type={"text"}
                          className={""}
                          placeholder={"Enter subject"}
                          pattern={""}
                          inputMode={"text"}
                        />
                        {subjectErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {subjectErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label className="small-select" html="quick-link-desc">
                          Description <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          ariaLabelledby="quick-link-desc"
                          value={quickLinkEditDetails?.description || ""}
                          handleChange={(event) =>
                            handleQuickLinkFieldsChangeClick(
                              event,
                              "description"
                            )
                          }
                          type={"text"}
                          className={""}
                          placeholder={"Enter description"}
                          pattern={""}
                          inputMode={"text"}
                        />
                        {descriptionErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {descriptionErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="quick-link-link-url"
                        >
                          Link URL <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          ariaLabelledby="quick-link-link-url"
                          value={quickLinkEditDetails?.linkURL || ""}
                          handleChange={(event) =>
                            handleQuickLinkFieldsChangeClick(event, "linkURL")
                          }
                          type={"text"}
                          className={""}
                          placeholder={"Enter link URL"}
                          pattern={""}
                          inputMode={"text"}
                        />
                        {linkURLErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {linkURLErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
