// .js
import React from "react";
import { Row, Col } from "reactstrap";
import { MyTextField } from "../../components/TextField";
import { Typography } from "@mui/material";

export const AnnouncementDetails = (props) => {
  const {
    // type,
    // details,
    announcementEditDetails,
    handleAnnouncementFieldsChangeClick,
    subjectErrorMessages,
    descriptionErrorMessages,
    detailedDescriptionErrorMessages,
  } = props;

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className="card">
            <div className="card-body p-0">
              <div
                className="text-muted"
                style={{ paddingTop: 20, paddingBottom: 20 }}
              >
                <Row>
                  <div className="col-md-12 col-sm-12">
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="announcement-subject"
                        >
                          Subject <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          ariaLabelledby="announcement-subject"
                          value={announcementEditDetails?.subject || ""}
                          handleChange={(event) =>
                            handleAnnouncementFieldsChangeClick(
                              event,
                              "subject"
                            )
                          }
                          type={"text"}
                          className={""}
                          placeholder={"Enter subject"}
                          pattern={""}
                          inputMode={"text"}
                          maxLength={50}
                        />
                        {subjectErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {subjectErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="announcement-desc"
                        >
                          Description <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <MyTextField
                          ariaLabelledby="announcement-desc"
                          value={announcementEditDetails?.description || ""}
                          handleChange={(event) =>
                            handleAnnouncementFieldsChangeClick(
                              event,
                              "description"
                            )
                          }
                          type={"text"}
                          className={""}
                          placeholder={"Enter description"}
                          pattern={""}
                          inputMode={"text"}
                          maxLength={50}
                        />
                        {descriptionErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {descriptionErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-2 row">
                      <Col className="col-3">
                        <label
                          className="small-select"
                          htmlFor="announcement-detailed-desc"
                        >
                          Detailed Description <i style={{ color: "red" }}>*</i>
                        </label>
                      </Col>
                      <Col className="col-9">
                        <textarea
                          aria-labelledby="announcement-detailed-desc"
                          name="detailedDescription"
                          rows="4"
                          cols="110"
                          onChange={(event) =>
                            handleAnnouncementFieldsChangeClick(
                              event,
                              "detailedDescription"
                            )
                          }
                          style={{ borderColor: "lightgray", color: "#505d69" }}
                        >
                          {announcementEditDetails?.detailedDescription || ""}
                        </textarea>
                        {descriptionErrorMessages?.isError ? (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {detailedDescriptionErrorMessages?.message}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
