import React, { useState, useEffect } from "react";
import Split from '@uiw/react-split';
import WBsteps from "./DataGrid/WBsteps";
import "./../../assets/scss/pages/workBoard.scss"
import { Card, CardBody, Col } from "reactstrap";

import WbSuiterunlist from "./DataGrid/WbSuiterunlist";
import WbScenariolist from "./DataGrid/WbScenariolist";

const isMobile = window.innerWidth <= 768;


const WBworkArea = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update isMobile when window is resized
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  return (
    <Col>
      {/* Main Card23 */}
      <Card>
        <CardBody className="p-2">
          {/* Section Title */}
          <h6>WorkArea</h6>
          {/* Split Layout Container */}
          {isMobile ? (
            
          <div id="workareaMobi">   
                     {/* Mobile layout */}
                     <p className="smallp">Select a suite to display corresponding scenarios in the middle section. Click on a scenario name to view its steps</p>

              <div className="workitems"> <WbSuiterunlist /></div>
              <div className="workitems"><WbScenariolist /></div>
              <div className="workitems"> <WBsteps /></div>
             
              
             
              </div>
          ) : (



          <div id="workarea" className="workarea">
            <Split guttersize={1}>
              {/* Left Section */}
              <div className="left-section">
                {/* Component for displayingg Suite Run list */}
                <WbSuiterunlist />
              </div>
              {/* Middle Section */}
              <div className="middle-section">
                {/* Component for displaying Scenario list */}
                <WbScenariolist/>
              </div>
              {/* Right Section */}
              <div className="right-section">
                {/* Component for displaying Steps */}
                <WBsteps />
              </div>
            </Split>
          </div>

)}

        </CardBody>
      </Card>
    </Col>   
  );
};

export default WBworkArea;

 
