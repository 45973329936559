import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Container } from "reactstrap";

import { menuSelectionChange, setLoadingState } from "../../store/actions";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import { UserList } from "./UserList";
import { getAllUserList } from "../../store/dashboard/orchestractor";

export const ManagerUsers = () => {
  const dispatch = useDispatch();

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    handleMenuClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = () => {
    dispatch(menuSelectionChange(7));
  };
  const getAllUserDetails = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await getAllUserList();
      if (response) {
        // console.log(response);
        setUserList(
          response?.length ? response?.filter((rec) => rec?.isActive) : []
        );
      } else {
        setUserList([]);
      }
      dispatch(setLoadingState(false));
    } catch (error) {
      console.error(error);
      setUserList([]);
      dispatch(setLoadingState(false));
    }
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ marginTop: "30px" }}>
        <Container fluid>
          <Breadcrumbs
            title="Home"
            titleRoute={"/manage-users"}
            breadcrumbItem="Manage Users"
            style={{ backgroundColor: "#f8f9fa" }}
          />
          <React.Fragment>
            <Row>
              <UserList
                userList={userList}
                getAllUserDetails={getAllUserDetails}
              />
            </Row>
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};
