import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import "../Dashboard/Dashboard.scss";
import Breadcrumbs from "../AHeaderFooterSections/HeaderComponents/Breadcrumb";
import { menuSelectionChange } from "../../store/actions";
import { useDispatch } from "react-redux";
import InsightsFilters from "./InsightsFilters";
import BusinessProcessesData from "../Comparison/BusinessProcessesData.json";
import InsightsChart from "./InsightsChart";
import { Box, IconButton, Tooltip } from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import SecurityIcon from "@mui/icons-material/Security";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BusinessProcessDetails from "./data/businessProcessDetails.json";
import BP_Changes from "./data/BP_Changes.json";
import SecurityChartOne from "./data/securityChartOne.json";
import BusinessProcessChartTwo from "./data/businessProcessChartTwo.json";

export const Insights = () => {
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState(1);

  useEffect(() => {
    document.title = "Insights | Ilumn ";
    dispatch(menuSelectionChange(6));
  }, [dispatch]);

  const handleMenuClick = (event, menuIndex) => {
    setSelectedMenu(menuIndex || 1);
  };
  console.log(selectedMenu);
  return (
    <div className="page-content" id="component">
      <Container fluid>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            className="card"
            textAlign={"center"}
            style={{
              marginTop: 45,
              marginRight: 5,
              marginBottom: 5,
              width: "100px",
            }}
          >
            <Box textAlign={"center"} marginTop={2}>
              <Tooltip title="Business Process" arrow>
                <IconButton
                  aria-label="Business Process"
                  color={selectedMenu === 1 ? "success" : "default"}
                  onClick={(event) => handleMenuClick(event, 1)}
                >
                  <BadgeIcon />
                </IconButton>
              </Tooltip>
              <br />
              <Tooltip title="Security" arrow>
                <IconButton
                  aria-label="Security"
                  color={selectedMenu === 2 ? "success" : "default"}
                  onClick={(event) => handleMenuClick(event, 2)}
                >
                  <SecurityIcon />
                </IconButton>
              </Tooltip>
              <br />
              <Tooltip title="Reports" arrow>
                <IconButton
                  aria-label="Reports"
                  color={selectedMenu === 3 ? "success" : "default"}
                  onClick={(event) => handleMenuClick(event, 3)}
                >
                  <AssessmentIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <Breadcrumbs title="Home" breadcrumbItem="Insights" />
            <InsightsFilters BusinessProcessesData={BusinessProcessesData} />
            {selectedMenu === 1 ? (
              <>
                <InsightsChart
                  chartName={"Transactions by period"}
                  tableHeaderName={"Business Process Transactions"}
                  rightText={
                    "Here are the most frequently used Business Process transactions within the specified timeframe. Using Ilumn its imperative to hone in on these pivotal Business Processes during the test execution."
                  }
                  BusinessProcessDetails={BusinessProcessDetails}
                  defaultChart={1}
                  selectedMenu={selectedMenu}
                />
                <InsightsChart
                  chartName={"Test Runs by Period"}
                  tableHeaderName={"Test Suite Name"}
                  rightText={`Insights provides an overview of actual test runs using Ilumn for a Business Process over a period of time. This will enable the users to <strong>plan and schedule</strong> the test execution based on the operation transactions as per the Chart 1 [as above].`}
                  BusinessProcessDetails={BusinessProcessChartTwo}
                  defaultChart={5}
                  isStacked={true}
                  selectedMenu={selectedMenu}
                />
                <InsightsChart
                  chartName={"Business Process definition Change"}
                  tableHeaderName={"Business Process"}
                  rightText={
                    "This Insight gives the maximum number of changes made to a Business Process definition over a period of time. By scheduling the Ilumn test Scripts either by Daily or Weekly we Will be able to capture the changes immediately made to the business process thus mitigating the risk of Overlooking the business process."
                  }
                  BusinessProcessDetails={BP_Changes}
                  defaultChart={2}
                  lineType={2}
                  selectedMenu={selectedMenu}
                />
              </>
            ) : selectedMenu === 2 ? (
              <>
                <InsightsChart
                  chartName={"Security Group"}
                  BusinessProcessDetails={SecurityChartOne}
                  defaultChart={4}
                  selectedMenu={selectedMenu}
                  rightText={
                    "This Insight gives the number of changes made to a Domain of a Security Group, over a period of time. Ilumn alerts the users of any changes made to the domain of a Security Group."
                  }
                />
              </>
            ) : selectedMenu === 3 ? (
              <>
                <InsightsChart
                  chartName={"Reports"}
                  BusinessProcessDetails={BusinessProcessDetails}
                  defaultChart={9}
                  selectedMenu={selectedMenu}
                />
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};
