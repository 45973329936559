import React from 'react';
import banner from "../../assets/images/Banner-Landing-Page.png";
const Banner = () => {
  return (
  
        <img src={banner} alt="Banner" height="120" />
     
    
  );
};

export default Banner;