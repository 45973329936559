import React from "react";

const MenuData = () => {
  const MenuNavData = [
    {
      id: 1,
      label: "Home",
      icon: "mdi mdi-home-variant-outline me-2",
      target: "/home",
    },
    {
      id: 2,
      label: "Dashboard",
      icon: "mdi mdi-view-dashboard me-2",
      target: "/dashboard",
    },
    {
      id: 3,
      label: "WorkBoard",
      icon: "mdi mdi-briefcase-variant-outline me-2",
      target: "/workboard",
    },
    {
      id: 4,
      label: "TestManager",
      icon: "mdi mdi-script-text-outline me-2",
      target: "/testmanager",
    },
    {
      id: 5,
      label: "Comparison",
      icon: "mdi mdi-repeat me-2",
      target: "/comparison",
    },
    {
      id: 6,
      label: "Insights",
      icon: "mdi mdi-trending-up me-2",
      target: "/insights",
    },
    {
      id: 7,
      label: "Manage Users",
      icon: "mdi mdi-home-variant-outline me-2",
      target: "/manage-users",
    },
    {
      id: 8,
      label: "Announcement",
      icon: "mdi mdi-script-text-outline me-2",
      target: "/announcement",
    },
    {
      id: 9,
      label: "Quick Links",
      icon: "mdi mdi-script-text-outline me-2",
      target: "/quick-links",
    },
  ];

  return <React.Fragment>{MenuNavData}</React.Fragment>;
};

export default MenuData;
