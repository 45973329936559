import React, { useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

// layouts
import NonAuthLayout from "../Pages/AHeaderFooterSections/NonAuthLayout";
import HorizontalLayout from "../Pages/AHeaderFooterSections/index";
import { AuthProtected } from "./AuthProtected";
import { publicRoutes } from "./routes";
import {
  adminRoutes,
  commonRoutes,
  privilegedRoutes,
  testerRoutes,
  viewerRoutes,
} from "../Pages/accessSettingsMenu";
import { Profile } from "../Pages/Profile";
import { Error404Page } from "../Pages/Error404Page";
// import { ManagerUsers } from "../Pages/ManagerUsers";

const Index = () => {
  const userLoggedin = useSelector((state) => state.login.userLoggedin || []);

  // State to hold authProtectedRoutes
  const [authProtectedRoutes, setAuthProtectedRoutes] = useState([]);

  useEffect(() => {
    if (userLoggedin) {
      const storedAuthUser = localStorage.getItem("authUser");
      const obj = storedAuthUser ? JSON.parse(storedAuthUser) : null;
      const adminRoleFlag = obj && obj.user && obj.user.adminRole;
      const testerRoleFlag = obj && obj.user && obj.user.testerRole;
      const viewerRoleFlag = obj && obj.user && obj.user.viewerRole;
      const privilegedRoleFlag = obj && obj.user && obj.user.privilegeUserRole;

      const newAuthProtectedRoutes = [
        ...(adminRoleFlag ? adminRoutes : []),
        ...(testerRoleFlag ? testerRoutes : []),
        ...(viewerRoleFlag ? viewerRoutes : []),
        ...(privilegedRoleFlag ? privilegedRoutes : []),
        ...(adminRoleFlag ? [] : commonRoutes),
      ];

      // Update the authProtectedRoutes state only if it has changed
      if (
        JSON.stringify(authProtectedRoutes) !==
        JSON.stringify(newAuthProtectedRoutes)
      ) {
        // const isRouteExists = newAuthProtectedRoutes.find(
        //   (rec) => rec?.path === window.location.pathname
        // );
        // if (isRouteExists) {
        setAuthProtectedRoutes(newAuthProtectedRoutes);
        // }
      }
    }
  }, [userLoggedin, authProtectedRoutes]);

  const Layout = HorizontalLayout;
  return (
    <Routes>
      <Route path="*" element={<Error404Page />} />
      <Route>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}
      </Route>

      <Route>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <Layout>{route.component}</Layout>
              </AuthProtected>
            }
            key={idx}
            exact={true}
          />
        ))}
        <Route
          path="/profile"
          element={
            <AuthProtected>
              <Layout>
                <Profile />
              </Layout>
            </AuthProtected>
          }
          // key={idx}
          exact={true}
        />
      </Route>
    </Routes>
  );
};

export default Index;
