// Import required modules and components
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Col, Collapse, InputGroup, Row } from "reactstrap";
import { setTenantSelection, showError } from "../../store/dashboard/actions";
import moment from "moment";
import { useDispatch, connect } from "react-redux";
import { Box, InputAdornment, TextField } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

// Functional component InsightsFilters
const InsightsFilters = ({
  tenants,
  tenantSelection,
  startDateValue,
  endDateValue,
  setTenantSelection,
  BusinessProcessesData,
}) => {
  // State variables using React hooks
  const [col1, setCol1] = useState(true);
  const [selectedTenant, setSelectedTenant] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [questionData, setQuestionData] = useState("");
  const dispatch = useDispatch();
  // useEffect hook to set state variables based on props
  useEffect(() => {
    setSelectedTenant(tenantSelection);
    setStartDate(startDateValue);
    setEndDate(endDateValue);
  }, [tenantSelection, startDateValue, endDateValue]);

  // Function to toggle the collapse state for column 1
  const toggleCol1 = () => {
    setCol1(!col1);
  };

  // Event handler for selecting a tenant from the dropdown
  const handleTenantChange = (event) => {
    setSelectedTenant(event.target.value);
  };

  // Event handler for form submission
  const handleFormSubmit = async () => {
    const formattedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : null;
    if (formattedStartDate <= formattedEndDate) {
      // To-Do
    } else {
      const message = "From-Date should not great than or equalto To-Date";
      dispatch(showError(true, message, "error"));
      setStartDate(startDateValue);
      setEndDate(endDateValue);
    }
  };

  // Event handler for reset button click
  const handleReset = async () => {
    // To-Do
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  const handleSearchQuestion = (event) => {
    const { value } = event?.target || {};
    setQuestionData(value);
  };

  return (
    <Row>
      <Col className="col-12">
        <div className="card" style={{ marginBottom: 10 }}>
          <div className="card-body p-0">
            <div className="accordion" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !col1,
                    })}
                    type="button"
                    onClick={toggleCol1}
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="mb-0 font-size-14">Filters</h4>
                  </button>
                </h2>

                <Collapse isOpen={col1} className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="text-muted">
                      <Row>
                        <div className="col-md-6 col-sm-12">
                          <Row className="mb-2 row">
                            <div className="card-title-desc-filters">
                              Please choose tenant, from, to date and business
                              process to show results.
                            </div>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-3">
                              <label
                                className="small-select"
                                htmlFor="choose-tenant-insight"
                              >
                                Choose your Tenant
                              </label>
                            </Col>
                            <Col className="col-9">
                              <select
                                aria-labelledby="choose-tenant-insight"
                                className="form-select small-select"
                                id="inlineFormSelectPref"
                                value={selectedTenant}
                                onChange={handleTenantChange}
                              >
                                {tenants.map((tenant) => (
                                  <option
                                    key={tenant.tenantId}
                                    value={tenant.tenantId}
                                  >
                                    {tenant.tenantName}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-3">
                              <label
                                className="small-select"
                                htmlFor="datepicker-insight"
                              >
                                Select From Date
                              </label>
                            </Col>
                            <Col className="col-9">
                              <InputGroup>
                                <Flatpickr
                                  aria-labelledby="datepicker-insight"
                                  id="startDatepicker"
                                  className="form-control-cal d-block"
                                  placeholder="Enter to Date"
                                  options={{
                                    altInput: true,
                                    altFormat: "d-M-Y",
                                    dateFormat: "Y-m-d",
                                    onChange: (selectedDates) =>
                                      setStartDate(selectedDates[0]),
                                    disable: [
                                      {
                                        from: tomorrow,
                                        to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                                      },
                                    ],
                                  }}
                                  value={startDate}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-3">
                              <label
                                className="small-select"
                                htmlFor="end-datepicker-insight"
                              >
                                Select To Date
                              </label>
                            </Col>
                            <Col className="col-9">
                              <InputGroup>
                                <Flatpickr
                                  aria-labelledby="end-datepicker-insight"
                                  id="endDatepicker"
                                  className="form-control-cal d-block"
                                  placeholder="Enter to Date"
                                  type="datetime-local"
                                  options={{
                                    altInput: true,
                                    altFormat: "d-M-Y",
                                    dateFormat: "Y-m-d",
                                    onChange: (selectedDates) =>
                                      setEndDate(selectedDates[0]),
                                    disable: [
                                      {
                                        from: tomorrow,
                                        to: new Date(2099, 0, 1), // You can adjust the 'to' date as needed
                                      },
                                    ],
                                  }}
                                  value={endDate}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <Row className="mb-2 row"></Row>
                          <Row className="mb-4 row">
                            <Col className="col-3"></Col>
                            <Col className="col-6"></Col>
                            <Col className="col-3"></Col>
                          </Row>
                          <Row className="mb-2 row">
                            <Col className="col-3"></Col>
                            <Col className="col-6">
                              <button
                                type="button"
                                className="btn btn-primary small-select"
                                onClick={handleFormSubmit}
                              >
                                Submit
                              </button>
                            </Col>
                            <Col className="col-3"></Col>
                          </Row>

                          <Row className="mb-2 row pt-1">
                            <Col className="col-3"></Col>
                            <Col className="col-6">
                              <button
                                type="button"
                                className="btn btn-outline-danger small-select"
                                onClick={handleReset}
                              >
                                Reset
                              </button>
                            </Col>
                            <Col className="col-3"></Col>
                          </Row>
                        </div>

                        {/* Quick filters */}
                        <div className="col-md-3 col-sm-12 mystyle">
                          <Box>
                            <TextField
                              label=""
                              fullWidth
                              className="search-ai-insights"
                              style={{ height: 35 }}
                              placeholder="Ask a question about your data"
                              value={questionData}
                              onChange={handleSearchQuestion}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CommentIcon />
                                  </InputAdornment>
                                ),
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  tenants: Object.values(state.dashboardData.tenants) || [],
  tenantSelection: state.dashboardData.tenantSelection || "",
  startDateValue: state.dashboardData.startDate || "",
  endDateValue: state.dashboardData.endDate || "",
});

export default connect(mapStateToProps, { setTenantSelection })(
  InsightsFilters
);
